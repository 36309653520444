<div class="container">
	<app-header *ngIf="isLoggedIn"></app-header>
	<app-header-logged-out *ngIf="!isLoggedIn"></app-header-logged-out>
	<section>
		<router-outlet></router-outlet>
	</section>

	<ng-template ngbModalContainer></ng-template>

	<footer class="footer content-box">
		<small class="mr-3"><a href="mailto:recoghelp@gmail.com">Get support</a></small>
		<small>&copy; 2017 Alec Sorensen</small>
	</footer>
	
</div>