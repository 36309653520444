import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;

  @Output() fieldUpdated = new EventEmitter<ThoughtField>();

  constructor() {}

  ngOnInit() {
  }
}
