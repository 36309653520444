import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';

import { NetworkService, Network } from '../network.service';
import { UtilityService } from '../../app-core/utility.service';
import { UserService, User } from '../../app-core/user.service';

@Component({
  selector: 'app-network-form',
  templateUrl: './network-form.component.html',
  styleUrls: ['./network-form.component.scss']
})
export class NetworkFormComponent implements OnInit, OnDestroy {

  public network: Network = {
    uid: null,
    name: null,
    createdDate: null,
    updatedDate: null,
    creatorName: null,
    creatorUid: null,
    admin: null,
    adminInvitee: null,
    members: {},
    invitees: {}
  };
  public networkId: string;
  public newInvitee: string;
  public members: string[] = [];
  public invitees: string[] = [];
  public userData: {[index: string]: User} = {};


  private _networkSubscription: Subscription;
  private _cachedNetwork: Network;

  constructor(
    private _thisRoute: ActivatedRoute,
    private _router: Router,
    private _titleService: Title,
    private _networkService: NetworkService,
    private _utilityService: UtilityService,
    private _userService: UserService ) { }

  ngOnInit () {
    const routeObserver = this._thisRoute.params.subscribe(params => {
        this.networkId = params['networkId'];

        // Get network observable from Service if there is one
        if (this.networkId) {
            this._networkSubscription = this._networkService.getOne(this.networkId)
              .subscribe( (networkData => {
                let allUserIds: string[] = [];

                networkData.members = networkData.members || {};
                networkData.invitees = networkData.invitees || {};

                this.members = Object.keys(networkData.members);
                this.invitees = Object.keys(networkData.invitees);

                this.network = networkData;

                this._cachedNetwork = this._utilityService.deepCopy(networkData);

                allUserIds = Object.keys(networkData.members);
                if (networkData.admin && !networkData.members[networkData.admin]) {
                  allUserIds.push(networkData.admin);
                }

                this._titleService.setTitle('ReCog - Editing Network: ' + this.network.name);
            }).bind(this));
        }
        else {
          this._titleService.setTitle('ReCog - Create Network');
        }
    });
  }

  ngOnDestroy () {
    this._networkSubscription.unsubscribe();
  }

  public addNewInvitee(): void {
    if (this.newInvitee) {
      this.invitees.push(this.newInvitee);
      this.newInvitee = null;
    }
  }

  public removeAdmin(): void {
    this.network.admin = null;
  }

  public removeMember(member): void {
      if (this.members && this.members.filter && member) {
        this.members = this.members.filter(memberInArray => memberInArray !== member);
        delete this.network.members[member];
      }
  }

  public removeInvitee(invitee): void {
      if (this.invitees && this.members.filter && invitee) {
        this.invitees = this.invitees.filter(inviteeInArray => inviteeInArray !== invitee);
        delete this.network.invitees[invitee];
      }
  }

  public save(): void {
    let results;

    this.invitees.forEach(invitee => this.network.invitees[invitee] = true);

    if (this.networkId) {
      results = this._networkService.edit(this.network, this._cachedNetwork);
    }
    else {
      results = this._networkService.create(this.network);
    }

    results.then(() => {
      this._router.navigateByUrl('/networks/' + this.networkId);
    });
  }

  public cancelEditNetwork(): void {
    if (this.network.uid) {
      this._router.navigateByUrl('/networks/' + this.network.uid);
    }
    else {
      this._router.navigateByUrl('/dashboard');
    }
  }

}

// TODO: transforming dates / saving dates
