import { Injectable } from '@angular/core';

import { Thought, ThoughtField } from '../../thought-module/thought.service';
import { UtilityService } from '../../app-core/utility.service';

export interface ViewVariable {
  name: string;
  instructions?: string;
  fieldMappings: string[];
  required: boolean;
  match: string;
  type: string;
}

export interface View {
  variables: ViewVariable[];
  settings: any;
}

// TODO: think about when/if to put these settings into a DB and fetch them
export const VIEW_INFO = {
  'List': {
      variables: [
        {
          name: 'Included Fields',
          instructions: 'Add labels for data that will appear in each list item.',
          fieldMappings: ['Title'],
          required: true,
          match: 'all',
          type: 'any'
        }
      ],
      settings: [
        {
          name: 'Format first field as title',
          type: 'boolean',
          value: true
        },
        {
          name: 'Include labels in list items',
          type: 'boolean',
          value: false
        },
        {
          name: 'Thoughts must include all labels listed in \'Included Fields\'',
          type: 'boolean',
          toggle: {
            variables: ['Included Fields'],
            target: 'match',
            on: 'each',
            off: 'all'
          },
          value: false
        },
      ]
  },
  'Pages': {
    variables: [
        {
          name: 'Included Fields',
          instructions: 'Add labels for data that will appear on each page.',
          fieldMappings: ['Title'],
          required: false,
          match: 'all',
          type: 'any'
        }
      ],
      settings: [
        {
          name: 'Format first field as title',
          type: 'boolean',
          value: true
        },
        {
          name: 'Include labels in list items',
          type: 'boolean',
          value: false
        }
      ]
  },
  'Gallery': {
    variables: [
        {
          name: 'Included Fields',
          instructions: 'Add labels for images that should be included in the gallery. Leave this field empty to get any image field.',
          fieldMappings: [],
          required: false,
          match: 'all',
          type: 'image'
        }
      ],
      settings: [
        {
          name: 'Use only the first matching image in a thought.',
          type: 'boolean',
          toggle: {
            variables: ['Included Fields'],
            target: 'match',
            on: 'first',
            off: 'all'
          },
          value: false
        }
      ]
  },
  'Bar Graph': {
    variables: [{
      name: 'Included Fields',
          instructions: 'Add labels for numbers that should be included in the graph. If a thought has multiple fields with the same label, only the first will be graphed.',
          fieldMappings: [],
          required: true,
          match: 'all',
          type: 'number'
    }],
    settings: []
  },
  'Time Line': {
    variables: [
        {
          name: 'Start Date Field(s)',
          instructions: 'Label(s) for the start date of each thought. Timeline uses the first matching date.',
          fieldMappings: [],
          required: false,
          match: 'first',
          type: 'date'
        },
        {
          name: 'End Date Field(s)',
          instructions: 'Label(s) for the end date for each thought. Timeline uses the first matching date.',
          fieldMappings: [],
          required: false,
          match: 'first',
          type: 'date'
        },
        {
          name: 'Label',
          instructions: 'Label of the info to be used as a label on the timeline. Timeline uses the first matching item.',
          fieldMappings: ['Title'],
          required: false,
          match: 'first',
          type: 'short text'
        }
      ],
      settings: []
  },
  'Word Cloud': {
    variables: [
      {
        name: 'Included Fields',
        instructions: 'Add labels for text info that should be included in the cloud.',
        fieldMappings: [],
        required: true,
        match: 'all',
        type: 'long text, short text'
      }
    ],
    settings: []
  }
};

@Injectable()
export class ViewService {

  constructor( private _utilityService: UtilityService ) { }

  private labelMatchesVariable = (field: ThoughtField, fieldMapping: string): boolean => {
    return fieldMapping === field.label;
  }
  private typeMatchesVariable = (field: ThoughtField, varType: string): boolean => {
    return varType === 'any' || varType.search(field.type) > -1;
  }
  private typeAndLabelMatchVariable = (field: ThoughtField, fieldMapping: string, varType: string): boolean => {
    return this.labelMatchesVariable(field, fieldMapping) && this.typeMatchesVariable(field, varType);
  }

  public getViews = (): string[] => {
    return Object.keys(VIEW_INFO);
  }

  public getDefaultView = () => {
    return {
      name: '',
      type: 'List',
      info: this.getViewInfo()
    };
  }

  public getViewInfo = (view = 'List'): View => {
    return this._utilityService.deepCopy(VIEW_INFO[view]);
  }

  // TODO: break this into several functions
  public mapThoughtsToView = (thoughts: Thought[], view: View): any[] => {
    let mappedData = [];

    if (!view || !view.variables || !thoughts) {
       return;
    }

    thoughts.forEach(thought => {
      let dataItem: any,
          hasAllRequiredFields: boolean;

      // ensure that each data item has thought ID, so it can link to the original thought
      dataItem = {
        thoughtId: thought.uid,
        title: thought.title,
        owner: thought.ownerName
      };

      // check each variable to see if the thought has fields that match its mapping
      hasAllRequiredFields = view.variables.every(viewVariable => {
        let matchingFields = [],
          allFieldsMatch: boolean,
          // TODO: figure out a way to not declare this function in every loop, but still have access to matching Fields
          matchVariableInputToThoughtLabel = (fieldMapping) => {
            // special exception for treating title as field
            if (fieldMapping === 'title' || fieldMapping === 'Title') {
              matchingFields.push({
                label: 'Title',
                type: 'short text',
                value: thought.title
              });
              return true;
            }
            else {
              let match = thought.fields.find(field => this.typeAndLabelMatchVariable(field, fieldMapping, viewVariable.type));
  
              if (match) {
                matchingFields.push(match);
                return true;
              }
            }
            return false;
          };

        if (!thought.fields || thought.fields.length === 0) {
          // skip all the work
        }
        // grab all matching fields
        else if (viewVariable.match === 'all') {

          // if there are no field mappings, grab all fields of matching type
          if (!viewVariable.fieldMappings || viewVariable.fieldMappings.length === 0) {
            let matches = thought.fields.filter(field => this.typeMatchesVariable(field, viewVariable.type));

            matchingFields = [...matchingFields, ...matches];
          }
          // if there are field mappings, grab all fields with matching labels AND types
          else {
             viewVariable.fieldMappings.forEach(fieldMapping => {
                // Special exception for including title as a field
                if (fieldMapping === 'title' || fieldMapping === 'Title') {
                  matchingFields.push({
                    label: 'Title',
                    type: 'short text',
                    value: thought.title
                  });
                }
                else {
                  let matches = thought.fields.filter(field => this.typeAndLabelMatchVariable(field, fieldMapping, viewVariable.type));

                  matchingFields = [...matchingFields, ...matches];
                }
              });
          }

        }
        else if (viewVariable.match === 'first') {
          
          // if there are no fieldMappings, grab first field of matching type
          if (!viewVariable.fieldMappings) {
            let match = thought.fields.find(field => this.typeMatchesVariable(field, viewVariable.type));

            if (match) {
              matchingFields.push(match);
            }
          }
          // if there are field mappings, grab first field that matches mapping & type
          else {
            viewVariable.fieldMappings.some(matchVariableInputToThoughtLabel);
          }
          
        }
        else if (viewVariable.match === 'each') {
          allFieldsMatch = viewVariable.fieldMappings.every(matchVariableInputToThoughtLabel);

          if(!allFieldsMatch) {
            matchingFields = [];
          }
        }

        // Only add to the data item if there is a match
        if ( matchingFields.length > 0 ) {
          // dataItem now contains all the relevant info fields, accessible by the variable name can be used by the template
          dataItem[viewVariable.name] = matchingFields;
        }

        // If a thought is processed if:
        // 1) The view variable is not required, OR
        // 2) The variable is required, set to match each item, and the thought has a label for each item listed in the variable,
        // 3) The variable is required, not set to match each item, and has at least one match
        return !viewVariable.required || (viewVariable.match === 'each' && allFieldsMatch) || (viewVariable.match !== 'each' && matchingFields.length > 0);
      });

      // only thoughts with all required variables get pushed to the map data
      if (hasAllRequiredFields) {
        mappedData.push(dataItem);
      }
    });

    return mappedData;
  }

  // TODO: should this be used by the data mapping function, rather than a post calc add-on
  public flattenData (mappedData, variableToFlatten: string = 'Included Fields', callBack?: (flatItem) => void): any[] {
    let flatData = [];

    if (!mappedData || !mappedData.length) {
      return;
    }

    mappedData.forEach(dataItem => {

      if (!dataItem[variableToFlatten] || !dataItem[variableToFlatten].length) {
        return;
      }

      dataItem[variableToFlatten].forEach(field => {
        let flatItem = dataItem = {
          thoughtId: dataItem.thoughtId,
          title: dataItem.title,
          owner: dataItem.owner
        };

        Object.assign(flatItem, field);
        if (callBack) {
          callBack(flatItem);
        }

        flatData.push(flatItem);
      });

    });

    return flatData;
  }



  public toggleSetting (setting: any, view: View) {
    let variables,
      newValue;

    if (!setting || !setting.toggle || !view || !view.variables) {
      return;
    }

    variables = view.variables.filter(variable => {
      return setting.toggle.variables.some(settingVariable => {
        return variable.name === settingVariable;
      });
    });

    if (setting.value) {
      newValue = setting.toggle.on;
    }
    else {
      newValue = setting.toggle.off;
    }

    variables.forEach(variable => {
      variable[setting.toggle.target] = newValue;
    });
  }

}
