import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';

import { View } from './view/view.service';
import { UserService, User } from '../app-core/user.service';
import { UtilityService, SelectReadyConstant } from '../app-core/utility.service';
import { SortInstruction, ThoughtFilter } from '../thought-search-module/thought-search.service';
import { SharingService } from '../network-module/sharing/sharing.service';
import { isOwner } from 'app/app-core/db-helper.service';

export let COLLECTION_TYPES: SelectReadyConstant[] = [
    {value: 'filter', displayName: 'Automatically Filter'} /*,*/
    // {value: 'manual', displayName: 'Manually Select'}
];

export interface Collection {
  uid: string;
  title: string;
  collectionType: string;
  views: [{
      type: string,
      name: string,
      info: View
  }];
  desc?: string;
  createdDate?: string;
  updatedDate?: string;
  creatorUid: string;
  creatorName: string;
  ownerUid: string;
  ownerName: string;
  sharedWith?: {[index: string]: boolean};
  oldSharedWith?: {[index: string]: boolean};
  isPublic?: boolean;
  thoughts?: string[];
  filter?: ThoughtFilter;
  sortInstructions?: SortInstruction[];
}

@Injectable()
export class CollectionService {
  private _user: User;
  private _collectionString: string;
  private _collectionsRef: AngularFirestoreCollection<Array<Collection>>;

  constructor(
    private _afs: AngularFirestore,
    private _userService: UserService,
    private _utilityService: UtilityService,
    private _sharingService: SharingService
  ) {
        this._init();
  }

  private _init() {
        this._user = this._userService.get();
        this._collectionString = '/collections/';
        this._collectionsRef = this._afs.collection(this._collectionString, ref => isOwner(ref, this._user.uid));
  }

  private _nullEmptyFieldMappings(collection: Collection) {

    if (!collection || !collection.views) {
        console.log('Collection does not exist or collection does not have views');
        return;
    }

    collection.views.forEach(view => {
        if (!view.info || !view.info.variables) {
            console.log('View does not have info or variables in the info');
        }
        view.info.variables.forEach(variable => {
            variable.fieldMappings = variable.fieldMappings || null;
        });
    });
  }

    public getAll() {
        if (this._collectionsRef) {
            return this._afs.collection(this._collectionString, ref => isOwner(ref, this._user.uid).orderBy('updatedDate', 'desc'))
            .valueChanges();
        }
        // TODO: throw an error if no user (or retry?)
    }

    public getRecent(numberOfCollections: number): Observable<Collection[]> {

        numberOfCollections = numberOfCollections || 10;

        if (this._collectionsRef) {
            return this._afs.collection<Collection>(this._collectionString, ref => {
                    return isOwner(ref, this._user.uid)
                        .orderBy('updatedDate')
                        .limit(numberOfCollections)
                } )
                .valueChanges()
                .pipe(map( (arr) => { return arr.reverse(); } ));
        }
        // TODO: throw an error if no user (or retry?)
    }

    public getOne (collectionId: string): Observable<Collection> {
        // TODO: improve security by NOT returning an object that can set data? Need to alter the save/delete method params/logic if done.
        return this._afs.doc<Collection>(this._collectionString + collectionId).valueChanges();
    }

    public create (collectionData: Collection): any {

        if (this._collectionsRef) {
            const newUid = this._afs.createId();

            collectionData.uid = newUid;
            collectionData.createdDate = collectionData.updatedDate = new Date().toJSON();
            collectionData.ownerUid = collectionData.creatorUid = this._user.uid;
            collectionData.ownerName = collectionData.creatorName = this._user.firstName + ' ' + this._user.lastName;

            collectionData.isPublic = false;
            

            this._nullEmptyFieldMappings(collectionData);

            return this._collectionsRef.doc(newUid).set(collectionData);

            // TODO: DBFIX sharing won't work, it used to essentially copy the collection

            // return this._sharingService.composeCreationPromiseWithShares(
            //     collectionData,
            //     () => this._collectionsRef.doc(newUid).set([collectionData]),
            //     (userIds, collectionId) => {
            //         return userIds.map(userId => {
            //             return this._afs.doc(this._collectionString + collectionId).set(collectionData);
            //         });
            //     }
            // );
        }
        // TODO: return error if no user or no title
    }

    public update (collectionData: Collection, collectionObserver: Observable<Collection>) {
        const collectionId = collectionData.uid;
        let removedShares: string[],
            removePromises,
            updatePromise: PromiseLike<any>;

        if (!collectionData || !collectionObserver) {
            return;
        }

        if (collectionData.oldSharedWith) {
            removedShares = this._utilityService.getNewAndRemovedKeys(collectionData.sharedWith, collectionData.oldSharedWith).removed;
        }
        delete collectionData.oldSharedWith;

        collectionData.updatedDate = new Date().toJSON();

        this._nullEmptyFieldMappings(collectionData);

        // TODO: DBFIX share service is broken, need to redesign
        updatePromise = this._sharingService.composePromiseWithShares(collectionData, (id) => {
            return this._afs.doc(this._collectionString + collectionId).update(collectionData);
        });

        // TODO: wait on all the promises, not just the update ones
        if (removedShares && removedShares.length > 0) {
            removePromises = Promise.all(removedShares.map(shareId => {
                return this._afs.doc(this._collectionString + collectionId).delete();
            }));
        }

        return updatePromise;
    }

    public delete (collectionData: Collection) {
        // if shares have been edited, use the originals
        if (collectionData.oldSharedWith) {
            collectionData.sharedWith = collectionData.oldSharedWith;
        }

        // TODO Fix sharing deletion?
        return this._afs.doc(this._collectionString + collectionData.uid).delete();
    }

}
