import { Component, OnInit, OnDestroy } from '@angular/core';

import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

import { UserService } from './app-core/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private userSub;

  public isLoggedIn: boolean;

  constructor (
    public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private _userService: UserService) {
      angulartics2GoogleAnalytics.startTracking();
    }

  ngOnInit() {

    this.userSub = this._userService.getObservable().subscribe(user => {
      if (user && user.uid) {
        this.isLoggedIn = true;
      }
      else {
        this.isLoggedIn = false;
      }
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

}
