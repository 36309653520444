import { Injectable } from '@angular/core';

function _window(): any {
   // return the global native browser window object
   return window;
}

function _navigator(): any {
  // return the global native navigator object
  return navigator;
}

@Injectable()
export class WindowRef {

  get nativeWindow(): any {
    return _window();
  }

  get navigator(): any {
    return _navigator();
  }

}
