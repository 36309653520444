<h2 class="header-major thought-bg">
  Thought Templates
</h2>
<div class="header-actions">
  <a routerLink="/templates/create" class="btn btn-secondary"><i class="fa fa-plus" aria-hidden="true"></i></a>
</div>

<div class="content-box table-tab-container">
  <table class="table table-no-separators table-with-tabs">
    <thead>
      <th>
        <span class="table-tab">Template Name</span>
      </th>
      <th>
        <span class="table-tab">Last Updated</span>
      </th>
    </thead>
    <tr *ngFor="let template of templatesObservable | async">
      <td>
        <a [routerLink]="['/templates/', template.uid]">{{template.templateName}}</a>
      </td>
      <td>
        {{template.updatedDate | date:'short'}}
      </td>
    </tr>
  </table>
</div>