<div class="login-page container">
  <p>
    <strong>Before creating an account, you must accept our <a href="javascript:void(0)" (click)="open(toc)">terms and conditions</a>.</strong>
  </p>
  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
  <div class="form-check mb-4">
      <label class="form-check-label"><input type="checkbox" [(ngModel)]="acceptedTerms" class="form-check-input mr-2">I agree to the ReCog terms and conditions.</label>
  </div>
  <div class="form-group mb-5">
    <button (click)="createAccount()" class="btn btn-primary">Create ReCog Account</button>
  </div>
  <a routerLink="/">Cancel sign up</a>
</div>
<ng-template #toc let-d="dismiss">
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <app-terms></app-terms>
  </div>
</ng-template>