import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-thought-search-page',
  templateUrl: './thought-search-page.component.html',
  styleUrls: ['./thought-search-page.component.scss']
})
export class ThoughtSearchPageComponent implements OnInit {
  public showEditForm = false;
  public showFilters = false;

  constructor(private _titleService: Title) { }

  ngOnInit() {
    this._titleService.setTitle('ReCog - Search Thoughts');
  }

  toggleEditForm () {
    this.showEditForm = !this.showEditForm;
  }
  toggleFilters () {
    this.showFilters = !this.showFilters;
  }
}
