import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

import { TagService } from './tag.service';

@Component({
  selector: 'app-tags',
  providers: [TagService],
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {


    // TODO: make sure that thought template tags work
    @Input() tags;
    @Input() contextClass = 'secondary';

    @Output() tagsUpdated = new EventEmitter<string[]>();

    public tagTitles;
    public savedTags: string[] = [];

    constructor(private _tagService: TagService) { }

    ngOnInit() {
        // get savedTags for autocomplete / knowing when to save new ones
        this._tagService.getAll().subscribe(tags => {
            this.savedTags = tags;
        });
    }

    public updateTags (tags: string[]) {

        // Tags are saved independently from the thought
        // Once added, you're stuck with it. FOREVER.
        // Even if you delete it before saving thought.

        tags.forEach(tag => {
            if (this.savedTags.every(savedTag => savedTag !== tag)) {
                this._tagService.saveTag(tag);
            }
        });

        this.tags = tags;

        this.tagsUpdated.emit(this.tags);
    }
}
