import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { HistoryService } from '../../app-core/history.service';
import { Thought, ThoughtService } from '../thought.service';
import { UserService, User } from '../../app-core/user.service';
import { SharingService } from '../../network-module/sharing/sharing.service';

@Component({
  selector: 'thought-view-basic',
  templateUrl: './thought-view-basic.component.html',
  styleUrls: ['./thought-view-basic.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ThoughtViewBasicComponent implements OnInit {

  private _thoughtObserver: Observable<Thought>;

  public thoughtId: string;
  public thought: Thought;
  public user: User;
  public shared: boolean;

  constructor (
    private thisRoute: ActivatedRoute,
    private _historyService: HistoryService,
    private _thoughtService: ThoughtService,
    private _userService: UserService,
    private _titleService: Title,
    private _sharingService: SharingService) {}

  ngOnInit() {
    this.user = this._userService.get();
    const routeObserver = this.thisRoute.params.subscribe(params => {
       this.thoughtId = params['thoughtId'];

       // TODO: sanitize long text
       // TODO: figure out how to make the editor not so space happy

        // Get thought observable from Service if there is one
        if (this.thoughtId) {
            this._thoughtObserver = this._thoughtService.getOne(this.thoughtId);
            this._thoughtObserver.subscribe( (thoughtData => {
                this.thought = thoughtData;
                this.shared = this._sharingService.isShared(this.thought);

                this._titleService.setTitle('ReCog - ' + this.thought.title);
            }).bind(this));
       }
    });
  }

  public saveThought() {
    if (this.thought && this.thought.uid) {
      this._thoughtService.update(this.thought);
    }
  }

  public back () {
    this._historyService.goBackNot([this.thoughtId]);
  }
}
