<span *ngIf="!isEditing">{{field.value | date:'fullDate'}}</span>

<div *ngIf="isEditing" class="form-inline input-group">
    <input [ngModel]="dateObj"
        (ngModelChange)="onSelectDate($event)"
        ngbDatepicker
        #d="ngbDatepicker"
        class="form-control"
        placeholder="m/d/yyyy"
        name="dp">
    <button class="input-group-addon btn addon-btn" (click)="d.toggle()" type="button">
        <i class="fa fa-calendar" aria-hidden="true"></i>
    </button>
</div>