import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './app-core/not-found/not-found.component';

import { DashboardComponent } from './app-core/dashboard/dashboard.component';

import { CanActivateAuthGuard } from './app-core/can-activate.service';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },
	{ path: 'dashboard',
		component: DashboardComponent,
		canActivate: [CanActivateAuthGuard],
		canActivateChild: [CanActivateAuthGuard]
	},
	{path: '**', component: NotFoundComponent}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}

export const routableComponents = [
	DashboardComponent,
	NotFoundComponent
];
