import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared-module/shared.module';
import { ThoughtModule } from '../thought-module/thought.module';
import { ThoughtSearchModule } from '../thought-search-module/thought-search.module';
import { NetworkModule } from '../network-module/network.module';
import { UtilityService } from '../app-core/utility.service';
import { SharingService } from '../network-module/sharing/sharing.service';

import { CollectionRoutingModule, routableComponents} from './collection-routing.module';

import { CollectionService } from './collection.service';
import { CollectionListComponent } from './collection-list/collection-list.component';

import { ViewService } from './view/view.service';

import { CustomPageComponent } from './view/custom-page/custom-page.component';
import { CustomListComponent } from './view/custom-list/custom-list.component';
import { GalleryComponent } from './view/gallery/gallery.component';
import { TimeLineComponent } from './view/time-line/time-line.component';
import { GoogleChartDirective } from './view/google-chart/google-chart.component';
import { BarGraphComponent } from './view/bar-graph/bar-graph.component';
import { WordCloudComponent } from './view/word-cloud/word-cloud.component';

let privateComponents = [
        CustomPageComponent,
        CustomListComponent,
        GalleryComponent,
        TimeLineComponent,
        GoogleChartDirective,
        BarGraphComponent,
        WordCloudComponent
    ],
    sharedComponents = [
      CollectionListComponent
    ];

@NgModule({
  imports:      [
        CommonModule,
        FormsModule,
        NgbModule,
        SharedModule,
        ThoughtModule,
        NetworkModule,
        ThoughtSearchModule,
        CollectionRoutingModule
  ],
  declarations: [ routableComponents, privateComponents, sharedComponents ],
  exports:      [ sharedComponents ],
  providers:    [ UtilityService, SharingService, CollectionService, ViewService ]
})
export class CollectionModule { }