import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header-logged-out',
  templateUrl: './header-logged-out.component.html',
  styleUrls: ['./header-logged-out.component.scss']
})
export class HeaderLoggedOutComponent implements OnInit {
  public showLinks = true;

  constructor(router:Router) {
  router.events.subscribe( (event: NavigationEnd) => {
    if (event.constructor.name === 'NavigationEnd') {

      if (event.url === '/login' || event.url === '/signup' || event.url ==='/accept-terms') {
        this.showLinks = false;
      }
      else {
        this.showLinks = true;
      }
    }
  });
}

  ngOnInit() {
    
  }

}
