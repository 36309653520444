<div class="field-label">
  <span *ngIf="!isEditing">
    {{field.label}}
  </span>
  <!-- TODO figure out why appFocusMe causes the ng-if below to throw an error:  ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'true'. Current value: 'false'. -->
  <input
    *ngIf="isEditing"
    type="text"
    [(ngModel)]="field.label"
    (change)="fieldUpdated.emit(field)"
    class="form-control"
    placeholder="Info Label"
    appFocusMe
    [readyForFocus]="readyForFocus">
</div>