import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';

import { UserService } from '../../app-core/user.service';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss']
})
export class AcceptTermsComponent implements OnInit {

  private _thisModal: NgbModalRef;

  public user: any = <any>{};
  public error: String;
  public acceptedTerms = false;

  constructor (
    private _afAuth: AngularFireAuth,
    private _afs: AngularFirestore,
    private _router: Router,
    private _userService: UserService,
    private _modalService: NgbModal) { }

  ngOnInit() {
    this._afAuth.authState.subscribe((authData => {
        if (authData) {
            let names = authData.displayName.split(' ');

            this.user.uid = authData.uid;
            this.user.lastName = names.pop();
            this.user.firstName = names.join(' ');
            this.user.email = authData.email;
        }
        else {
          this._router.navigateByUrl('signup');
        }
    }).bind(this));
  }

  public open (content) {
    this._thisModal = this._modalService.open(content, {});
    this._thisModal.result.then( (result) => {}, (reason) => {});
  }

  createAccount() {
      let newUser;

      // acceptance of terms is required
      if ( !this.acceptedTerms ) {
        this.error = 'Please accept the terms.';
        return;
      }

      this.error = null;

      // assumes user already has ID, as this page is for people signing up via Google or Facebook
      newUser = this._afs.doc('/users/' + this.user.uid);

      this.user.createdDate = this.user.updatedDate = new Date().toJSON();
      this.user.role = 'user';

      // TODO: error handling for creating accounts
      
      newUser.set(this.user).then(() => {
        this._router.navigateByUrl('dashboard');
      });
  }

}
