<div class="row">
    <section class="col-md-6">
        <h2 class="header-tab collection-bg">
            Collections
        </h2>
        <div class="header-actions">
            <a routerLink="/collections/create" class="btn btn-secondary"><i class="fa fa-plus" aria-hidden="true"></i></a>
        </div>
        <div class="content-box">
            <app-collection-list [collectionList]="recentCollections" simple="true"></app-collection-list>
        </div>
    </section>
    <section class="col-md-6">
        <h2 class="header-tab thought-bg">
            Recent Thoughts
        </h2>
        <div class="header-actions">
            <a routerLink="/thoughts" class="btn btn-secondary"><i class="fa fa-search" aria-hidden="true"></i></a>
        </div>
        <div class="content-box">
            <app-thought-list [thoughtList]="recentThoughts" simple="true"></app-thought-list>
        </div>
    </section>
</div>