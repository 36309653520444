<h2 class="header-major collection-bg">
  {{collection?.title}}
</h2>

<div class="header-actions">
  <button (click)="toggleEdit()" class="btn btn-secondary mr-2" [ngClass]="{'toggle-active': editingThoughts}">Edit Thoughts</button>
  
  <a routerLink="edit" class="btn btn-secondary">Edit Collection</a>

  <div *ngIf="collection && collection.views && collection.views.length > 1" ngbDropdown class="d-inline-block ml-2">
    <button class="btn btn-secondary" id="collectionViewDropDown" ngbDropdownToggle><i class="fa fa-eye"></i></button>
    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="collectionViewDropDown">
      <button
        *ngFor="let view of collection.views; let i = index"
        (click)="selectView(i)"
        class="dropdown-item">
        {{view.name}}
      </button>
    </div>
  </div>
</div>

<p *ngIf="collection?.desc" class="content-box">{{collection?.desc}}</p>

<!-- TODO: Multi-view per collection -->
<div *ngIf="!editingThoughts">
  <!-- TODO: dynamically load, save the excess template if checks -->
  <app-custom-list
    *ngIf="selectedView?.type === 'List'"
    [view]="selectedView.info"
    [viewData]="viewData"></app-custom-list>
  <app-custom-page
    *ngIf="selectedView?.type === 'Pages'"
    [view]="selectedView.info"
    [viewData]="viewData"></app-custom-page>
  <app-gallery
    *ngIf="selectedView?.type === 'Gallery'"
    [view]="selectedView.info"
    [viewData]="viewData"></app-gallery>
  <app-bar-graph
    *ngIf="selectedView?.type === 'Bar Graph'"
    [view]="selectedView.info"
    [viewData]="viewData"></app-bar-graph>
  <app-time-line
    *ngIf="selectedView?.type === 'Time Line'"
    [view]="selectedView.info"
    [viewData]="viewData"></app-time-line>
  <app-word-cloud
    *ngIf="selectedView?.type === 'Word Cloud'"
    [view]="selectedView.info"
    [viewData]="viewData"></app-word-cloud>
</div>
<div *ngIf="editingThoughts">
  <app-thought-list [thoughtList]="filteredThoughts" showEditForm="true"></app-thought-list>
</div>