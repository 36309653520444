import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators'

@Component({
  selector: 'app-word-list-input',
  templateUrl: './word-list-input.component.html',
  styleUrls: ['./word-list-input.component.scss']
})
export class WordListInputComponent implements OnInit {

  @Input() items: string[];
  @Input() itemName: string;
  @Input() typeaheadWords: string[];
  @Input() contextClass = 'secondary';

  @Output() updated = new EventEmitter<string[]>();
  @Output() itemDeleted = new EventEmitter<string>();

  public newPhrase: string;

  constructor() { }

  ngOnInit() {
    this.typeaheadWords = this.typeaheadWords || [];
  }

  public addPhrase (newFocusElement) {

    if (!this.newPhrase || this.newPhrase.trim() === '') {
      return;
    }


    // since firebase deletes parent when all children are gone... ensure this parent exists
    if (!this.items) {
        this.items = [];
    }
    this.items.push(this.newPhrase);
    this.newPhrase = '';

    if (newFocusElement && newFocusElement.focus) {
        newFocusElement.focus();
    }


    this.updated.emit(this.items);
  }

  public deletePhrase (phrase) {
      this.items = this.items.filter(phraseInArray => phraseInArray !== phrase);

      this.updated.emit(this.items);
      this.itemDeleted.emit(phrase);
  }

  public typeahead  = (text$: Observable<string>) => {
        return text$
            .pipe(
              debounceTime(200),
              map(term => {
                  return term === '' ? [] : this.typeaheadWords.filter(v => new RegExp(term, 'gi').test(v)).slice(0, 10);
              })
            );
      }

}
