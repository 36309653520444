import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { CollectionService, COLLECTION_TYPES, Collection } from '../collection.service';
import { ThoughtService, Thought } from '../../thought-module/thought.service';
import { ThoughtSearchService, SORTABLE_DATA_TYPES, ThoughtFilter} from '../../thought-search-module/thought-search.service';
import { ViewService, View, ViewVariable} from '../view/view.service';
import { UtilityService, SelectReadyConstant } from '../../app-core/utility.service';

@Component({
  selector: 'app-collection-form',
  templateUrl: './collection-form.component.html',
  styleUrls: ['./collection-form.component.scss']
})
export class CollectionFormComponent implements OnInit {

  private _collectionObserver: Observable<Collection>;
  private _cachedCollection: Collection;
  private _allThoughts: Thought[] = [];
  private _simpleSearchValue: string = null;
  private _advancedFilters: ThoughtFilter = null;
  private _showAdvancedSearch: boolean = false;

  public collectionId: string;
  public collection: Collection;
  public sortableDataTypes: SelectReadyConstant[] = SORTABLE_DATA_TYPES;
  public collectionTypes: SelectReadyConstant[] = COLLECTION_TYPES;
  public thoughtResults: Thought[] = [];
  public views: string[];

  constructor (
    private _thisRoute: ActivatedRoute,
    private _collectionService: CollectionService,
    private _thoughtService: ThoughtService,
    private _thoughtSearchService: ThoughtSearchService,
    private _viewService: ViewService,
    private _utilityService: UtilityService,
    private _router: Router,
    private _titleService: Title) {}

  ngOnInit() {

    const routeObserver = this._thisRoute.params.subscribe(params => {
       this.collectionId = params['collectionId'];

       // create a default collection so we don't have to use elvis operator for collection in every template reference
       this.collection = <Collection> {
            title: null,
            collectionType: 'filter',
            sortInstructions: [{
              sortBy: 'updatedDate',
              isAscending: false
            }],
            views: [{
              name: 'List',
              type: 'List',
              info: null
            }], // TODO: make this more dynamic instead of hard-coded?
            isPublic: false
        };

        // Get collection observable from Service if there is one
        if (this.collectionId) {
            this._collectionObserver = this._collectionService.getOne(this.collectionId);
            this._collectionObserver.subscribe( (collectionData => {
                this.collection = collectionData;
                this._titleService.setTitle('ReCog - Edit' + this.collection.title);

                this.collection.sharedWith = this.collection.sharedWith || {};
                this.collection.oldSharedWith = this._utilityService.deepCopy(this.collection.sharedWith);

                if (!this.collection.views) {
                  this.collection.views = [this._viewService.getDefaultView()];
                }
                this.collection.views.forEach(view => {
                  if (!view.info) {
                    view.info = {
                      variables: [],
                      settings: [],
                    };
                  }
                });
                
                this._cachedCollection = this._utilityService.deepCopy(this.collection);

                // this.mergeViewCollectionSettings();
            }).bind(this));
       }
       else {
         this._titleService.setTitle('ReCog - Create Collection');
         this.collection.views[0].info =  this._viewService.getViewInfo(this.collection.views[0].type);
       }

       // Get thoughts for in-form filtering/testing
       this._thoughtService.getAll().subscribe(thoughtData => {
          this._allThoughts = <Thought[]>thoughtData;

          if (!this._showAdvancedSearch && this._simpleSearchValue) {
            this.thoughtResults = this._thoughtSearchService.simpleSearch(this._simpleSearchValue, this._allThoughts);
          }
          else if (this._showAdvancedSearch) {
            this.thoughtResults = this._thoughtSearchService.advancedSearch(this._advancedFilters, this._allThoughts);
          }
          else {
            this.thoughtResults = this._allThoughts;
          }
        });

        // Get View info
        this.views = this._viewService.getViews();
    });

  }

  public resetViewSettings (view) {
    let viewInfo = this._viewService.getViewInfo(view.type);

    // if we somehow have a collection type with no settings, return
    if (!viewInfo) {
      view.info = {};
    }
    else {
      view.info = viewInfo;
    }
  }

  public viewSettingToggle (setting: any, view: View) {
    this._viewService.toggleSetting(setting, view);
  }

  public addView (viewType = 'List') {
    this.collection.views.push({
      name: '',
      type: viewType,
      info: this._viewService.getViewInfo(viewType)
    });
  }

  public removeView (index: number) {
    this.collection.views.splice(index, 1);
  }

  public save () {
    let filter = this.collection.filter,
      filterTitle 

    // TODO: Error Messaging in the UI for lacking title or filter
    if (!this.collection.title) {
      return;
    }
    if ( this.collection.collectionType === 'filter' &&
      (!filter || !this._thoughtSearchService.filterIsValid(filter)) ) {
      return;
    }

    if (this.collectionId) {
        this._collectionService.update(this.collection, this._collectionObserver).then ( () => {
            this._router.navigateByUrl('/collections/' + this.collectionId);
          } );
    }
    else {
    this._collectionService.create(this.collection).then( (response) => {
            this._router.navigateByUrl('/dashboard');
      } );
    }
  }

  public cancel () {
    if (this.collectionId) {
        this._router.navigateByUrl('/collections/' + this.collectionId);
    }
    else {
        this._router.navigateByUrl('/dashboard');
    }
  }

  public delete () {
      // delete method will handle deleted images contained in the actual collection
      this._collectionService.delete(this.collection);
      this._router.navigateByUrl('/dashboard');
  }

  public updateCollectionFilter (searchFilter: ThoughtFilter): void {
    this.collection.filter = searchFilter;
  }

  // TODO: figure out how to match this to variable
  public updateIncludedFields(fields: string[], variable: ViewVariable) {
    variable.fieldMappings = fields || null;
  }

}
