import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TemplateListComponent } from './thought-template/template-list/template-list.component';
import { TemplateCreateComponent } from './thought-template/template-create/template-create.component';
import { TemplateEditComponent } from './thought-template/template-edit/template-edit.component';
import { TemplateViewComponent } from './thought-template/template-view/template-view.component';

import { ThoughtEditComponent } from './thought-edit/thought-edit.component';
import { ThoughtViewBasicComponent } from './thought-view-basic/thought-view-basic.component';

import { CanActivateAuthGuard } from '../app-core/can-activate.service';

const routes: Routes = [
    { path: 'templates',
        component: TemplateListComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
    { path: 'templates/create',
        component: TemplateCreateComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
    { path: 'templates/:templateId',
        component: TemplateViewComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
    { path: 'templates/:templateId/edit',
        component: TemplateEditComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
    { path: 'thoughts/:thoughtId',
        component: ThoughtViewBasicComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
    { path: 'thoughts/:thoughtId/edit',
        component: ThoughtEditComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThoughtRoutingModule {}

export const routableComponents = [
    TemplateListComponent,
	TemplateCreateComponent,
	TemplateEditComponent,
	TemplateViewComponent,
    ThoughtEditComponent,
	ThoughtViewBasicComponent,
];