<ul class="list-unstyled">
    <li *ngFor="let phrase of items" class="btn-group m-2">
          <span class="btn btn-secondary">{{phrase}}</span>
          <button class="btn btn-danger" (click)="deletePhrase(phrase)">X</button>
    </li>
    <li class="form-inline input-group mt-2 mb-2 word-input">
        <input 
            #newPhraseFocus
            type="text"
            placeholder="New {{itemName}}"
            [(ngModel)]="newPhrase"
            [ngbTypeahead]="typeahead"
            class="form-control">
        <button class="btn btn-{{contextClass}} input-group-addon addon-btn" (click)="addPhrase(newPhraseFocus)">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
    </li>
</ul>