<div class="terms-content">
    <h2>Terms and Conditions ("Terms")</h2>
    <p>Last updated: 7/25/17</p>
    <p>Read the terms before using http://www.recog.biz. By creating or using a ReCog account, you agree to be bound by these terms. If you do not agree to these tearms, you may not use ReCog.</p>
    
    <h4>Be careful with your data</h4>
    <p>We will do our best to protect your data, but please DO NOT post sensitive data like bank account numbers, credit card numbers, or passwords. During Alpha development, any ReCog features are subject to change or removal, and it may result in a loss of data. ReCog will not accept responsibility for data loss or theft during Alpha development.</p>
    <p>If you wish to backup your data, you can use browsers (like Chrome) to safe thoughts or collections as PDFs. You may also contact ReCog at <a href="mailto:recoghelp@gmail.com">recoghelp@gmail.com</a> to request a manual backup of your raw data.</p>
    
    <h4>ReCog is currently in Alpha. Enjoy the free access.</h4>
    <p>ReCog is in early development. That means all of our features and services are free for now.</p>
    <p>Although we intend to keep some of services free, we reserve the right to charge for features and services that we deem "premium" in the future. We will alert you before we make any changes to your service.</p>
    <p>For your information, premium services in the future <em>might</em> include data storage above a certain limit, importing data from or exporting data to programs outside of ReCog, or specialized data visualizations. The list of premium features is subject to change.</p>
    
    <h4>Content Rights and Responsibilities</h4>
    <p>The images, words, code, and other content provided in ReCog are the property of Alexander Sorensen.</p>
    <p>All of the content uploaded by the user, such as images, text, and other data is property of the user. Graphs or other visualizations of user data using ReCog also belong to the user.</p>
    <p>Users are responsible for not uploading illegal content or using ReCog in an illegal manner. Don't plagiarize, don't steal, don't upload child pornography. Don't do anything else illegal.</p>
    <p>ReCog can contain links to external websites. ReCog does not assume any responsibility for the content or practices of other websites.</p>
    
    <h4>Treat Others With Respect</h4>
    <p>Currently, users can <a href="mailto:recoghelp@gmail.com">contact ReCog</a> to be added to a network with other users, where thoughts can be shared.</p>
    <p>Be respectful to others while user ReCog. Any complaints of harassment or inappropriate behavior are subject to review. Reviews may result in no action, a warning, or termination of offending accounts.</p>
    <p>Any user attempting to access data NOT shared with them will also be reviewed.</p>
</div>