import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { NetworkService, Network } from '../network.service';
import { UserService, User } from '../../app-core/user.service';

@Component({
  selector: 'app-network-view',
  templateUrl: './network-view.component.html',
  styleUrls: ['./network-view.component.scss']
})
export class NetworkViewComponent implements OnInit {

  private _networkObserver: Observable<Network>;

  public networkId: string;
  public network: Network = {
    uid: null,
    name: null,
    createdDate: null,
    updatedDate: null,
    creatorUid: null,
    creatorName: null,
    admin: null,
    adminInvitee: null,
    invitees: {},
    members: {}
  };
  public members: string[] = [];
  public user: User;

  constructor (
    private thisRoute: ActivatedRoute,
    private _networkService: NetworkService,
    private _titleService: Title,
    private _userService: UserService) {}

  ngOnInit() {
    const routeObserver = this.thisRoute.params.subscribe(params => {
       this.networkId = params['networkId'];
       this.user = this._userService.get();

        // Get thought observable from Service if there is one
        if (this.networkId) {
            this._networkObserver = this._networkService.getOne(this.networkId);
            this._networkObserver.subscribe( (networkData => {
                let allUserIds: string[];
                this.network = networkData;

                if (this.network.members) {
                  this.members = Object.keys(this.network.members);
                }

                allUserIds = Object.keys(networkData.members);
                    if (networkData.admin && !networkData.members[networkData.admin]) {
                      allUserIds.push(networkData.admin);
                    }

                // only admins see invitees

                this._titleService.setTitle('ReCog - ' + this.network.name);
            }).bind(this));
       }
    });
  }
}
