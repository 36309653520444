import { Component, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';

import { View } from '../view.service';
import { UtilityService } from '../../../app-core/utility.service';

@Component({
  selector: 'app-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.scss']
})
export class BarGraphComponent implements OnChanges {

  @Input() view: View;
  @Input() viewData: any;
  @Input() linksToThought = true;

  public barData: any[];
  public barOptions: any = {};

  constructor(
    private _router: Router,
    private _utilityService: UtilityService
    ) { }

  ngOnChanges(changes: any) {

    let infoToGraph;

    if (!changes.viewData || !changes.viewData.currentValue || !this.view || !this.view) {
      return;
    }

    console.log(this.view);

    infoToGraph = this._utilityService.deepCopy(this.view.variables[0].fieldMappings);



    // TODO: Enable multiple columns per thought by switching back to included fields
    this.barData = this.viewData.map(dataItem => {
      let processedArray = [dataItem.title],
        mappedInfoIndex: any = {};

      dataItem['Included Fields'].forEach(mappedField => {
        mappedInfoIndex[mappedField.label] = mappedField.value;
      });

      infoToGraph.forEach(infoLabel => {
        processedArray.push(mappedInfoIndex[infoLabel] || 0);
      });

      return processedArray;
    });

    infoToGraph.unshift('Thought Title');
    this.barData.unshift(infoToGraph);
  }

  // TODO: move this somewhere common (view service?) to DRY
  public selectionCallback = (selectionIndex) => {

    let item = this.viewData[selectionIndex];

    if (!this.linksToThought) {
      return;
    }

    if (item) {
      this._router.navigateByUrl('/thoughts/' + item.thoughtId);
    }
  }

}
