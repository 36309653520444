import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ThoughtService, Thought } from '../thought.service';
import { ThoughtTemplateService, ThoughtTemplate } from '../thought-template/thought-template.service';
import { UserService, User} from '../../app-core/user.service';
import { UtilityService } from '../../app-core/utility.service';


@Component({
  selector: 'app-thought-list',
  templateUrl: './thought-list.component.html',
  styleUrls: ['./thought-list.component.scss']
})
export class ThoughtListComponent implements OnInit {
  @Input() thoughtList: Thought[];
  @Input() showEditForm = false;
  @Input() simple: boolean;
  @Input() contextClass = 'thought';

  public user;

  public selectedThoughts: {[index: string]: boolean} = {};
  public bulkEdits = {
    info: [],
    tags: [],
    template: null
  };
  public bulkShares: {[index: string]: boolean} = {};
  public bulkEditErrors;
  public bulkEditSuccess: string = null;
  public allChecked = false;
  public thoughtTemplates: ThoughtTemplate[] = null;

  constructor(
    private _thoughtService: ThoughtService,
    private _thoughtTemplateService: ThoughtTemplateService,
    private userService: UserService,
    private _utilityService: UtilityService
  ) {}

  ngOnInit() {
    
    this.thoughtList = this.thoughtList || [];
    this.user = this.userService.get();

    this._thoughtTemplateService.getAll().subscribe(templates => {
        this.thoughtTemplates = templates;
    });
  }

  // null the error object if there are no errors (for template if check)
  private clearEmptyErrorObj (): void {
    if (this.bulkEditErrors && Object.keys(this.bulkEditErrors).length === 0) {
      this.bulkEditErrors = null;
    }
  }

  public newInfoEdit (editType: string) {
    // TODO: get list of labels to prepop
    let newItem: any = {
      label: '',
      bulkEditType: editType
    };

    if (editType === 'add' || editType === 'edit') {
      newItem.type = 'short text';
      newItem.value = null;
    }

    // remove case just needs the basic label and editType

    this.bulkEdits.info.push(newItem);
  }

  public removeEditItem (itemType: string, index: number) {
    this.bulkEdits[itemType].splice(index, 1);
  }

  public newTagEdit (editType: string) {
    let newItem: any = {
      tagName: '',
      bulkEditType: editType
    };

    switch (editType) {
      case 'add':
        newItem.placeholder = 'Tag to add';
        break;
      case 'delete':
        newItem.placeholder = 'Tag to delete';
        break;
      case 'edit':
        newItem.placeholder = 'Tag to replace';
        newItem.newTagName = '';
        newItem.newPlaceholder = 'New tag';
        break;
    }

    this.bulkEdits.tags.push(newItem);
  }

  public applyTemplate (template: ThoughtTemplate, thought) {
      // TODO: move this to a bulk edit component at some point
      this._thoughtTemplateService.applyTemplateToThought(template, thought);
  }

  processShareErrors (): boolean {
    let hasShareError = false,
      user: User,
      thoughtsToCheck: Thought[];

    user = this.user;
    thoughtsToCheck = this.thoughtList.filter(thought => this.selectedThoughts[thought.uid]);

    if (thoughtsToCheck.length > 0) {

      hasShareError = thoughtsToCheck.some((thought) => thought.ownerUid !== user.uid);

      if (hasShareError) {
        this.bulkEditErrors = this.bulkEditErrors || {};
        this.bulkEditErrors.shares = 'You do not own all the selected thoughts';
      }
    }

    if (!hasShareError && this.bulkEditErrors) {
      delete this.bulkEditErrors.shares;
      this.clearEmptyErrorObj();
    }

    return hasShareError;
  }

  public updateShares (shareInfo): void {
    let hasShareErrors: boolean;

    hasShareErrors = this.processShareErrors();

    if (!hasShareErrors) {
      this.bulkShares = shareInfo;
    }
  }

  // TODO: fold this into sharing service's "isShared"
  public hasDefinedShare (shareObject: {[index: string]: boolean}): boolean {
    let shareIds;

    if (!shareObject) {
      return false;
    }

    shareIds = Object.keys(shareObject);

    return shareIds.length > 0 && shareIds.some(key => shareObject[key] !== undefined);
  }

  public editThoughts () {
    let allSelectedThoughts: Thought[],
      editedThoughts: PromiseLike<any>[],
      shareIds: string[];

    if (this.bulkEditErrors) {
      return false;
    }

    // TODO: throw an error if no thoughts are selected

    allSelectedThoughts = this.thoughtList.filter(thought => this.selectedThoughts[thought.uid]);
    shareIds = Object.keys(this.bulkShares);
    editedThoughts = allSelectedThoughts.map( (thought: Thought) => {

      // Info Edits

      this.bulkEdits.info.forEach(infoEdit => {
        if ( infoEdit.bulkEditType === 'edit' ) {
          thought.fields.forEach(field => {
            if (field.label === infoEdit.label) {
              // TODO: if there's a new label, change that, too
              field.type = infoEdit.type;
              field.value = infoEdit.value;

              if (infoEdit.newLabel) {
                field.label = infoEdit.newLabel;
              }
            }
          });
        }
        else if ( infoEdit.bulkEditType === 'delete') {
          thought.fields = thought.fields.filter(field => field.label !== infoEdit.label)
        }
        else {
          thought.fields.push({
            label: infoEdit.label,
            type: infoEdit.type,
            value: infoEdit.value
          });
        }
      });

      // Tag Edits

      this.bulkEdits.tags.forEach( tag => {
        if ( tag.bulkEditType === 'edit' ) {
          thought.tags.forEach( (thoughtTag, index) => {
            if (thoughtTag === tag.tagName && tag.newTagName) {
              thought.tags[index] = tag.newTagName;
            }
          });
        }
        else if ( tag.bulkEditType === 'delete' ) {
          thought.tags = thought.tags.filter(thoughtTag => thoughtTag !== tag.tagName);
        }
        else {
          thought.tags.push(tag.tagName);
        }
      });

      // Share Edits
      if (this.bulkShares && this.hasDefinedShare(this.bulkShares)) {
          // add share info if none
          if (!thought.sharedWith) {
            Object.keys(this.bulkShares).forEach(shareId => {
              // clean up builk shares, which will have false/undefined values because of startIndeterminate
              if (!this.bulkShares[shareId]) {
                delete this.bulkShares[shareId];
              }
            });

            thought.oldSharedWith = {};
            thought.sharedWith = this.bulkShares;
          }
          // update existing share info
          else {
            thought.oldSharedWith = thought.sharedWith;
            thought.sharedWith = this._utilityService.deepCopy(thought.sharedWith);

            // ensure that true shares are copied over, false shared are removed, ignore undefined shares
            shareIds.forEach(shareId => {
              let share = this.bulkShares[shareId];

              if (share === true) {
                thought.sharedWith[shareId] = true;
              }
              else if (share === false ) {
                delete thought.sharedWith[shareId];
              }
            });
        }
      }

      return this._thoughtService.update(thought);
    });

    // TODO: success case + error case
    Promise.all(editedThoughts).then((res) => {
      this.bulkEditSuccess = 'Selected thoughts have been updated.';

      setTimeout(
        () => {
          this.bulkEditSuccess = null;
          this.bulkShares = {};
        },
        3000);
    });
  }

  public applyFirstTemplate() {
    this.bulkEdits.template = this.thoughtTemplates[0];
  }
  public clearTemplate() {
    this.bulkEdits.template = null;
  }

  // TODO: check error messages when thought selection changes
  public selectionUpdateTasks = (updateAllBox: boolean = false) => {

    if (updateAllBox) {
      this.setCheckAllBox();
    }

    this.processShareErrors();
  }

  public setCheckAllBox = () => {
    this.allChecked = this.thoughtList.every((thought) => this.selectedThoughts[thought.uid]);
  }

  public toggleAll = () => {
    this.thoughtList.forEach( (thought) => {
      this.selectedThoughts[thought.uid] = this.allChecked;
    });

    this.selectionUpdateTasks();
  }
}
