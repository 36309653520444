import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators'

@Injectable()
export class HistoryService {

  private _history: NavigationEnd[] = [];

  constructor(private _router: Router) {
    this._router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((val: NavigationEnd) => {
        // see also
        this._history.push(val);
    });
  }

  public back() {

    if (history.length === 1 ) {
      return;
    }

    this._history.pop();

    // always remove the route you're moving to: it will be added back in the navigation
    this._router.navigateByUrl(this._history.pop().urlAfterRedirects);
  }

  public goBackNot(notFilters: string[]) {
    const isUnwantedUrl = (historyItem) => {
      return notFilters.some((filter) => {
        return historyItem.urlAfterRedirects.indexOf(filter) > -1;
      });
    };

    while (this._history.length > 1 && isUnwantedUrl(this._history[this._history.length - 1])) {
        this._history.pop();
    }

    if (this._history.length > 0 && !isUnwantedUrl(this._history[this._history.length - 1])) {
      // always remove the route you're moving to: it will be added back in the navigation
      this._router.navigateByUrl(this._history.pop().urlAfterRedirects);
    }
    else {
      this._router.navigateByUrl('/dashboard');
    }
  }
}
