import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-display-fields',
  templateUrl: './display-fields.component.html',
  styleUrls: ['./display-fields.component.scss'],
  providers: [DragulaService]
})
export class DisplayFieldsComponent implements OnInit {

  @Input() fields: ThoughtField[];
  @Input() isEditing: boolean;
  @Input() showLabels: boolean;
  @Input() showType: boolean;
  @Input() hideValue = false;

  @Output() fieldsUpdated = new EventEmitter<ThoughtField[]>();
  @Output() fieldDeleted = new EventEmitter<ThoughtField>();

  public ready: boolean;
  public readyForFocus = false;


  constructor(private _dragulaService: DragulaService) {

    _dragulaService.createGroup('info-bag', {
        moves: function (el, container, handle) {
            return handle.classList.contains('fa-arrows');
        }
    });
   }

  ngOnInit() {
    if (this.showLabels === null || this.showLabels === undefined) {
      this.showLabels = true;
    }
    this.readyForFocus = true;
  }

  public deleteField (field: ThoughtField, index: number) {

        if (!field) { return; }

        this.fields.splice(index, 1);

        this.fieldsUpdated.emit(this.fields);
        this.fieldDeleted.emit(field);
    }

    public moveField (direction: string, index: number) {
      let targetIndex: number,
        targetItem;

      this.ready = false;

      if ( (direction === 'up' && index === 0) || (direction === 'down' && index === this.fields.length - 1) ) {
        return;
      }

      if (direction === 'up') {
        targetIndex = index - 1;
      }
      else {
        targetIndex = index + 1;
      }

      targetItem = this.fields[targetIndex];
      this.fields[targetIndex] = this.fields[index];
      this.fields[index] = targetItem;

      setTimeout(() => { this.ready = true; }, 200);
    }

}
