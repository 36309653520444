import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { CollectionService, Collection } from '../collection.service';
import { ThoughtService, Thought } from '../../thought-module/thought.service';
import { ThoughtSearchService } from '../../thought-search-module/thought-search.service';
import { ViewService, View } from '../view/view.service';
@Component({
  selector: 'app-collection-view-basic',
  templateUrl: './collection-view-basic.component.html',
  styleUrls: ['./collection-view-basic.component.scss']
})
export class CollectionViewBasicComponent implements OnInit {

  private _collectionObserver: Observable<Collection>;
  private _allThoughts: Thought[];

  public collectionId: string;
  public collection: Collection;
  public filteredThoughts: Thought[];
  public viewData: any;
  public editingThoughts: boolean;
  public selectedView: any;

  constructor(
    private thisRoute: ActivatedRoute,
    private _titleService: Title,
    private _collectionService: CollectionService,
    private _viewService: ViewService,
    private _thoughtService: ThoughtService,
    private _thoughtSearchService: ThoughtSearchService
  ) {}

  ngOnInit() {
    const routeObserver = this.thisRoute.params.subscribe(params => {
       this.collectionId = params['collectionId'];

        // Get thought collection from service if there is one
        if (this.collectionId) {
            this._collectionObserver = this._collectionService.getOne(this.collectionId);
            this._collectionObserver.subscribe( (collectionData => {
                this.collection = collectionData;

                // TODO: a combine these, instead of nesting
                // TODO: kill subscriptions
                this._thoughtService.getAll().subscribe(thoughtData => {
                    this._allThoughts = thoughtData;

                    if (this.collection.filter ) {
                      this.filteredThoughts = this._thoughtSearchService.advancedSearch(this.collection.filter, this._allThoughts);
                    }

                     // Get the view
                    if (this.collection.views) {
                      this.selectView();
                    }

                });

                this._titleService.setTitle('ReCog - ' + this.collection.title);
            }).bind(this));
       }
    });
  }



  private _generateViewData () {
    let thoughts = this.filteredThoughts || this._allThoughts;

    this.viewData = this._viewService.mapThoughtsToView(thoughts, this.selectedView.info);

  }

  public selectView (i = 0) {
    if (this.collection && this.collection.views && this.collection.views[i]) {
      this.selectedView = this.collection.views[i];
      this._generateViewData();
    }
  }

  public toggleEdit () {
    this.editingThoughts = !this.editingThoughts;
  }

}
