import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { ThoughtTemplateService, ThoughtTemplate} from '../thought-template.service';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit {

  public templatesObservable: Observable<ThoughtTemplate[]>;

  constructor(
    private _thoughtTemplateService: ThoughtTemplateService,
    private _titleService: Title) { }

  ngOnInit() {
     this._titleService.setTitle('ReCog - Thought Templates');
    this.templatesObservable = this._thoughtTemplateService.getAll();
    
  }

}
