<!-- TODO: figure out what in the thought list component triggers a firebase call -->

<!-- TODO: make Editing its own widget? -->
<div id="bulkEditForm" style="clear: both" [ngbCollapse]="!showEditForm" class="content-box">
  <h4 class="header-major {{contextClass}}-bg">Edit Selected Thoughts</h4>
  <div class="header-actions no-float-xs">
    <div *ngIf="thoughtTemplates && thoughtTemplates.length > 0" class="form-group form-inline input-group">
        <button *ngIf="bulkEdits.template === null" (click)="applyFirstTemplate()" class="btn btn-secondary">Apply a Thought Template</button>
        <select *ngIf="bulkEdits.template !== null" [(ngModel)]="bulkEdits.template" class="form-control">
          <option *ngFor="let template of thoughtTemplates" [ngValue]="template">{{template.templateName}}<option>
        </select>
        <button *ngIf="bulkEdits.template !== null" (click)="clearTemplate()" class="btn input-group-addon addon-btn btn-clear-template">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="form-group">
    <h4 class="header-subsection">Info</h4>
    <div class="header-sub-actions">
      <button (click)="newInfoEdit('add')" class="btn btn-secondary"><i class="fa fa-plus" aria-hidden="true"></i></button>
      <button (click)="newInfoEdit('edit')" class="btn btn-secondary"><i class="fa fa-pencil" aria-hidden="true"></i></button>
      <button (click)="newInfoEdit('delete')" class="btn btn-secondary"><i class="fa fa-trash" aria-hidden="true"></i></button>
    </div>
    <!-- TODO: change to cols -->

    <div class="info-edit-items pt-3 pb-2">
      <div class="row" *ngFor="let infoItem of bulkEdits.info; let i = index">
        <div class="edit-type-container col-md-1 mb-3">
            <span *ngIf="infoItem.bulkEditType === 'add'"class="bulk-edit-icon">
              <span class="hide-text">Add</span> <i class="fa fa-plus" aria-hidden="true"></i>
            </span>
            <span *ngIf="infoItem.bulkEditType === 'edit'"class="bulk-edit-icon bulk-edit">
              <span class="hide-text">Edit</span> <i class="fa fa-pencil" aria-hidden="true"></i>
            </span>
            <span *ngIf="infoItem.bulkEditType === 'delete'"class="bulk-edit-icon bulk-delete">
              <span class="hide-text">Delete</span> <i class="fa fa-trash" aria-hidden="true"></i>
            </span>
        </div>
        <div class="col-md-3 col-lg-4 mb-3">
          <app-display-field-label [field]="infoItem" isEditing="true"></app-display-field-label>
          <input type="text"
              [(ngModel)]="infoItem.newLabel"
              placeholder="New label (optional)"
              class="form-control"
              *ngIf="infoItem.bulkEditType === 'edit'"/>
        </div>
        <div class="col-md-2 mb-3">
          <app-display-field-type
              *ngIf="infoItem.bulkEditType !== 'delete'"
              [field]="infoItem"
              isEditing="true"></app-display-field-type>
        </div>
        <div class="col-md-4 mb-3">
          <app-display-field-value
              *ngIf="infoItem.bulkEditType !== 'delete'"
              [field]="infoItem"
              isEditing="true"></app-display-field-value>
        </div>
        <div class="col-md-2 col-lg-1 mb-3">
          <button (click)="removeEditItem('info', i)" class="btn btn-danger"><i class="fa fa-trash" aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <h4 class="header-subsection">Tags</h4>
    <div class="header-sub-actions">
      <button (click)="newTagEdit('add')" class="btn btn-secondary"><i class="fa fa-plus" aria-hidden="true"></i></button>
      <button (click)="newTagEdit('edit')" class="btn btn-secondary"><i class="fa fa-pencil" aria-hidden="true"></i></button>
      <button (click)="newTagEdit('delete')" class="btn btn-secondary"><i class="fa fa-trash" aria-hidden="true"></i></button>
    </div>
    <div class="tag-edit-items pt-3 pb-2">
      <div class="row" *ngFor="let tagItem of bulkEdits.tags; let i = index">
        <div class="edit-type-container col-md-1 mb-3">
          <span *ngIf="tagItem.bulkEditType === 'add'"c lass="bulk-edit-icon">
            <span class="hide-text">Add</span> <i class="fa fa-plus" aria-hidden="true"></i>
          </span>
          <span *ngIf="tagItem.bulkEditType === 'edit'"class="bulk-edit-icon bulk-edit">
            <span class="hide-text">Edit</span> <i class="fa fa-pencil" aria-hidden="true"></i>
          </span>
          <span *ngIf="tagItem.bulkEditType === 'delete'"class="bulk-edit-icon bulk-delete">
            <span class="hide-text">Delete</span> <i class="fa fa-trash" aria-hidden="true"></i>
          </span>
        </div>
        <div class="col-md-5 mb-3">
          <input
              [(ngModel)]="tagItem.tagName"
              [placeholder]="tagItem.placeholder"
              type="text"
              class="form-control">
        </div>
        <div class="col-md-5 mb-3">
          <input
                *ngIf="tagItem.bulkEditType === 'edit'"
              [(ngModel)]="tagItem.newTagName"
              [placeholder]="tagItem.newPlaceholder"
              type="text"
              class="form-control">
        </div>
        <div class="col-md-1 mb-3">
          <button (click)="removeEditItem('tags', i)" class="btn btn-danger"><i class="fa fa-trash" aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
    <!-- TODO: create form for add edit drop -->
  </div>
  <!-- TODO: Enable networks -->
  <!-- <app-sharing [shareInfo]="bulkShares" (updated)="updateShares($event)" [startIndeterminate]="true" [alwaysShowForm]="true"></app-sharing> -->
  <div class="alert alert-success" *ngIf="bulkEditSuccess">{{bulkEditSuccess}}</div>
  <div class="alert alert-danger" *ngIf="bulkEditErrors">
    Edits cannot be made:
    <ul>
      <li *ngIf="bulkEditErrors.shares">{{bulkEditErrors.shares}}</li>
    </ul>
  </div>
  <button (click)="editThoughts()" class="btn btn-primary">Update Thoughts</button>
</div>


<!-- List itself  -->
<div [ngClass]="{'table-tab-container': !simple, 'content-box': !simple}">
  <table class="table table-no-separators" [ngClass]="{'table-sm': simple, 'table-with-tabs': !simple}">
    <thead *ngIf="!simple">
      <th *ngIf="showEditForm">
        <label class="checkbox-label">
          <input type="checkbox" [(ngModel)]="allChecked" (change)="toggleAll()" class="checkbox-easy-click">
        </label>
      </th>
      <th>
        <span class="table-tab">Thought</span>
      </th>
      <th>
        <span class="table-tab">Updated</span>
      </th>
      <th class="not-sm">
        <span class="table-tab">Created</span>
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let thought of thoughtList">
        <td *ngIf="showEditForm">
          <label class="checkbox-label">
            <input type="checkbox" [(ngModel)]="selectedThoughts[thought.uid]" (change)="selectionUpdateTasks(true)" class="checkbox-easy-click">
          </label>
        </td>
        <td>
          <a [routerLink]="['/thoughts/', thought.uid]">{{thought.title}}</a>
          <span
            *ngIf="hasDefinedShare(thought.sharedWith)"
            [ngClass]="{'sharer-icon': thought.ownerUid === user.uid, 'sharee-icon': thought.ownerUid !== user.uid}"
            class="fa fa-users"></span>
        </td>
        <td *ngIf="!simple">
          {{thought.updatedDate | date:'short'}}
        </td>
        <td *ngIf="!simple" class="not-sm">
          {{thought.createdDate | date:'short'}}
        </td>
      </tr>
    </tbody>
  </table>
</div>

