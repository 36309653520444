<header class="site-header content-box clearfix no-print">
	<h1 class="float-left">
		<a href="http://recog.biz" class="branding">
			<img src="assets/img/recog-logo.png" class="branding-img" width="80" height="50"> 
			<span class="branding-name">ReCog</span>
		</a>
  </h1>
  <nav *ngIf="showLinks" class="float-right">
    <ul class="list-inline">
      <li class="list-inline-item"><a routerLink="login" class="btn btn-link">Log In</a></li>
      <li class="list-inline-item"><a routerLink="signup" class="btn btn-primary">Sign Up</a></li>
    </ul>
  </nav>
</header>