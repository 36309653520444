<div ngbDropdown *ngIf="userNetworks && (!owner || user.uid === owner) && !alwaysShowForm">
        <button
            ngbDropdownToggle
            id="shareDropButton"
            class="share-btn btn btn-secondary"
            [ngClass]="{'sharer-icon': owner === user?.uid && hasAShare, 'not-shared': !hasAShare }">
                <span class="fa fa-users" aria-hidden="true"></span>
        </button>
        <ul class="list-unstyled dropdown-menu">
        <li *ngFor="let network of userNetworks" class="dropdown-item">
            <label class="share-network h5"><input type="checkbox" [checked]="networkShares[network.uid]" (change)="updateNetworkShares(network.uid)" [indeterminate]="startIndeterminate && networkShares[network.uid] === undefined"> {{network.name}}</label>
            <ul class="list-unstyled">
                <li *ngFor="let memberId of memberHash[network.uid]">
                    <label class="share-person" *ngIf="memberId !== user?.uid">
                        <input type="checkbox" [(ngModel)]="shareInfo[memberId]" (change)="processUpdates(true)"  [indeterminate]="startIndeterminate && shareInfo[memberId] === undefined" *ngIf="shareInfo"> {{network.members[memberId]}}
                    </label>
                </li>
            </ul>
        </li>
    </ul>
</div>