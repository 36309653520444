import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { takeWhile } from 'rxjs/operators'

import { HistoryService } from '../history.service';
import { User, UserService } from '../user.service';
import { ThoughtService, Thought } from '../../thought-module/thought.service';
import { NetworkService, Network } from '../../network-module/network.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private _closeResult: string;
  private _thisModal: NgbModalRef;
  private modalContent: any = null;
  private _alive: boolean = true;

  public user: User;
  public userNetworks: Network[];
  public networkInvites: Network[];
  public thoughtModalOptions: any = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };
  public helpModalOptions: any = {size: 'lg'};

  constructor (
    private _modalService: NgbModal,
    private _thoughtService: ThoughtService,
    private _networkService: NetworkService,
    private _historySerivce: HistoryService,
    public userService: UserService) {}

  ngOnInit() {
    this.user = this.userService.get();

    if (this.user) {
      this._networkService.getNetworksFromInvites()
        .pipe(
          takeWhile(() => this._alive)
        )
        .subscribe(networks => {
          this.networkInvites = networks;
        });

      this._networkService.getUserNetworks()
        .pipe(takeWhile(() => this._alive))
        .subscribe(networks => {
          this.userNetworks = networks;
        });
    }

  }

  ngOnDestroy() {
    this._alive = false;
  }



  public open (content, options) {
    this.modalContent = content;
    this._thisModal = this._modalService.open(content, options);
    this._thisModal.result.then( (result) => {}, (reason) => {});
  }

  // TODO: switch modals instead of stacking them when I figure out how to do it
  toggleCreateThoughtModal(secondaryModalIsOpen: boolean) {

    // console.log(secondaryModalIsOpen);

    // if (secondaryModalIsOpen) {
    //   this._thisModal.close();
    // }
    // else {
    //   this.open(this.modalContent, this.thoughtModalOptions);
    // }
  }

  public thoughtSaved(savedThought: Thought): void {
    this._thoughtService.create(savedThought);
  }
  public thoughtCancelled(cancelledThought: Thought): void {
    this._thisModal.dismiss('Thought Canceled');
  }


  // TODO: LEFT OFF HERE wire the template to these functions, test 
  public acceptInvite (network: Network) {
    this._networkService.acceptInvite(network);
  }

  public rejectInvite (network: Network) {
    this._networkService.rejectInvite(network);
  }

}
