<div class="container login-page card card-block">
    <h2 class="login-header mb-4">Log In <small class="float-right"><a routerLink="/signup" >(or sign up)</a></small></h2>
    <div class="alert alert-danger" *ngIf="error">{{error}}</div>
    <div class="login-form clearfix">
        <div class="form-group row">
            <label class="col-md-3">Email</label>
            <div class="col-md-9">
                <input type="email" [(ngModel)]="email" class="form-control" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3">Password</label>
            <div class="col-md-9">
                <input type="password" [(ngModel)]="password" class="form-control" />
            </div>
        </div>
        <button (click)="login()" type="submit" class="btn btn-primary btn-login float-right">Login with Email</button>

        <div class="alt-login">
            <button class="btn btn-secondary" (click)="loginGoogle()">
                <i class="fa fa-google" aria-hidden="true"></i>
                Google Log In
            </button>
            <button class="btn btn-secondary" (click)="loginFacebook()">
                <i class="fa fa-facebook-official" aria-hidden="true"></i>
                Facebook Log In
            </button>
        </div>
    </div>
</div>
