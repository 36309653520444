import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-display-field-label',
  templateUrl: './display-field-label.component.html',
  styleUrls: ['./display-field-label.component.scss']
})
export class DisplayFieldLabelComponent implements OnInit {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;
  @Input() readyForFocus: boolean;

  @Output() fieldUpdated = new EventEmitter<ThoughtField>();

  constructor() { }

  ngOnInit() {
  }

}
