<div *ngIf="!isEditing">
    <img src="{{field.value}}" class="info-img view">
</div>
<!-- TODO: figure out why upload doesn't seem to do anything -->
<div *ngIf="isEditing" class="file-input-group">
    <img src="{{field.value}}" *ngIf="field.value" class="info-img edit" >
    <input type="file" (change)="upload($event, field)" class="hidden-file-input">

    <button *ngIf="!field.value" class="btn btn-thought fake-file-input"><i class="fa fa-upload" aria-hidden="true"></i></button>
</div>
