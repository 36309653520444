import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

import { ThoughtService, Thought } from '../../thought-module/thought.service';
import { ThoughtSearchService, ThoughtFilterItem, ThoughtFilter } from '../thought-search.service';

@Component({
  selector: 'app-thought-search',
  templateUrl: './thought-search.component.html',
  styleUrls: ['./thought-search.component.scss']
})
export class ThoughtSearchComponent implements OnInit {

  @Input() showFilters: boolean = false;
  @Input() advancedFilters: ThoughtFilter;
  @Input() showEditForm = false;
  @Input() contextClass = 'thought';

  @Output() filterUpdated = new EventEmitter<ThoughtFilter>();
  @Output() thoughtSelectionUpdated = new EventEmitter<Thought[]>();

  public thoughtResults: Thought[] = [];


  private _allThoughts: Thought[] = [];
  private _simpleSearchValue: string = null;

  constructor(private _thoughtService: ThoughtService, private _thoughtSearchService: ThoughtSearchService) { }

  ngOnInit() {

    this._thoughtService.getAll().subscribe(thoughtData => {
      this._allThoughts = <Thought[]>thoughtData;

      if (!this.showFilters && this._simpleSearchValue) {
        this.thoughtResults = this._thoughtSearchService.simpleSearch(this._simpleSearchValue, this._allThoughts);
      }
      else if (this.showFilters) {
        this.thoughtResults = this._thoughtSearchService.advancedSearch(this.advancedFilters, this._allThoughts);
      }
      else {
        this.thoughtResults = this._allThoughts;
      }
    });
  }

  public runSimpleSearch(searchTerms: string): void {
    this._simpleSearchValue = searchTerms;
    this.thoughtResults = this._thoughtSearchService.simpleSearch(this._simpleSearchValue, this._allThoughts);
  }

  public runAdvancedSearch (searchFilter: ThoughtFilter): void {

    // get the reference for the filter
    this.advancedFilters = searchFilter;

    // pass on the event
    this.filterUpdated.emit(this.advancedFilters);

    // update thought results
    this.thoughtResults = this._thoughtSearchService.advancedSearch(this.advancedFilters, this._allThoughts);
  }
}
