<app-short-text
  *ngIf="field.type === 'short text'"
  [field]="field"
  [isEditing]="isEditing"
  (fieldUpdated)="fieldUpdated.emit(field)"></app-short-text>
<app-long-text
  *ngIf="field.type === 'long text'"
  [field]="field"
  [isEditing]="isEditing"
  (fieldUpdated)="fieldUpdated.emit(field)"
  [ready]="ready"
  [index]="i"></app-long-text>
<app-date
  *ngIf="field.type === 'date'"
  [field]="field"
  [isEditing]="isEditing"
  (fieldUpdated)="fieldUpdated.emit(field)"></app-date>
<app-number
  *ngIf="field.type === 'number'"
  [field]="field"
  [isEditing]="isEditing"
  (fieldUpdated)="fieldUpdated.emit(field)"></app-number>
<app-image
  *ngIf="field.type === 'image'"
  [field]="field"
  [isEditing]="isEditing"
  (fieldUpdated)="fieldUpdated.emit(field)"></app-image>
<app-checkbox
  *ngIf="field.type === 'checkbox'"
  [field]="field"
  [isEditing]="isEditing"
  (fieldUpdated)="fieldUpdated.emit(field)"></app-checkbox>
