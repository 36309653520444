import { Component, DoCheck, Input, ElementRef, KeyValueDiffers } from '@angular/core';
import * as D3 from 'd3';

import { View } from '../view.service';

declare const d3: any;

// TODO: "Solve Cannot convert undefined or null to object" on ng check

@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss']
})
export class WordCloudComponent implements DoCheck {

  @Input() view: View;
  @Input() viewData: any;

  private _host;              // D3 object referencing host DOM object
  private _svg;               // SVG in which we will print our chart
  private _margin: {          // Space between the svg borders and the actual chart graphic
    top: number,
    right: number,
    bottom: number,
    left: number
  };
  private _width: number;      // Component width
  private _height: number;     // Component height
  private _contentBoxPadding = 21; // Trying to put it into a box, don't know where this goes.
  private _htmlElement: HTMLElement; // Host HTMLElement
  private _minCount: number;   // Minimum word count
  private _maxCount: number;   // Maximum word count
  private _fontScale;          // D3 scale for font size
  private _fillScale;          // D3 scale for text color
  private _objDiffer;

  public config = {
    dataset: [],
    settings: {
      fontFace: null,
      fontWeight: null,
      spiral: null,
      minFontSize: null,
      maxFontSize: null
    }
  };

  private _regSigns: RegExp = /\-|\+|\*|\$|\/|\#|\@|&|\^|\\|\[|\]/;
  private _regArt: RegExp = /a|an|the/;
  private _regConj: RegExp = /and|but|if|or|so|because|'cause|since|also|then|although|however/;
  private _regPronouns: RegExp = /i|me|my|mine|you|your|yours|he|him|his|she|her|hers|it|its|we|us|our|ours|they|them|their|theirs|that|those|this|these|there|here/;
  private _regVerbs: RegExp = /is|are|be|being|been|was|were|will|do|does|did|doing|have|had|having|has|get|got|gotten|getting|can|could|would|should|going/;
  private _regPrep: RegExp = /to|of|from|in|into|out|on|off|with|by|at|about|around|for|up|down/;
  private _regComp: RegExp = /not|like|as|than|more|less|most|least|really|very/;
  private _regGeneric: RegExp = /thing|things|anything|stuff|some|something|someone/;
  private _regQuestions: RegExp = /who|when|what|where|why|how/;
  private _regMisc: RegExp = /&nbsp|nbsp/;
  // TODO: create ignore settings for the view: pronouns, prepositions, articles, conjuctions, be verbs/helper verbs
  public verbotten: RegExp = new RegExp('^('
    + this._regSigns.source + '|'
    + this._regArt.source + '|'
    + this._regConj.source + '|'
    + this._regPronouns.source + '|'
    + this._regVerbs.source + '|'
    + this._regPrep.source + '|'
    + this._regComp.source + '|'
    + this._regGeneric.source + '|'
    + this._regQuestions.source + '|'
    + this._regMisc.source + ')$', 'i');
  public cloudLayout: any;

  constructor(private _element: ElementRef, private _keyValueDiffers: KeyValueDiffers) {
    this._htmlElement = this._element.nativeElement;
    this._host = D3.select(this._element.nativeElement);
    this._objDiffer = this._keyValueDiffers.find([]).create();
  }

  ngDoCheck() {
    let changes = this._objDiffer.diff(this.viewData);
    if (changes && this.viewData) {
      let flattenedData = this._concatText(this.viewData)
      this._countWords(flattenedData);
      this._setLayout();
      this.cloudLayout.start();

      // STACK OVERFLOW FOR REFERENCE
      // this._setup();
      // this._buildSVG();
      // this._populate();
    }
  }

  private _concatText (viewData: any): string {
    let superString = '';

    viewData.forEach(dataItem => {
        dataItem['Included Fields'].forEach(info => {
          if (info.value) {
            superString += info.value;
          }
        });
      });

    return superString;
  };

  private _countWords (longString: string) {
    let wordArray: string[],
        instanceCounts = {},
        processedData: {text: string, size: number}[];

    // strip out html and split the words along any punctuation (except decimals)
    wordArray = longString
      .replace(/(<([^>]+)>)/gi, '')
      .split(/[\s\t\?\!\(\)\[\]\{\}\",;]+|\.(?!\d)\s*/);

    wordArray.forEach(word => {
      // make lowercase, remove possessives and contractions (for consistency)
      word = word
              .replace(/('m|'d|'ve|'s|'re|n't|:)$/i, '');

      if (word && word.search(this.verbotten) === -1) {
        word = word.toLocaleLowerCase()
        instanceCounts[word] ? instanceCounts[word] += 1 : instanceCounts[word] = 1;
      }
    });

    processedData = Object.keys(instanceCounts).map(instance => {return {text: instance, size: instanceCounts[instance]}; });
    console.log(processedData);
    // assume words that appear less than once / three entries on average are unimportant
    // TODO: make this smarter?
    // Natural log is a great way to get a number that increases slowly with data size... but it's ultimately arbitrary
    this.config.dataset = processedData.filter(word => word.size > Math.log(this.viewData.length));
    this.config.dataset.sort( (a,b) => {
      if (a.size > b.size) {
        return -1;
      }
      else if (a.size < b.size) {
        return 1;
      }
      return 0;
    });

    console.log(this.config.dataset);
  }

  private _setLayout () {

    let minCount: number,
      maxCount: number,
      minFontSize: number,
      maxFontSize: number,
      fontScale: any;

    this._margin = {
      top   : 10,
      right : 10,
      bottom: 10,
      left  : 10
    };
    this._width = ((this._htmlElement.parentElement.clientWidth === 0)
        ? 300
        : this._htmlElement.parentElement.clientWidth) - this._margin.left - this._margin.right;
    if (this._width < 100) {
      this._width = 100;
    }
    this._height = this._width * 0.5 - this._margin.top - this._margin.bottom;

    minCount = D3.min(this.config.dataset, d => d.size);
    maxCount = D3.max(this.config.dataset, d => d.size);

    minFontSize = (this.config.settings.minFontSize == null) ? 18 : this.config.settings.minFontSize;
    maxFontSize = (this.config.settings.maxFontSize == null) ? 96 : this.config.settings.maxFontSize;

    fontScale = D3.scaleLinear()
                  .domain([minCount, maxCount])
                  .range([minFontSize, maxFontSize]);

    this.cloudLayout = d3.layout.cloud()
    .size([this._width, this._height])
    .words(this.config.dataset)
    .padding(5)
    // .rotate(function() { return ~~(Math.random() * 2) * 90; })
    .rotate(0)
    .font('Impact')
    .fontSize(d => fontScale(d.size))
    .on('end', () => {this._draw(this.config.dataset)});
  }

  private _draw(words) {
    let fill = D3.scaleOrdinal(d3.schemeCategory20),
        layout = this.cloudLayout;

    this._host.html('');
    this._svg = this._host
                  .append('svg')
                    .attr('width', this._width + this._margin.left + this._margin.right)
                    .attr('height', this._height + this._margin.top + this._margin.bottom)
                    // .attr('class', 'content-box')
                  .append('g')
                    .attr('transform', 'translate(' + ~~(this._width / 2) + ',' + ~~(this._height / 2) + ')');

    this._svg
      .selectAll('text')
        .data(words)
      .enter().append('text')
        .style('font-size', function(d: any) { return d.size + 'px'; })
        .style('font-family', 'Impact')
        .attr('fill', (d, i) => D3.schemeCategory10[i])
        .attr('text-anchor', 'middle')
        .attr('transform', function(d: any) {
          return 'translate(' + [d.x, d.y] + ') rotate(' + d.rotate + ')';
        })
        .text(function(d: any) { return d.text; })
        .append('svg:title') // TITLE APPENDED HERE
          .text(function(d) { return d.size; });
    }

  // THE STACK OVERFLOW CODE FOR REFERENCE (couldn't get it to work as was)

  private _setup() {
    this._margin = {
      top   : 10,
      right : 10,
      bottom: 10,
      left  : 10
    };
    this._width = ((this._htmlElement.parentElement.clientWidth == 0)
        ? 300
        : this._htmlElement.parentElement.clientWidth) - this._margin.left - this._margin.right;
    if (this._width < 100) {
      this._width = 100;
    }
    this._height = this._width * 0.75 - this._margin.top - this._margin.bottom;

    this._minCount = D3.min(this.config.dataset, d => d.size);
    this._maxCount = D3.max(this.config.dataset, d => d.size);

    let minFontSize: number = (this.config.settings.minFontSize == null) ? 18 : this.config.settings.minFontSize;
    let maxFontSize: number = (this.config.settings.maxFontSize == null) ? 96 : this.config.settings.maxFontSize;
    this._fontScale = D3.scaleLinear()
                        .domain([this._minCount, this._maxCount])
                        .range([minFontSize, maxFontSize]);
    this._fillScale = D3.scaleOrdinal(D3.schemeCategory10);
  }

  private _buildSVG() {
    this._host.html('');
    this._svg = this._host
                    .append('svg')
                    .attr('width', this._width + this._margin.left + this._margin.right)
                    .attr('height', this._height + this._margin.top + this._margin.bottom)
                    .append('g')
                    .attr('transform', 'translate(' + ~~(this._width / 2) + ',' + ~~(this._height / 2) + ')');
  }

  private _populate() {
    let fontFace: string = (this.config.settings.fontFace == null) ? 'Roboto' : this.config.settings.fontFace;
    let fontWeight: string = (this.config.settings.fontWeight == null) ? 'normal' : this.config.settings.fontWeight;
    let spiralType: string = (this.config.settings.spiral == null) ? 'rectangular' : this.config.settings.spiral;

    d3.layout.cloud()
      .size([this._width, this._height])
      .words(this.config.dataset)
      .rotate(() => 0)
      .font(fontFace)
      .fontWeight(fontWeight)
      .fontSize(d => this._fontScale(d.count))
      .spiral(spiralType)
      .on('end', () => {
        this._drawWordCloud(this.config.dataset);
      })
      .start();
  }

  private _drawWordCloud(words) {
    this._svg
        .selectAll('text')
        .data(words)
        .enter()
        .append('text')
        .style('font-size', d => d.size + 'px')
        .style('fill', (d, i) => {
          return this._fillScale(i);
        })
        .attr('text-anchor', 'middle')
        // This line is always broken, unclear why
        .attr('transform', function(d: any) {
          return 'translate(' + [d.x, d.y] + ') rotate(' + d.rotate + ')';
        })
        .attr('class', 'word-cloud')
        .text(d => {
          return d.word;
        });
  }

}
