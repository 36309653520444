import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';

import { UtilityService } from '../../app-core/utility.service';
import { UserService, User } from '../../app-core/user.service';
import { ThoughtData, Thought } from '../thought.service';
import { isOwner } from 'app/app-core/db-helper.service';

export interface ThoughtTemplate extends ThoughtData {
  templateName: string;
}

@Injectable()
export class ThoughtTemplateService {

  private _cachedTemplates;
  private _templateString: string = '/templates/';
  private _userTemplatesRef: AngularFirestoreCollection<ThoughtTemplate>;
  private _user: User;

  constructor(
      private _afs: AngularFirestore,
      private _userService: UserService,
      private _utilityService: UtilityService
    ) {
      this._init();
    }

  private _init() {
      this._user = this._userService.get();

      if (this._user && this._user.uid) {
          this._userTemplatesRef = this._afs.collection<ThoughtTemplate>(this._templateString, ref => {
              return isOwner(ref, this._user.uid)
                .orderBy('templateName')
            });
      }
  }
  public getAll(): Observable<ThoughtTemplate[]> {

      if ( !this._user ) {
          this._init();
      }

      if (this._userTemplatesRef) {
          return this._userTemplatesRef.valueChanges();
      }
      // TODO: throw an error if no user (or retry?)
  }
  public getOne (templateId: string): Observable<ThoughtTemplate> {

      if ( !this._user ) {
          this._init();
      }

      // TODO: improve security by NOT returning an object that can set data? Need to alter the save/delete method params/logic if done.
      return this._afs.doc<ThoughtTemplate>(this._templateString + templateId).valueChanges();
  }

  public create (templateData: ThoughtTemplate): Promise<ThoughtTemplate> {

        if ( !this._user ) {
            this._init();
        }

        if (this._userTemplatesRef) {
            const newUid = this._afs.createId();

            // Most of the required data is auto generated. User must supply a title, at least
            templateData.uid = newUid;
            templateData.createdDate = templateData.updatedDate = new Date().toJSON();
            templateData.ownerUid = templateData.creatorUid = this._user.uid;
            templateData.ownerName = templateData.creatorName = this._user.firstName + ' ' + this._user.lastName;

            // ensure that date fields are translated to their json string
            templateData.fields.forEach((field) => {
                if (field.type === 'date' && field.value && field.value.toJSON) {
                    field.value = field.value.toJSON();
                }
            });

			return this._afs.collection(this._templateString).doc(newUid).set(templateData).then(res => {
                return new Promise<ThoughtTemplate>((resolve) => {
                    resolve(templateData);
                })
            });
        }
        // TODO: return error if no user or no title
    }

    public update (templateData: ThoughtTemplate): Promise<ThoughtTemplate> {
        const templateId = templateData.uid;

        if (!templateData) {
            // TODO: if I can figure out how to get thought uid from thought data, maybe try that
            return;
        }

        templateData.updatedDate = new Date().toJSON();

        return this._afs.doc(this._templateString + templateId).set(templateData).then(res => {
            return new Promise<ThoughtTemplate>( (resolve, reject) => {
                resolve(templateData);
            })
        });
    }

	public delete (templateData: ThoughtTemplate): PromiseLike<any> {
		return this._afs.doc(this._templateString + templateData.uid).delete();
    }

    public applyTemplateToThought(template: ThoughtTemplate, thought: Thought): void {
        if (template.title && ! thought.title) {
            thought.title = template.title;
        }

        if (template.fields && template.fields.length > 0) {
            thought.fields = thought.fields || [];

            template.fields.forEach(field => {
                // ensure that dates loaded as strings are converted to JS date objects
                if (field.type === 'date' && field.value && typeof field.value == 'string') {
                    field.value = new Date(field.value);
                }

                thought.fields.push(field);
            });
        }

        if ( template.tags && template.tags.length > 0 ) {
            let thoughtTags = [];

            if (thought.tags && thought.tags.length > 0) {
                thoughtTags = [...thought.tags]
            }

            template.tags.forEach(templateTag => {
                if ( thoughtTags.every(tag => tag !== templateTag) ) {
                    thoughtTags.push(templateTag);
                }
            });

            // ensure that thought tags array exists, and is always a new array, so it triggers onChanges
            thought.tags = thoughtTags;
        }
    }
}
