import { Injectable } from '@angular/core';
import { UserService, User } from '../../app-core/user.service';
import { Thought } from '../../thought-module/thought.service';

interface SomethingWithThen {
    then?(res): any;
}

type CreationFunction = () => SomethingWithThen;
type CreationCallback = (userIds: string[], dataId: string) => SomethingWithThen[];
type MappingFunction = (id: string) => SomethingWithThen;

@Injectable()
export class SharingService {
  private _user: User;

  constructor( private _userService: UserService ) { 
    this._init();
  }

  private _init() {
    this._user = this._userService.get();
  }

  composeCreationPromiseWithShares (data: any, creationFunction: CreationFunction, callback: CreationCallback) {
      return creationFunction().then((res) => {
        let sharePromises;

        if (data.sharedWith) {
            sharePromises = callback(Object.keys(data.sharedWith), res.getKey());
        }
        else {
            sharePromises = Promise.resolve(null);
        }
        return Promise.all(sharePromises);
    });
  }

  composePromiseWithShares (data: any, mappingFunction: MappingFunction) {
        let composedPromise;

        if (data.sharedWith) {
            let thoughtSharers,
                thoughtPromises;

            thoughtSharers = [data.ownerUid, ...Object.keys(data.sharedWith)];
            thoughtPromises = thoughtSharers.map(mappingFunction);

            composedPromise = Promise.all(thoughtPromises);
        }
        else {
            composedPromise = mappingFunction(this._user.uid);
        }

        return composedPromise;
    };

    // TODO: Delete? Looks like I'm not using isShared in the thought anyway, and I'm trying to reuse shares for collections. Looks outdated.
    isShared (thought: Thought) {
        return thought && typeof thought.sharedWith === 'object' && Object.keys(thought.sharedWith).length > 0;
    }
}
