import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-short-text',
  templateUrl: './short-text.component.html',
  styleUrls: ['./short-text.component.scss']
})
export class ShortTextComponent implements OnInit {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;

  @Output() fieldUpdated = new EventEmitter<ThoughtField>();

  constructor() { }

  ngOnInit() {
  }

}
