import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';

import { UserService } from '../../app-core/user.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  // TODO: create a user interface that can be imported from user service for type script
  public user = {
    firstName: <string>null,
    lastName: <string>null,
    email: <string>null,
    uid: <string>null,
    createdDate: null,
    updatedDate: null,
    role: <string>null
  };
  public password: string;
  public passwordCopy: string;
  public acceptedTerms = false;
  public error: String;
  public googleError: string;
  public facebookError: string;
  public openPanel= 'googleSignUp';

  private _thisModal: NgbModalRef;

  // TODO: move logic out of constructor for testing
  constructor (
    private _modalService: NgbModal,
    private _afAuth: AngularFireAuth,
    private _afs: AngularFirestore,
    private _router: Router,
    private _userService: UserService) {}

  ngOnInit() {
    let loggedIn = false;

		// TODO: make this a special route guard?
    loggedIn = this._userService.isLoggedIn();

    if (loggedIn && !this._userService.isSignedUp()) {
      this._router.navigateByUrl('accept-terms');
    }
    else if (loggedIn) {
      this._router.navigateByUrl('dashboard');
    }
    else {
      this._afAuth.auth.getRedirectResult().then(
        userCredential => {
          console.log(userCredential);
        },
        (error: any) => {
          if (error && error.credential && error.credential.providerId === 'facebook.com') {
            this.facebookError = error.message;
            this.openPanel = 'fbSignUp';
          }
          else {
            this.googleError = error.message;
          }
        }
      );
    }
  }

  public open (content) {
    this._thisModal = this._modalService.open(content);
    this._thisModal.result.then( (result) => {}, (reason) => {});
  }

  loginGoogle() {
    this._afAuth.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider()).then(
      () => {},
      (error) => {
        this.googleError = "Could not sign up with Google. " + error.message;
      }
    );
  }
  loginFacebook() {
    this._afAuth.auth.signInWithRedirect(new firebase.auth.FacebookAuthProvider()).then(
      () => {},
      (error) => {
        this.googleError = "Could not sign up with Facebook. " + error.message;
      }
    );
  }

  signup() {

      // TODO: verify that this is not a duplicate signUp (create a rule in firebase + return an error)
      let newUser;

      if ( !this.user.firstName || !this.user.lastName || !this.user.email || !this.password || !this.passwordCopy) {
        this.error = 'Please fill out all the fields.';
        return;
      }

      if (this.password !== this.passwordCopy ) {
        this.error = 'Passwords do not match.';
        return;
      }

      if ( !this.acceptedTerms) {
        this.error = 'Please accept the terms and conditions.';
        return;
      }

      this._afAuth.auth.createUserWithEmailAndPassword(this.user.email, this.password).catch( (error) => {
        this.error = 'Account could not be created. ' + error.message;
        return;
      }).then( response => {
        if(!response) {
          console.error('Could not find user!')
          return;
        }
        newUser = this._afs.doc('/users/' + response.user.uid);

        this.user.createdDate = this.user.updatedDate = new Date().toJSON();
        this.user.role = 'user';
        this.user.uid = response.user.uid;

        return newUser.set(this.user);
      }).then(() => {
        this._router.navigateByUrl('dashboard');
      });
  }
}
