<i class="field-type-icon fa fa-align-left" aria-hidden="true" *ngIf="field.type === 'long text'"></i>
<i class="field-type-icon fa fa-font" aria-hidden="true" *ngIf="field.type === 'short text'"></i>
<i class="field-type-icon fa fa-picture-o" aria-hidden="true" *ngIf="field.type === 'image'"></i>
<i class="field-type-icon fa fa-check-square-o" aria-hidden="true" *ngIf="field.type === 'checkbox'"></i>
<i class="field-type-icon fa fa-calendar" aria-hidden="true" *ngIf="field.type === 'date'"></i>
<i class="field-type-icon fa fa-hashtag" *ngIf="field.type === 'number'"></i>

<!-- TODO: remove editing capabilities from type altogether? -->
<!-- <select *ngIf="isEditing" [(ngModel)]="field.type" class="form-control" (change)="fieldUpdated.emit(field)">
  <option *ngFor="let fieldType of fieldTypes; let i = index">{{fieldType}}<option>
</select> -->