import { Injectable } from '@angular/core';
import { Observable, forkJoin, combineLatest, of } from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators'

// TODO: figure out what the heck gets exported
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import * as firebase from 'firebase';

import { UtilityService } from '../app-core/utility.service';
import { UserService, User } from '../app-core/user.service';

export interface Network {
  uid: string;
  name: string;
  createdDate: string;
  updatedDate: string;
  creatorUid: string;
  creatorName: string;
  admin: {
      uid: string,
      name: string
  };
  adminInvitee: string;
  members?: {
    [index: string]: string;
  };
  invitees?: {
    [index: string]: boolean;
  };
}

@Injectable()
export class NetworkService {

    private _networkObservables: {[index: string]: Observable<Network>} = {};
    private _currentUserObservable: Observable<User>;
    private _nonUserInviteObservable: Observable<any>;
    private _networkString: string = '/networks/';
    private _inviteString: string;
    private _byMemberString: string;

    private _user: User;

    constructor(
        private _afs: AngularFirestore,
        private _userService: UserService,
        private _utilityService: UtilityService) {

        this._init();
    }

    private _init() {
        this._currentUserObservable = this._userService.getObservable();
        this._currentUserObservable.subscribe(user => this._user = user);
 
        this._inviteString = '/invites/';
    }

    private getUserInfo (userId: string): Observable<any> {
        return this._afs.doc('/users/' + userId)
            .valueChanges()
            .pipe(take(1));
    }

    private createNetworkInvite (userEmail: string, networkKey: string) {
        let encodedEmail = this._utilityService.encodeEmailKey(userEmail);

        this._afs.doc(this._inviteString + encodedEmail + '/' + networkKey).set(true);
    }

    private removeNetworkInvite (userEmail: string, networkKey: string) {

        let encodedEmail: string;

        if (!userEmail || !networkKey) {
            return;
        }

        encodedEmail = this._utilityService.encodeEmailKey(userEmail);
        this._afs.doc(this._inviteString + encodedEmail + '/' + networkKey).delete();
    }

    private removeUserNetwork (userId: string, networkKey: string): void {
        if (!userId || !networkKey ) {
            return;
        }

        // TODO: delete? does nothing right now, since users are removed by the admin directly in the data
        return;
    }

    // PUBLIC FUNCTIONS

    public create (networkData: Network): any {

      let invites: string[] = [],
        newUid = this._afs.createId();

      networkData.uid = newUid;
      networkData.createdDate = networkData.updatedDate = new Date().toJSON();
      networkData.creatorUid = this._user.uid;
      networkData.creatorName = this._user.firstName + ' ' + this._user.lastName;

      // TODO: Dedup members in network, check for valid email addresses, etc
      // TODO: require valid admin email address, and any member addresses are valid

      if (networkData.invitees) {
        invites = Object.keys(networkData.invitees);
        this._utilityService.transformKeys(networkData.invitees, this._utilityService.encodeEmailKey);
      }

      if (networkData.adminInvitee) {
          invites.push(networkData.adminInvitee);
      }

      return this._afs.collection(this._networkString).doc(newUid).set(networkData)
        .then(res => {
            // TODO: DBFIX figure out how to persist + pass on key

            invites.forEach(invitee => {
                return this.createNetworkInvite(invitee, newUid);
            });

            return;
        });

        // TODO: figure out how to return when EVERYTHING is complete, not just the saving of the network
    }

    public getOne = (networkId: string): Observable<Network> => {
        let networkRef: Observable<Network>;

        // TODO: return if user is not admin, or does not have network ID in their networks / networkInvites

        if (!this._networkObservables[networkId]) {
            this._networkObservables[networkId] = this._afs.doc<Network>(this._networkString + networkId)
                .valueChanges()
                .pipe(
                    map(network => {
                    // Firebase doesn't allow keys with '.', must unencode email keys
                    if (network.invitees) {
                        this._utilityService.transformKeys(network.invitees, this._utilityService.decodeEmailKey);
                    }
                    return network;
                }));
        }

        return this._networkObservables[networkId];
    }

    public getNetworks (networkIds: any): Observable<Network[]> {
        let networkObservables: Observable<Network>[];

        // don't try to fetch if there are no IDs
        if (!networkIds) {
            return of([]);
        }
        // if firebase index, pass transform to array
        if (!Array.isArray(networkIds)) {
            delete networkIds.uid;
            delete networkIds.$exists;
            delete networkIds.$value;

            networkIds = Object.keys(networkIds);
        }

        if (networkIds.length > 0) {
            networkObservables = networkIds.map(networkId => this.getOne(networkId));
            return combineLatest(networkObservables);
        }

        return of([]);
    }

    public transferNonUserInvites (userEmail: string, userId: string): void {
        let thisUserInviteString;

        if (!this._nonUserInviteObservable) {
            let encodedEmail = this._utilityService.encodeEmailKey(userEmail);
            thisUserInviteString = this._inviteString + encodedEmail;

            this._nonUserInviteObservable = this._afs.doc(thisUserInviteString).valueChanges();
        }

        this._nonUserInviteObservable
            .pipe(take(1))
            .subscribe(networks => {
                let inviteNetworkIds: string[];

                inviteNetworkIds.forEach( networkId => {
                    this._afs.doc('/users/' + userId + '/networkInvites/' + networkId).set(true);
                });

                this._afs.doc(thisUserInviteString).delete();
            });
    }

    // get user's network, updates when there's a change to the user object
    public getUserNetworks () {
        // TODO: Store the flat map as part of the part of the observable? Unless there's a reason why I need the ID list alone
    
        return this._afs.collection<Network>(this._networkString, ref => ref.where('members.' + this._user.uid, '==', true))
            // We don't want to generate multiple on-going subscriptions to networks
            // Just want network data for the latest invites, so take(1)
            .valueChanges()
            .pipe(
                mergeMap((networks) => this.getNetworks(networks).pipe(take(1)))
            )
    }

    // TODO: Delete?
    // note, this only updates when the USER's list of network updates, not when one of the networks themselves updates...
    // ...or when one of the members of the network updates their data
    public getUserNetworksAndMembers (): Observable<Network[]> {
        return this.getUserNetworks()
    }

    public getNetworksFromInvites() {
        // returns a subscription to user's invites, that are then mapped to the network data itself
        return this._afs.doc(this._inviteString + this._utilityService.encodeEmailKey(this._user.email))
            // We don't want to generate multiple on-going subscriptions to networks
            // Just want network data for the latest invites, so take(1)
            .valueChanges()
            .pipe(
                mergeMap((invites) => this.getNetworks(invites).pipe(take(1)))
            )
    }

    public clearInvite (network: Network) {
        let isAdmin,
            clearUserInvite,
            clearNetworkInvite,
            clearAdminInvite,
            clearActions = [],
            encodedEmail = this._utilityService.encodeEmailKey(this._user.email);

        if (!network) { return; }

        isAdmin = network.adminInvitee === this._user.email;

        // if this is a pre-existing user, need userID to delete invite
        clearUserInvite = this._afs.doc(this._inviteString + encodedEmail + '/' + network.uid).delete();

        clearActions.push(clearUserInvite);

        if (isAdmin) {
            clearAdminInvite = this._afs.doc(this._networkString + network.uid + '/adminInvitee').delete();
            clearActions.push(clearAdminInvite);
        }

        if (network.invitees[this._user.email]) {
            clearNetworkInvite = this._afs.doc(this._networkString + network.uid + '/invitees/' + encodedEmail).delete();
            clearActions.push(clearNetworkInvite);
        }

        return forkJoin(clearActions);
    }

    // alias, since all that needs to be done to handle a rejection is to clear the invites
    public rejectInvite = this.clearInvite;

    public acceptInvite(network: Network) {
        let isAdmin,
            uid,
            name,
            clearInviteUpdate,
            memberUpdate,
            userUpdate,
            adminUpdate,
            networkUpdate;

        uid = this._user.uid;
        isAdmin = network.adminInvitee === this._user.email;
        name = this._user.firstName + ' ' + this._user.lastName;

        if (isAdmin) {
            let adminInfo = {
                uid: uid,
                name: name
            };
            networkUpdate = this._afs.doc(this._networkString + network.uid + '/admin').set(adminInfo);
        }
        if (network.invitees[this._user.email]) {
            networkUpdate = this._afs.doc(this._networkString + network.uid + '/members/' + uid).set(name);
        }

        return networkUpdate
            .then(updateResponse => clearInviteUpdate = this.clearInvite(network));
    }

    public edit (newNetwork: Network, oldNetwork: Network): Promise<void> {

        let inviteChanges,
            memberChanges,
            adminChanges: any = {};

        // TODO: uncomment code when done test
        // if ( (!this._user.privileges || !this._user.privileges.admin) && this._user.email !== oldNetwork.admin.email) {
        //     return;
        // }

        // INVITE CHANGES
        if (newNetwork.invitees !== oldNetwork.invitees) {
            inviteChanges = this._utilityService.getNewAndRemovedKeys(newNetwork.invitees, oldNetwork.invitees);
        }
        // send invites to new users
        // TODO: don't send out invite to new member if member is already the admin
        inviteChanges.new.forEach(invitee => {
            this.createNetworkInvite(invitee, newNetwork.uid);
        });
        // remove invites from removed users who haven't accepted / rejected yet
        inviteChanges.removed.forEach(invitedMember => {
            if (invitedMember !== newNetwork.adminInvitee) {
                this.removeNetworkInvite(invitedMember, newNetwork.uid);
            }
        });

        // MEMBER CHANGES
        // In practice is only going to be removing members, because you can't add members directly... only invites
        if (newNetwork.members !== oldNetwork.members) {
            memberChanges = this._utilityService.getNewAndRemovedKeys(newNetwork.members, oldNetwork.members);
        }
        // remove network data from members who have joined the network
        memberChanges.removed.forEach(member => {
            if (member !== newNetwork.admin) {
                this.removeUserNetwork(member, newNetwork.uid);
            }
        });

        // ADMIN CHANGES
        if (newNetwork.adminInvitee && newNetwork.adminInvitee !== oldNetwork.adminInvitee) {

            // TODO don't send new invite if admin is already a member
            if (!newNetwork.invitees[newNetwork.adminInvitee]) {
                this.createNetworkInvite(adminChanges.new, newNetwork.uid);
            }

            if (oldNetwork.adminInvitee) {
                this.removeNetworkInvite(oldNetwork.adminInvitee, newNetwork.uid);
            }
        }

        if (!newNetwork.admin && oldNetwork.admin && !newNetwork.members[oldNetwork.admin.uid]) {
            this.removeUserNetwork(oldNetwork.admin.uid, newNetwork.uid);
        }

        // ensure that invitee email keys are encoded because Firebase does not allow keys with '.'
        this._utilityService.transformKeys(newNetwork.invitees, this._utilityService.encodeEmailKey);

        // ensure that admin / admin is not undefined, or firebase will return yet another error that gets swallowed by angular
        newNetwork.admin = newNetwork.admin || null;
        newNetwork.adminInvitee = newNetwork.adminInvitee || null;

        // TODO: figure out how to tell the UI when this is all done, not just this piece
        return this._afs.doc('this._networkStringbyNetworkId/' + newNetwork.uid).update({
            name: newNetwork.name,
            admin: newNetwork.admin,
            adminInvitee: newNetwork.adminInvitee,
            members: newNetwork.members,
            invitees: newNetwork.invitees,
            updatedDate: new Date().toJSON()
        });
    }

    public delete (networkToDelete: Network): Observable<any> {
        let removedMembers: string[] = [],
            removedInvitees: string[] = [];

        if (!this._user.privileges.admin || this._user.uid === networkToDelete.admin.uid) {
            return;
        }

        // TODO: any deduping (for instance, if admin is member as well)

        // MEMBER Changes (treat admin as member)
        // Note: Network to delete should be the original, cached version (should not include unsaved edits)
        removedMembers = Object.keys(networkToDelete.members);
        if (networkToDelete.admin) {
            removedMembers.push(networkToDelete.admin.uid);
        }
        // remove network data from members who have joined the network
        removedMembers.forEach(member => {
            this.removeUserNetwork(member, networkToDelete.uid);
        });

        // INVITE CHANGES (treat adminInvitee as invite)
        removedInvitees = Object.keys(networkToDelete.invitees);
        if (networkToDelete.adminInvitee) {
            removedInvitees.push(networkToDelete.adminInvitee);
        }

        // remove invites from removed users who haven't accepted / rejected yet
        removedInvitees.forEach(invitedMember => {
            this.removeNetworkInvite(invitedMember, networkToDelete.uid);
        });

        return this._afs.doc('this._networkStringbyNetworkId/' + networkToDelete.uid).valueChanges();
    }

}

// TODO: Fix network related errors