import {Directive, ElementRef, Input, OnInit} from '@angular/core';

declare var google: any;
declare var googleLoaded: any;

@Directive({
  selector: '[GoogleChart]'
})
export class GoogleChartDirective implements OnInit {
  public _element: any;

  @Input() chartType: string;
  @Input() chartOptions: Object;
  @Input() chartData: any [];
  @Input() chartCols: any[];
  @Input() selectionCallback: (selectedIndex: any) => any;

  constructor(public element: ElementRef) {
    this._element = this.element.nativeElement;
  }

  ngOnInit() {
    google.charts.load('current', {'packages': ['corechart']});
    google.charts.setOnLoadCallback(this._drawGraph);
  }

  private _drawGraph = () => {
    let dataTable,
          settings,
          wrapper;

      if (!this.chartData || !this.chartData[0] || !this.chartData[0].forEach) {
        return;
      }

      if (this.chartCols) {
        dataTable = new google.visualization.DataTable();

        this.chartCols.forEach(col => { dataTable.addColumn(col); });

        // addRows appears to LIFO, reverse the data to preserve order
        this.chartData.reverse();
        dataTable.addRows(this.chartData);
      }
      else if (this.chartData && this.chartData.length > 0){
        dataTable = this.chartData;
      }

      settings = {
        chartType: this.chartType,
        dataTable: dataTable,
        options: this.chartOptions || {},
        containerId: this._element.id
      };

      wrapper = new google.visualization.ChartWrapper(settings);

      if (this.selectionCallback) {

        google.visualization.events.addListener(wrapper, 'select', () => {
          let chart = wrapper.getChart(),
              selectedItem = chart.getSelection()[0];

          if (selectedItem) {
            let selectionIndex = selectedItem.row;

            this.selectionCallback(selectionIndex);
          }
        });
      }

      wrapper.draw();
  }
}
