<div class="login-page container">
    <ngb-accordion [closeOthers]="true" [activeIds]="openPanel">
      <ngb-panel id="googleSignUp" title="Sign Up With Google">
        <ng-template ngbPanelContent>
            <div class="alert alert-danger" *ngIf="googleError">{{googleError}}</div>
            <button class="btn btn-secondary" (click)="loginGoogle()">
                <i class="fa fa-google" aria-hidden="true"></i>
                Google Sign Up
            </button>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="fbSignUp" title="Sign Up With Facebook">
        <ng-template ngbPanelContent>
            <div class="alert alert-danger" *ngIf="facebookError">{{facebookError}}</div>
            <button class="btn btn-secondary" (click)="loginFacebook()">
                <i class="fa fa-facebook-official" aria-hidden="true"></i>
                Facebook Sign Up
            </button>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="emailSignUp" title="Sign Up With Email">
        <ng-template ngbPanelContent>
            <div class="alert alert-danger" *ngIf="error">{{error}}</div>
            <div class="form-group">
                <label>First Name</label>
                <input type="text" [(ngModel)]="user.firstName" class="form-control">
            </div>
            <div class="form-group">
                <label>Last Name</label>
                <input type="text" [(ngModel)]="user.lastName" class="form-control">
            </div>
            <div class="form-group">
                <label>Email</label>
                <input type="text" [(ngModel)]="user.email" class="form-control">
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password" [(ngModel)]="password" class="form-control">
            </div>
            <div class="form-group">
                <label>Retype Password</label>
                <input type="password" [(ngModel)]="passwordCopy" class="form-control">
            </div>
            <div class="form-check">
                <label class="form-check-label">
                    <input type="checkbox" [(ngModel)]="acceptedTerms" class="form-check-input"> I agree to the ReCog <a href="javascript:void(0)" (click)="open(toc)">terms and conditions</a>
                </label>
            </div>
            <!-- For Everyone -->
            <button (click)="signup()" class="btn btn-primary">Sign Up!</button>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
</div>
<p class="container login-page login-link">
    Already a member? <a routerLink="/login">Log in</a>
</p>

<ng-template #toc let-d="dismiss">
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <app-terms></app-terms>
  </div>
</ng-template>