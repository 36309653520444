import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-long-text',
  templateUrl: './long-text.component.html',
  styleUrls: ['./long-text.component.scss']
})
export class LongTextComponent {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;
  @Input() index: number;
  @Input() ready: boolean;

  @Output() fieldUpdated = new EventEmitter<ThoughtField>();

  public id: number = Date.now();
  public Editor = ClassicEditor;

  constructor() { }

  public updateRichTextField(data) {
    this.field.value = data.editor.getData();
    this.fieldUpdated.emit(this.field);
  }

}
