import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NetworkRoutingModule, routableComponents} from './network-routing.module';
import { NetworkService } from './network.service';

import { SharingComponent } from './sharing/sharing.component';
 
@NgModule({
  imports:      [
        CommonModule,
        NgbModule,
        FormsModule,
        NetworkRoutingModule
  ],
  declarations: [ routableComponents, SharingComponent ],
  exports:      [ SharingComponent ],
  providers:    [ NetworkService ]
})
export class NetworkModule { }

