import { Component, OnInit } from '@angular/core';

declare const fbq: any;

@Component({
  selector: 'app-wait-list-thank-you',
  templateUrl: './wait-list-thank-you.component.html',
  styleUrls: ['./wait-list-thank-you.component.scss']
})
export class WaitListThankYouComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    fbq('track', 'Lead');
  }

}
