import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;

  @Output() fieldUpdated = new EventEmitter<ThoughtField>();

  constructor() { }

  ngOnInit() {
  }

}
