import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';

import { UserService } from '../../app-core/user.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email: string = '';
	public password: string = '';
	public error: string;

  constructor (
		private _afAuth: AngularFireAuth,
		private router: Router,
		private _userService: UserService
	) {}

  ngOnInit() {
    let loggedIn = false;

		// TODO: make this a special route guard?
    loggedIn = this._userService.isLoggedIn();

    if (loggedIn && !this._userService.isSignedUp()) {
      this.router.navigateByUrl('accept-terms');
    }
    else if (loggedIn) {
      this.router.navigateByUrl('dashboard');
    }

    this._afAuth.auth.getRedirectResult().then(
      response => {},
      error => {
        this.error = error.message;
      }
    );
  }

	login() {
		this._afAuth.auth.signInWithEmailAndPassword(this.email, this.password).catch( (error: any) => {
			// Handle Errors here.
			this.error = "Could not log in. " + error.message;
		});
	}
	loginGoogle() {
    this._afAuth.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider()).then(
      () => {},
      (error) => {
        this.error = "Could not log in with Google. " + error.message;
      }
    );
  }
  loginFacebook() {
    this._afAuth.auth.signInWithRedirect(new firebase.auth.FacebookAuthProvider()).then(
      () => {},
      (error) => {
        this.error = "Could not log in with Facebook. " + error.message;
      }
    );
  }
}
