import { Component, OnInit, OnChanges, EventEmitter, Input, Output, HostListener } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators'

import { ThoughtService, ThoughtField, Thought } from '../thought.service';
import { ThoughtTemplateService, ThoughtTemplate } from '../thought-template/thought-template.service';
import { UtilityService } from '../../app-core/utility.service';

@Component({
  selector: 'app-thought-form',
  templateUrl: './thought-form.component.html',
  styleUrls: ['./thought-form.component.scss']
})

export class ThoughtFormComponent implements OnInit, OnChanges {

// TODO:  make sure that all caching / image scrubing logic works with the new display fields

    @Input() thought: Thought;
    @Input() isTemplateForm: boolean;

    @Output() thoughtSaved = new EventEmitter<Thought>();
    @Output() thoughtCancelled = new EventEmitter<Thought>();
    @Output() thoughtDeleted = new EventEmitter<Thought>();

    @Output() templateSaved = new EventEmitter<ThoughtTemplate>();
    @Output() templateCancelled = new EventEmitter<ThoughtTemplate>();
    @Output() templateDeleted = new EventEmitter<ThoughtTemplate>();

    @Output() sharesUpdated = new EventEmitter<boolean>();
    @Output() modalIsOpen = new EventEmitter<boolean>();

    @HostListener('window:keydown', ['$event']) keyboardInput (event: KeyboardEvent) {

        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            if (this.isTemplateForm) {
                this.saveTemplate();
            }
            else {
                this.saveThought();
            }
        }
    }

    private _deletedFields: Array<ThoughtField> = [];
    private _deletedTags: string[] = [];
    private _cachedThought: Thought;
    private _isAlive = true;
    private _thisModal: NgbModalRef;

    public newTag: string;
    // TODO: move this to ThoughtService
    public fieldTypes: string[] = [
        'short text',
        'long text',
        'number',
        'date',
        'image',
        'checkbox'
    ];
    public thoughtTemplates: ThoughtTemplate[] = null;
    public saveTemplateSuccess: boolean;
    public templateToSaveAs: any;
    public selectedTemplates: {[index: string]: boolean} = {};
    public modalOptions: any = {size: 'lg'};

    constructor (
        private _modalService: NgbModal,
        private _thoughtService: ThoughtService,
        private _thoughtTemplateService: ThoughtTemplateService,
        private _utilityService: UtilityService) {}

    ngOnInit() {
        this.thought = this.thought || <Thought> {
            title: '',
            fields: [],
            tags: []
        };

        this.isTemplateForm = this.isTemplateForm || false;

        this._thoughtTemplateService.getAll().subscribe(templates => {
            this.thoughtTemplates = templates;
        });
    }

    ngOnChanges(changes) {
        if (changes.thought) {
            this.thought.fields = this.thought.fields || [];
            this.thought.fields.forEach((field) => {
                if (field.type === 'date' && field.value && !field.value.getFullYear) {
                    field.value = new Date (field.value);
                }
            });
            this._cachedThought = this._utilityService.deepCopy(this.thought);
            this.thought.sharedWith = this.thought.sharedWith || {};
            this.thought.oldSharedWith = this._utilityService.deepCopy(this.thought.sharedWith);
        }
    }

    public addField (type: string) {
        // TODO: error messaging
        if (!this.thought) {
            return;
        }

        // since firebase deletes parent when all children are gone... ensure this parent exists
        if (!this.thought.fields) {
            this.thought.fields = [];
        }
        this.thought.fields.push({
            label: '',
            type: type,
            value: null
        });
    };

    public getEditorId (index: number): string {
        return 'long-text-' + index;
    }

    public deleteField (field) {
        this._deletedFields.push(field);
    }

    // There are cases where the tag component may change the ref, so keep thought updates
    public updateTags (tags) {
        this.thought.tags = tags;
    }

    public cacheDeletedTag(tag) {
        this._deletedTags.push(tag);
    }

    // TODO: remove when we use display fields
    public updateRichTextField(data, field) {
        field.value = data;
    }

    public open (content, options) {
        let onClose = () => {
            this.modalIsOpen.emit(false);
            if (document.querySelector('body > .modal')) {
                document.body.classList.add('modal-open');
            }
        };

        this._thisModal = this._modalService.open(content, options);
        this._thisModal.result.then(onClose, onClose);

        this.modalIsOpen.emit(true);
    }

    public saveThought (cancelAlso = false) {
        // assumes images from deleted fields aren't used in other thoughts
        // TODO: check that there are no references to the image (maybe call a server process to do it behind the scenes)
        this._thoughtService.scrubImagesFromThoughtFields(this._deletedFields, this.thought.fields);

        this.thoughtSaved.emit(this.thought);

        if (cancelAlso) {
            this.thoughtCancelled.emit(this.thought);
        }
    }

    public cancelEditThought () {
        let allPossibleFields: Array<ThoughtField> = [];

        if (this.thought.fields && this.thought.fields.concat) {
            allPossibleFields = this.thought.fields.concat(this._deletedFields);
        }

        // TODO: move logic around thoughtID/navigation/modal to parent component
        if (this.thought.uid) {
            this._thoughtService.scrubImagesFromThoughtFields(allPossibleFields, this._cachedThought.fields);
        }
        else {
            // delete images just in case (assumes images used in new thoughts weren't the same ones somewhere else)
            this._thoughtService.scrubImagesFromThoughtFields(allPossibleFields, null);
        }
        this.thoughtCancelled.emit(this.thought);
    }

    // TODO: remove when we use display fields
    public upload (event, object) {
        const file = event.srcElement.files[0];

        this._thoughtService.uploadUserImage(file).then((snapshot) => {
            object.value = snapshot.downloadURL;
        });
    }

    public deleteThought () {
        // Treat the new field as a deleted field, make sure to errase all images (assumes they're not in use by other thoughts)
        this._thoughtService.scrubImagesFromThoughtFields(this._deletedFields, null);

        this.thoughtDeleted.emit(this.thought);
    }

    private convertThoughtToTemplate (): ThoughtTemplate {
        let thoughtTemplate: ThoughtTemplate = <ThoughtTemplate> {};

        Object.assign(thoughtTemplate, this.thought);
        thoughtTemplate.uid = this.thought.uid;

        return thoughtTemplate;
    }

    public applyTemplates () {
        this.thoughtTemplates.forEach(template => {
            if (this.selectedTemplates[template.templateName]) {
                this._thoughtTemplateService.applyTemplateToThought(template, this.thought);
            }
        });
        this._thisModal.close();
    }

    public saveThoughtAsTemplate() {
        let thoughtTemplate = this.convertThoughtToTemplate(),
            promise,
            nameCache: string;

        if ( !this.templateToSaveAs ) {
            return;
        }

        // no existing template selected from typeahead
        if (typeof this.templateToSaveAs === 'string') {
            thoughtTemplate.templateName = this.templateToSaveAs;
            delete thoughtTemplate.uid;
            nameCache = this.templateToSaveAs;

            promise = this._thoughtTemplateService.create(thoughtTemplate);
        }
        // existing template selected from typeahead
        else if (this.templateToSaveAs.uid) {
            thoughtTemplate.templateName = this.templateToSaveAs.templateName;
            thoughtTemplate.uid = this.templateToSaveAs.uid;

            promise = this._thoughtTemplateService.update(thoughtTemplate);
        }


        this.templateSaved.emit(thoughtTemplate);

        promise.then(response => {
            // show success message
            this.saveTemplateSuccess = true;

            // ensure that the newly created templates are the default 'save as' item
            if (nameCache) {
                this.templateToSaveAs = {
                    templateName: nameCache,
                    uid: response.getKey()
                };
            }

            // clear success message, allow thought control buttons to reappear
            setTimeout(
                () => {
                    this.saveTemplateSuccess = false;
                },
                3000
            );
        });
    }

    public saveTemplate() {
        let thoughtTemplate = this.convertThoughtToTemplate(),
            promise;

        // Don't save if no template name
        if (!thoughtTemplate.templateName) {
            return;
        }

        // new template
        if (!thoughtTemplate.uid) {
            promise = this._thoughtTemplateService.create(thoughtTemplate)
        }
        // existing template
        else {
            promise = this._thoughtTemplateService.update(thoughtTemplate)
        }

        promise.then(response => {
            this.templateSaved.emit(response);
        });
    }

    public cancelEditTemplate() {
        this.templateCancelled.emit();
    }
    public deleteTemplate() {
        let thoughtTemplate = this.convertThoughtToTemplate();

        this._thoughtTemplateService.delete(thoughtTemplate);

        this.templateDeleted.emit(thoughtTemplate);
    }

    public search = (text$: Observable<string>) => {
        return text$
            .pipe(
                debounceTime(200),
                map(term => {
                    return term === '' ? [] : this.thoughtTemplates.filter(v => new RegExp(term, 'gi').test(v.templateName)).slice(0, 10);
                })
            );
    }
    public formatter = (x: ThoughtTemplate) => x.templateName;

    // TODO: actually run the thought or template save / delete calls to services here, just use outgoing events for redirection afterward
}
