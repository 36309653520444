<h2 *ngIf="!network" class="header-major">
    This Network Does Not Exist    
</h2>
<h2 *ngIf="network" class="header-major">
    {{network.name}}
</h2>
<div  *ngIf="user.privileges?.admin || user.uid === network?.admin?.uid" class="header-actions">
    <a routerLink="edit" class="btn btn-secondary">
      <i class="fa fa-pencil" aria-hidden="true"></i>
    </a>
</div>
<div class="content-box">
  <h3 class="header-minor">About</h3>
  <dl class="network-info">
    <dt>Admin</dt>
    <dd>
      <span *ngIf="network?.admin">{{network.admin.name}}</span>
      <span *ngIf="!network?.admin">Awaiting admin</span>
    </dd>
    <dt>Created On</dt>
    <dd>{{network.createdDate | date:'short'}}</dd>
    <dt>Last Updated</dt>
    <dd>{{network.updatedDate | date:'short'}}</dd>
  </dl>
</div>

<div class="content-box">
  <h3 class="header-minor">Members</h3>
  <p>Members of a network can share thoughts and copy collections from each other.</p>
  <ul class="network-member-list list-unstyled">
    <li class="network-member-item" *ngFor="let member of members">
      {{network.members[member]}}
    </li>
  </ul>
</div>
