import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { SharedModule } from '../shared-module/shared.module';
import { NetworkModule } from '../network-module/network.module';

import { ThoughtRoutingModule, routableComponents} from './thought-routing.module';
import { ThoughtService } from './thought.service';

import { ShortTextComponent } from './field/short-text/short-text.component';
import { LongTextComponent } from './field/long-text/long-text.component';
import { DateComponent } from './field/date/date.component';
import { NumberComponent } from './field/number/number.component';
import { ImageComponent } from './field/image/image.component';
import { CheckboxComponent } from './field/checkbox/checkbox.component';

import { DisplayFieldLabelComponent } from './field/display-field-label/display-field-label.component';
import { DisplayFieldTypeComponent } from './field/display-field-type/display-field-type.component';
import { DisplayFieldValueComponent } from './field/display-field-value/display-field-value.component';
import { DisplayFieldsComponent } from './field/display-fields/display-fields.component';

import { ThoughtTemplateService } from './thought-template/thought-template.service';

import { ThoughtFormComponent } from './thought-form/thought-form.component';
import { ThoughtListComponent } from './thought-list/thought-list.component';

let privateComponents = [
  ShortTextComponent,
  LongTextComponent,
  DateComponent,
  NumberComponent,
  ImageComponent,
  CheckboxComponent,
];

let sharedComponents = [
  DisplayFieldLabelComponent,
  DisplayFieldTypeComponent,
  DisplayFieldValueComponent,
  DisplayFieldsComponent,
  ThoughtFormComponent,
  ThoughtListComponent
]

@NgModule({
  imports:      [
        CommonModule,
        ThoughtRoutingModule,
        SharedModule,
        NetworkModule,
        NgbModule,
        DragulaModule,
        CKEditorModule,
        FormsModule
  ],
  declarations: [ routableComponents, privateComponents, sharedComponents],
  exports:      [ sharedComponents ],
  providers:    [ ThoughtService, ThoughtTemplateService ]
})
export class ThoughtModule { }

