<div *ngIf="thought" (onKeyUp)="console.log($event)">
    
    <header class="clearfix mb-4 ">
        <div class="form-group mb-4" *ngIf="isTemplateForm">
            <input type="text" [(ngModel)]="thought.templateName" class="form-control" placeholder="Name your template">
        </div>
        <div class="content-box" *ngIf="isTemplateForm">
            <h2 class="header-minor thought-bg">Default Title</h2>
            <input type="text" [(ngModel)]="thought.title" class="form-control thought-title-field" placeholder="Thought Title">
        </div>
        <h2 class="header-major thought-bg mb-1" *ngIf="!isTemplateForm">
            <input type="text" [(ngModel)]="thought.title" class="form-control thought-title-field" placeholder="Thought Title">
        </h2>
        <div class="header-actions no-float-xs">
            <!-- TODO: Enable networks -->
            <!-- <app-sharing 
                *ngIf="!isTemplateForm"
                [shareInfo]="thought.sharedWith"
                [owner]="thought.owner?.uid"
                (updated)="sharesUpdated.emit(true);">
            </app-sharing> -->
        </div>
    </header>

    <app-display-fields
        [fields]="thought?.fields"
        (fieldDeleted)="deleteField($event)"
        isEditing="true"
        showLabels="true">
    </app-display-fields>
    
    <h2 class="header-minor thought-bg">Tags</h2>
    <div class="pb-4">
        <app-tags
            [tags]="thought.tags"
            contextClass="thought"
            (tagsUpdated)="updateTags($event)"
            (tagDeleted)="cacheDeletedTag($event)">
        </app-tags>
    </div>
    
    
    <div class="form-actions">
        <div class="container">
            <div class="positive-actions">
                <!-- ADD INFO  -->
                <div class="add-info action-item" ngbDropdown placement="top-left" container="body">
                    <button class="btn btn-primary" id="addInfoDropButton" ngbDropdownToggle>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="addInfoDropButton">
                        <button (click)="addField('long text')" class="dropdown-item">
                            <i class="add-info-icon fa fa-align-left" aria-hidden="true"></i> Rich Text
                        </button>
                        <button (click)="addField('short text')" class="dropdown-item">
                            <i class="add-info-icon fa fa-font" aria-hidden="true"></i> Short Text
                        </button>
                        <button (click)="addField('image')" class="dropdown-item">
                            <i class="add-info-icon fa fa-picture-o" aria-hidden="true"></i> Image
                        </button>
                        <button (click)="addField('checkbox')" class="dropdown-item">
                            <i class="add-info-icon fa fa-check-square-o" aria-hidden="true"></i> Checkbox
                        </button>
                        <button (click)="addField('date')" class="dropdown-item">
                            <i class="add-info-icon fa fa-calendar" aria-hidden="true"></i> Date
                        </button>
                        <button (click)="addField('number')" class="dropdown-item">
                            <i class="add-info-icon fa fa-hashtag"></i> Number
                        </button>
                    </div>
                </div>
                <button *ngIf="!isTemplateForm" class="btn btn-secondary action-item" (click)="open(thoughtTemplateModal, modalOptions)">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                </button>
                <!-- TODO: fix firebase error, "FirebaseError: Function DocumentReference.set() called with invalid data. Unsupported field value: undefined" -->
                <button *ngIf="!isTemplateForm" class="btn btn-primary action-item" (click)="saveThought(true)">Save</button>
                <button *ngIf="isTemplateForm"class="btn btn-primary action-itemt" (click)="saveTemplate()">Save Template</button>
            </div>
            
            <div *ngIf="!isTemplateForm"  class="negative-actions">
                <button class="btn btn-danger btn action-item" (click)="deleteThought()" *ngIf="thought.uid"><i class="fa fa-trash" aria-hidden="true"></i></button>
                <button class="btn btn-warning btn action-item" (click)="cancelEditThought()"><i class="fa fa-ban" aria-hidden="true"></i></button>
            </div>
            <div *ngIf="isTemplateForm"  class="negative-actions">
                <button class="btn btn-warning float-right action-item" (click)="cancelEditTemplate()">Cancel</button>
                <button class="btn btn-danger float-right action-item" (click)="deleteTemplate()" *ngIf="thought.uid">Delete Template</button>
            </div>
        </div>
    </div>
</div>

<ng-template #thoughtTemplateModal let-d="dismiss">
	<div class="modal-header thought-bg">
        <h3 class="modal-title">Thought Templates</h3>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
         <div *ngIf="!isTemplateForm && thoughtTemplates && thoughtTemplates.length">
            <h4>Apply a template</h4>
            <p>Thought templates can include thought titles, info labels and values, and tags. Thought titles will be added only if the current thought title is empty. Info will be added as a new item (even if info with the same label already exists). Tags will be added unless the thought already has them.</p>
             <ul class="list-unstyled row">
                <li *ngFor="let template of thoughtTemplates" class="form-check template-item col-md-4 col-sm-6">
                    <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="selectedTemplates[template.templateName]"> {{template.templateName}}
                    </label>
                </li>
            </ul> 
            <button  (click)="applyTemplates()" class="btn btn-thought mb-4">Apply Templates</button>
        </div> 
        <h4>Save as template</h4>
        <div *ngIf="!saveTemplateSuccFess">
            <p>Saving this thought as a template allows you to copy the title, info, and tags of this thought to other thoughts. Once you save this thought as a template, you will be able to edit the thought again as normal.</p>
            <div *ngIf="saveTemplateSuccess" class="card-block">
                <div class="alert alert-success">Your Template Was Saved</div>
            </div>
            <div class="form-inline float-left">
                <label class="mr-2">Template Name</label>
                <input id="typeahead-template" type="text" class="form-control" [(ngModel)]="templateToSaveAs" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultFormatter]="formatter" />
            </div>
            <button class="btn btn-primary float-right" (click)="saveThoughtAsTemplate()" [disabled]="!templateToSaveAs">
                Save as
                <span *ngIf="templateToSaveAs">{{templateToSaveAs?.templateName || templateToSaveAs}}</span>
                <span *ngIf="!templateToSaveAs">...</span>
            </button>
        </div>
	</div>
</ng-template>