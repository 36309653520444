<!-- TODO: make a thought-data-view that is reusable by thought basic view and template view -->

<h2 *ngIf="!thought" class="header-major thought-bg">
    This Thought Does Not Exist    
</h2>

<h2 *ngIf="thought" class="header-major thought-bg">
    {{thought.title}}
    <span
        *ngIf="shared"
        [ngClass]="{'sharer-icon': thought?.owner?.uid === user?.uid, 'sharee-icon': thought?.owner?.uid !== user?.uid}"
        class="fa fa-users ml-2"></span>
</h2>
<div class="header-actions no-float-xs">
    <small>
        <a routerLink="edit" class="btn btn-secondary mr-3"><i class="fa fa-pencil" aria-hidden="true"></i></a>
    </small>
    <small>
        <button (click)="back()" class="btn btn-secondary">Back</button>
    </small>
</div>

<div class="thought-view-list">
    <app-display-fields
        [fields]="thought?.fields"
        (fieldsUpdated)="saveThought()"></app-display-fields>
</div>
<h3 class="thought-section header-minor thought-bg">Tags</h3>
<ul class="list-inline">
    <li *ngFor="let tag of thought?.tags" class="badge badge-default mr-3 mb-3">
        {{tag}}
    </li>
</ul>