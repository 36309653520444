import { Component, OnChanges, Input } from '@angular/core';

import { ViewService, View } from '../view.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnChanges {

  @Input() view: View;
  @Input() viewData;
  @Input() linksToThought = true;

  private _gallery: any;

  constructor(private _viewService: ViewService) { }



  ngOnChanges(changes) {

    if (this.viewData) {
      this.viewData = this._viewService.flattenData(this.viewData, 'Included Fields', (item) => { item.src = item.value; item.w = 600; item.h = 400; });
    }

  }

}
