import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ThoughtService, Thought } from '../../thought-module/thought.service';
import { CollectionService, Collection } from '../../collection-module/collection.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // TODO: have dashboard cache and save current state
  // TODO: on dashboard load, load the last state first, then fetch ALL data behind the scenes to update

  public recentThoughts: Thought[];
  public recentCollections: Collection[];

  constructor(
    private _thoughtService: ThoughtService,
    private _collectionService: CollectionService,
    private _titleService: Title) {}

  ngOnInit() {
    this._titleService.setTitle('ReCog - Dashboard');
    this._thoughtService.getRecent(10).subscribe(thoughtData => {
      this.recentThoughts = thoughtData;
    });
    this._collectionService.getRecent(100).subscribe(collectionData => {
      this.recentCollections = collectionData;
    });
  }
}
