import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wait-list-check-email',
  templateUrl: './wait-list-check-email.component.html',
  styleUrls: ['./wait-list-check-email.component.scss']
})
export class WaitListCheckEmailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
