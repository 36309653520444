import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnChanges {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;

  @Output() fieldUpdated = new EventEmitter<ThoughtField>();

  public dateObj: NgbDateStruct;

  constructor(private ngbDateParserFormatter: NgbDateParserFormatter) { }

  ngOnChanges(changes){
    if (changes.field && this.field && this.field.value) {
      this.dateObj = this.setDate(this.field.value);
    }
  }

  onSelectDate(date: NgbDateStruct){
    if (date != null) {
        this.field.value = new Date(date.year, date.month - 1, date.day);   // needed for first time around due to ngModel not binding during ngOnInit call. Seems like a bug in ng2.
    }
    this.fieldUpdated.emit(this.field);
  }

  setDate(date: Date): NgbDateStruct {
      return this.ngbDateParserFormatter.parse(date.toString());
  }

}
