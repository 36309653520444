import { Inject, Injectable } from '@angular/core';

interface FirebaseObject {
    uid?: string;
    $value?: any;
    $exists? (): boolean;
}

export interface SelectReadyConstant {
    value: string;
    displayName: string;
}

// Class for common functions used by other services and controllers to keep code DRY
@Injectable()
export class UtilityService {
    constructor() {}

    public deepCopy(oldObj: any) {
        var newObj = oldObj;
        if (oldObj && typeof oldObj === 'object') {
            newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};

            for (let i in oldObj) {
                newObj[i] = this.deepCopy(oldObj[i]);
            }
        }
        return newObj;
    }
    public firebaseListFind(firebaseListObject: any, callback) {
        let object,
            objectKey;

        if (!firebaseListObject) {
            return;
        }

        object = Object.keys(firebaseListObject).find( (key) => {
            let match = callback(firebaseListObject[key]);

            if (match) {
                objectKey = key;
                return true;
            }
            return false;
        });

        return {
            item: object,
            key: objectKey
        };
    }

    public getNewAndRemovedKeys (newObj: any, oldObj: any) {
        let oldObjCopy = {},
            currentKeys = [],
            newKeys = [],
            removedKeys = [];


        // copy the old object, so we don't mutate any data
        Object.assign(oldObjCopy, oldObj);

        newKeys = Object.keys(newObj).filter(key => {
            delete oldObjCopy[key];
            return !oldObjCopy[key];
        });

        removedKeys = Object.keys(oldObjCopy);

        return {new: newKeys, removed: removedKeys};
    }

    public encodeEmailKey (email: string) {
        // return encodeURIComponent(email).replace('.', '%2E');

        return email.replace(/\./g, ',');
    }

    public decodeEmailKey (email: string) {
        // return decodeURIComponent(email.replace('%2E', '.'));

        return email.replace(/\,/g, '.');
    }

    public transformKeys (parent: any, transformFunc: (key: string) => string ) {
        const oldKeys = Object.keys(parent);

        oldKeys.forEach(oldKey => {
           const newKey = transformFunc(oldKey);

           parent[newKey] = parent[oldKey];
           delete parent[oldKey];
        });
    }
}
