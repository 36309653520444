import { Directive, Renderer2, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appFocusMe]'
})
export class FocusMeDirective implements OnInit {

  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}

  @Input() readyForFocus: boolean;

  // TODO figure out why this causes error: thoughtData = this.preSaveTasks(thoughtData); [on the field label]

  ngOnInit() {
    if (this.readyForFocus) {
      this.elementRef.nativeElement.focus();
    }
  }

}
