import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { LoginRoutingModule, routableComponents} from './login-routing.module';
import { HeaderLoggedOutComponent } from './header-logged-out/header-logged-out.component';
import { TermsComponent } from './terms/terms.component';

let privateComponents = [
  TermsComponent
];

let sharedComponents = [
  HeaderLoggedOutComponent
];

@NgModule({
  imports:      [
        CommonModule,
        FormsModule,
        NgbModule,
        LoginRoutingModule
  ],
  declarations: [ routableComponents, privateComponents, sharedComponents ],
  exports:      [ sharedComponents ],
  providers:    [ ]
})
export class LoginModule { }

