import { Injectable } from '@angular/core';
import { Observable, of, zip, combineLatest, BehaviorSubject } from 'rxjs'
import 'rxjs/add/Observable/zip';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';

export interface User {
    uid: string;
    createdDate: string;
    updatedDate: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    privileges?: {
        admin?: boolean;
    };
    networks?: {
        [index: string]: boolean;
    };
    networkInvites?: {
        [index: string]: boolean;
    };
    tags: string[];
}

@Injectable()
export class UserService {
    private _user: User;
    private _currentUserObservable: BehaviorSubject<User> = new BehaviorSubject(this._user);
    private _userObservables: {[index: string]: Observable<User>} = {};
    private _isLoggedIn: boolean = false;
    private _userString = '/users/';

    constructor(private _afAuth: AngularFireAuth, private _afs: AngularFirestore) {
        // this._user = _appConfig.getUser();
    }

    public load = () => {

        return new Promise((resolve, reject) => {

            this._afAuth.authState.subscribe(auth => {
                if (auth) {
                    const user = this._afs.doc<User>(this._userString + auth.uid).valueChanges();

                    this._userObservables[auth.uid] = user;
                    this._isLoggedIn = true;

                    user.subscribe( userData => {
                        if (userData && userData.uid) {
                            this._user = userData;
                            this._currentUserObservable.next(userData);
                        }
                        else {
                            this._currentUserObservable.next(null);
                        }
                        resolve(true);
                    });
                }
                else {
                    this._isLoggedIn = false;
                    resolve(true);
                }
            });

        });
    }

    public isLoggedIn = (): boolean => {
        return this._isLoggedIn;
    }

    public isSignedUp = (): boolean => {
        return !!this._user;
    }

    public get = (): User => {
        return this._user;
    }
    public getObservable = () => {
        return this._currentUserObservable;
    }

    public lookUp = (id: string): Observable<User> => {
        if (!this._userObservables[id]) {
            this._userObservables[id] = this._afs.doc<User>(this._userString + id).valueChanges();
        }

        return this._userObservables[id];
    }
    public lookUpMany = (ids: string[], fetchOnce = false): Observable<any> => {
        let lookUpObservable: Observable<any>;

        if (!ids || ids.length < 1) {
            lookUpObservable = of([]);
        }
        else {
            let observables: Observable<User>[] = ids.map(this.lookUp);

            if (ids.length > 1 && fetchOnce) {
                lookUpObservable = zip(observables);
            }
            else {
                lookUpObservable = combineLatest(observables);
            }
        }

        return lookUpObservable;
    }

    public signout = (): void => {
        this._afAuth.auth.signOut();
    }
}
