import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

import { UserService, User } from '../../../app-core/user.service';

@Injectable()
export class TagService {

  private _user: User;
  private _userTagsString: string;
  private _userTagsRef: AngularFirestoreCollection<{label: string}>;
  private _userTagsObservable: Observable<string[]>;
  private _tags: string[] = [];
  private _sorter = (a, b) => {
    if (a > b) {
      return 1
    }
    return -1;
  };

  constructor(
    private _afs: AngularFirestore,
    private _userService: UserService) {
      this._init();
    }

  private _init() {
    this._user = this._userService.get();

    if (this._user && this._user.uid) {
        this._userTagsString = `/users/${this._user.uid}/tags`;
        this._userTagsRef = this._afs.collection(this._userTagsString);
        this._userTagsObservable = this._userTagsRef.valueChanges()
          .pipe(map(tags => {
            return tags
              .map(tag => tag.label)
              .sort(this._sorter);
        }));

        this._userTagsObservable.subscribe(tags => this._tags = tags);
    }
  }

  public getAll(): Observable<string[]> {

    // TODO: sort?
    return this._userTagsObservable;
  }

  public saveTag(newTag: string) {

    newTag = newTag.trim();

    if(this._tags.every(current => current != newTag)) {
      this._userTagsRef.add({label: newTag});
    }
    
  }

}
