import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login-page/login.component';
import { SignupComponent } from './signup-page/signup.component';
import { AcceptTermsComponent } from './accept-terms/accept-terms.component';
import { WaitListComponent } from './wait-list/wait-list.component';
import { WaitListCheckEmailComponent } from './wait-list-check-email/wait-list-check-email.component';
import { WaitListThankYouComponent } from './wait-list-thank-you/wait-list-thank-you.component';


import { CanActivateAuthGuard } from '../app-core/can-activate.service';

const routes: Routes = [
    { path: 'login', component: WaitListComponent },
	{ path: 'signup', component: WaitListComponent },
	{ path: 'signup/check-email', component: WaitListCheckEmailComponent },
	{ path: 'signup/thank-you', component: WaitListThankYouComponent },
	{ path: 'login/alpha', component: LoginComponent },
	{ path: 'signup/alpha', component: SignupComponent },
	{ path: 'accept-terms', component: AcceptTermsComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule {}

export const routableComponents = [
    LoginComponent,
	SignupComponent,
    AcceptTermsComponent,
    WaitListComponent,
	WaitListCheckEmailComponent,
    WaitListThankYouComponent
];