import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-display-field-type',
  templateUrl: './display-field-type.component.html',
  styleUrls: ['./display-field-type.component.scss']
})
export class DisplayFieldTypeComponent implements OnInit {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;

  @Output() fieldUpdated = new EventEmitter<ThoughtField>();

  public fieldTypes: string[] = [
      'short text',
      'long text',
      'number',
      'date',
      'image',
      'checkbox'
  ];

  constructor() { }

  ngOnInit() {
  }

}
