import { Injectable } from '@angular/core';
import { ThoughtService, Thought } from '../thought-module/thought.service';
import { SelectReadyConstant } from '../app-core/utility.service';

export let SORTABLE_DATA_TYPES: SelectReadyConstant[] = [
  {value: 'title', displayName: 'Title'},
  {value: 'fieldValue', displayName: 'Field Value'},
  {value: 'createdDate', displayName: 'Date Created'},
  {value: 'updatedDate', displayName: 'Date Last Updated'}
];

export interface SortInstruction {
  sortBy: string;
  isAscending: boolean;
  fieldLabel?: string;
}

export interface ThoughtFilterItem {
    filterType: string;
    filterValue?: string;
    filterDate?: Date;
    reverseFilter: boolean;
}

export interface ThoughtFilter {
    title?: ThoughtFilterItem[];
    info?: ThoughtFilterItem[][];
    tags?: ThoughtFilterItem[];
    dates?: ThoughtFilterItem[];
}

@Injectable()
export class ThoughtSearchService {

  constructor(private _thoughtService: ThoughtService) { }

  public simpleSearch (searchTerms: string, thoughtSet: Thought[]): Thought[] {
    let searchWords: string[],
      matchIndex = {},
      results: Thought[] = [];

    if (!searchTerms || !searchTerms.split || !thoughtSet) {
      return thoughtSet;
    }

    searchWords = searchTerms.split(' ');

    results = thoughtSet.filter((thought: Thought) => {
        let matches = 0;
        searchWords.forEach((word) => {
          const wordRegex = new RegExp(word, 'gi');

          if (thought.title.search(wordRegex) > -1) {
            matches += 4;
          }

          if (thought.tags && thought.tags.forEach) {
            thought.tags.forEach((tag) => {
              if (tag && tag.search && tag.search(wordRegex) > -1) {
                matches += 2;
              }
            });
          }

          if (thought.fields && thought.fields.forEach) {
            thought.fields.forEach((field) => {
              if (field.label && field.label.search && field.label.search(wordRegex) > -1) {
                matches++;
              }
              if (field.value && field.value.search && field.value.search(wordRegex) > -1) {
                matches++;
              }
            });
          }
        });

        if (matches > 0) {
          matchIndex[thought.uid] = matches;
          return true;
        }

        return false;

    }).sort( (a: Thought, b: Thought) => {
      if (matchIndex[a.uid] > matchIndex[b.uid]) {
        return -1;
      }
      if (matchIndex[a.uid] < matchIndex[b.uid]) {
        return 1;
      }
      return 0;
    });

    return results;
  }

  private _thoughtMatchesTitleFilter (filter: ThoughtFilterItem, thought: Thought): boolean {
    let thoughtHasTerm = thought.title && thought.title.search && thought.title.search(filter.filterValue) >= 0;

    if (filter.reverseFilter) {
      return !thoughtHasTerm;
    }
    return thoughtHasTerm;
  }

  private _thoughtMatchesTagFilter (filter: ThoughtFilterItem, thought: Thought): boolean {
    let thoughtHasTag;

    thoughtHasTag = thought.tags.some((tag) => {
        return tag && tag.search && tag.search(filter.filterValue) > -1;
    });

    if (filter.reverseFilter) {
     return !thoughtHasTag;
    }
    return thoughtHasTag;
  }

  private _thoughtMatchesDateFilter (filter: ThoughtFilterItem, thought: Thought): boolean {
     if (filter.filterType === 'Created After' && new Date(thought.createdDate) >= new Date(filter.filterDate)) {
        return true;
      }
      else if (filter.filterType === 'Created Before' && new Date(thought.createdDate) <= new Date(filter.filterDate)) {
        return true;
      }
      else if (filter.filterType === 'Updated After' && new Date(thought.updatedDate) >= new Date(filter.filterDate)) {
        return true;
      }
      else if (filter.filterType === 'Updated Before' && new Date(thought.updatedDate) < new Date(filter.filterDate)) {
        return true;
      }
  }

  private _thoughtMatchesInfoFilters(filters: ThoughtFilterItem[], thought: Thought): boolean {
    return filters.every( (singleInfoFilter) => {

      let hasInfoTerm = false;

      // Case where thought has no fields (and there's a field filter) is a mismatch
      if (thought.fields && thought.fields.some) {
        hasInfoTerm = thought.fields.some( (thoughtField) => {
          if (singleInfoFilter.filterType === 'Info Label'
            && thoughtField.label
            && thoughtField.label.search
            && thoughtField.label.search(singleInfoFilter.filterValue) > -1) {
            return true;
          }
          else if (singleInfoFilter.filterType === 'Info Type'
            && thoughtField.type
            && thoughtField.type === singleInfoFilter.filterValue ) {
            return true;
          }
          else if (singleInfoFilter.filterType === 'Info Value'
          && thoughtField.value
          && thoughtField.value.search
          && thoughtField.value.search(singleInfoFilter.filterValue) > -1) {
            return true;
          }

          return false;
        });
      }

      if (singleInfoFilter.reverseFilter) {
        return !hasInfoTerm;
      }

      return hasInfoTerm;
    });
  }

  public advancedSearch (thoughtFilter: ThoughtFilter, thoughtSet: Thought []): Thought[] {
    let results: Thought[] = [];

    if (!thoughtFilter || !thoughtSet) {
      return thoughtSet;
    }

    results = thoughtSet.filter((thought: Thought) => {
      let match: boolean;

      match = thoughtFilter.title && thoughtFilter.title.every((titleFilter) => {
        return this._thoughtMatchesTitleFilter(titleFilter, thought);
      });
      if (match === false) {
        return false;
      }

      match = thoughtFilter.tags && thoughtFilter.tags.every((tagFilter) => {
        // Case where thought has no tags (and there's a tag filter) is a mismatch
        if (!tagFilter.reverseFilter && (!thought.tags || !thought.tags.some)) {
          return false;
        }
        else if (tagFilter.reverseFilter && (!thought.tags || !thought.tags.some)) {
          return true;
        }

        return this._thoughtMatchesTagFilter(tagFilter, thought);
      });
      if (match === false) {
        return false;
      }

      match = thoughtFilter.dates && thoughtFilter.dates.every((dateFilter) => {
       return this._thoughtMatchesDateFilter(dateFilter, thought);
      });
      if (match === false) {
        return false;
      }

      match = thoughtFilter.info && thoughtFilter.info.every((infoFilters) => {
        return this._thoughtMatchesInfoFilters(infoFilters, thought);
      });
      if (match === false) {
        return false;
      }

      return true;
    });

    return results;
  }

  public filterIsValid (filter: any): boolean {
    let hasAtLeastOneFilterItem = false,
      allFilterItemsAreValid = false;

    // TODO: also check all the filterItems valid
    // filter it has at least one of the required filter groups with a working filter, and all filters are
    if ( (filter.title && filter.title.length && filter.title.length > 0) ||
      (filter.tags && filter.tags.length && filter.tags.length > 0) ||
      (filter.dates && filter.dates.length && filter.dates.length > 0) ||
      (filter.info && filter.info.length && filter.info.length > 0) ) {

        hasAtLeastOneFilterItem = true;
    }

    return hasAtLeastOneFilterItem;
  }

}
