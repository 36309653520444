<div class="form-inline mb-4" *ngIf="!showFilters">
  <input type="search" (keyup)="searchTrigger()" [(ngModel)]="simpleSearchValue" class="form-control" placeholder="Simple text search">
</div>
  
  <div *ngIf="showFilters" class="content-box mt-2 mb-4">
    <h4 class="header-major {{contextClass}}-bg ">Filters</h4>
  
      <dl class="advanced-filter-list clearfix">
        <div *ngFor="let filterItem of advancedFilters.title">
          <dt>{{filterItem.filterType}}</dt>
          <dd>
            <span class="filter-item-value">{{filterItem.filterValue}}</span>
            <i *ngIf="filterItem.reverseFilter" class="reverse-filter-icon fa fa-ban mr-4" aria-hidden="true"></i>
            <button class="btn btn-sm btn-danger btn-small" (click)="deleteFilterItem('title', filterItem)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </dd>
        </div>
        <div *ngFor="let filterItem of advancedFilters.info">
          <!-- TODO: fix missing or insufficient permissions issue -->
          <dt>{{filterItem[0].filterType}}</dt>
          <dd>
            <span class="filter-item-value">{{filterItem[0].filterValue}}</span>
            <i *ngIf="filterItem[0].reverseFilter" class="reverse-filter-icon fa fa-ban mr-4" aria-hidden="true"></i>
            <button class="btn btn-sm btn-danger btn-small" (click)="deleteFilterItem('info', filterItem)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </dd>
        </div>
        <div *ngFor="let filterItem of advancedFilters.tags">
          <dt>{{filterItem.filterType}}</dt>
          <dd>
            <span class="filter-item-value">{{filterItem.filterValue}}</span>
            <i *ngIf="filterItem.reverseFilter" class="reverse-filter-icon fa fa-ban mr-4" aria-hidden="true"></i>
            <button class="btn btn-sm btn-danger btn-small" (click)="deleteFilterItem('tags', filterItem)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </dd>
        </div>
        <div *ngFor="let filterItem of advancedFilters.dates">
          <dt>{{filterItem.filterType}}</dt>
          <dd>
            <span class="filter-item-value">{{filterItem.filterDate | date:'short'}}</span>
            <button class="btn btn-sm btn-danger btn-small" (click)="deleteFilterItem('dates', filterItem)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </dd>
        </div>
      </dl>
      
      <h5 class="header-subsection">New Filter</h5>
      <div class="row">
        <div class="input-group col-md-3 mb-2">
          <select [(ngModel)]="newFilter.filterType" class="form-control" (change)="selectFilterInput()">
            <optgroup label="Title"></optgroup>
            <option>Title</option>
            <optgroup label="Info"></optgroup>
            <option>Info Label</option>
            <option>Info Type</option>
            <option>Info Value</option>
            <optgroup label="Tag">Tag</optgroup>
            <option>Tag</option>
            <optgroup label="Date">Date</optgroup>
            <option>Created After</option>
            <option>Created Before</option>
            <option>Updated After</option>
            <option>Updated Before</option>
          </select>
        </div>

        <div *ngIf="filterInputType === 'text'" class="input-group col-md-5 mb-2">
          <input type="text" [(ngModel)]="newFilter.filterValue" class="form-control" placeholder="Filter for...">
        </div>
        <div *ngIf="filterInputType === 'select'" class="input-group col-md-5 mb-2">
          <select [(ngModel)]="newFilter.filterValue" class="form-control">
            <option *ngFor="let fieldType of fieldTypes">{{fieldType}}<option>
          </select>
        </div>
        <div *ngIf="filterInputType === 'date'" class="input-group col-md-5 mb-2">
            <input [ngModel]="filterDate"
              (ngModelChange)="onSelectDate($event)"
              ngbDatepicker
              #d="ngbDatepicker"
              class="form-control"
              placeholder="m/d/yyyy"
              name="dp">
            <button class="input-group-addon btn addon-btn" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
        </div>

        <div class=" col-md-4">
          <label *ngIf="filterInputType !== 'date'" class="form-check-label pt-2 pb-2 mr-4">
            <input type="checkbox" [(ngModel)]="newFilter.reverseFilter" class="form-check-input">
            Exclude
          </label>

          <button (click)="addNewFilter(newFilter)" class="btn btn-{{contextClass}}"><i class="fa fa-plus" aria-hidden="true"></i></button>
        </div>

      </div>



</div>