import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ThoughtField } from '../../thought.service';

@Component({
  selector: 'app-display-field-value',
  templateUrl: './display-field-value.component.html',
  styleUrls: ['./display-field-value.component.scss']
})
export class DisplayFieldValueComponent {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;
  @Input() index: number;
  @Input() ready: boolean;

  @Output() fieldUpdated = new EventEmitter<ThoughtField>();

  constructor() { }

}
