import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { mergeMap, combineLatest, take } from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';

import { UtilityService } from '../app-core/utility.service';
import { UserService, User } from '../app-core/user.service';

@Injectable()
export class IntegrationService {

  private _integrationsObservable: Observable<any>;
  private _integrationsString = '/integrations/';
  private _currentUserObservable: Observable<User>;
  private _user: User;

  constructor(
        private _http: HttpClient,
        private _afs: AngularFirestore,
        private _userService: UserService,
        private _utilityService: UtilityService) {

          this._init();
  }

  private _init () {
    this._currentUserObservable = this._userService.getObservable();
    this._currentUserObservable.subscribe(user => this._user = user);
  }

  public get () {
    if (!this._integrationsObservable) {
      let encodedEmail = this._utilityService.encodeEmailKey(this._user.email);

            this._integrationsObservable = this._afs.collection(this._integrationsString + 'emailIndex/' + encodedEmail)
                .valueChanges()
                .pipe(
                  mergeMap( integrationPaths => {
                    let integrationsObservable: Observable<any>[];

                    integrationsObservable = integrationPaths.map( (integrationPath: any) => {
                      return this._afs.doc(this._integrationsString + integrationPath.uid).valueChanges().pipe(take(1));
                    });

                    return combineLatest(integrationsObservable);
                  })
                )
        }

        return this._integrationsObservable;
  };

  public searchIntegration (integrationType: string, integrationId: string, query: string) {

    integrationType = integrationType.toLowerCase();
    // TODO: which ever page pings the server, use ENV config for URL (and protocol: anything remote should be HTTPS)
    // TODO: make getting and sending auth token a reusable function
    firebase.auth().currentUser.getIdToken(true).then(idToken => {
      let queryURL = 'http://localhost:3000/' + integrationType + '/search/' + integrationId + '?query=' + query;
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      });

      // Send token to your backend via HTTPS
      // TODO: make this secure based on environment
      this._http.get(queryURL, {headers}).subscribe(res => console.log(res));
    }).catch(function(error) {
      console.log('Failed to get token:');
      console.log(error);
    });
  }

  // public import () {
  //   // TODO link to import page instead of this test functions
  //   // TODO: which ever page pings the server, use ENV config for URL (and protocol: anything remote should be HTTPS)

  //   // TODO: make getting and sending auth token a reusable function
  //   firebase.auth().currentUser.getToken(/* forceRefresh */ true).then(idToken => {
  //     let roomsURL = 'http://localhost:3000/hipchat/rooms';
  //     let headers = new Headers({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + idToken
  //     });
  //     let options = new RequestOptions({ headers: headers });

  //     // Send token to your backend via HTTPS
  //     // TODO: make this secure based on environment
  //     this._http.get(roomsURL, options).subscribe(res => console.log(res));
  //   }).catch(function(error) {
  //     console.log('Failed to get token:');
  //     console.log(error);
  //   });
  // }

}
