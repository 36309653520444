import { Component, Input } from '@angular/core';
import { Collection } from '../collection.service';

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.scss']
})
export class CollectionListComponent {

  @Input() collectionList: Collection[];
  @Input() simple: boolean;

}
