// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  dbConfig: {
    apiKey: "AIzaSyCrJTZaNN4taZUDlA0G_g8vEp8oicnHB8Y",
    authDomain: "rethink-e3aa1.firebaseapp.com",
    databaseURL: "https://rethink-e3aa1.firebaseio.com",
    projectId: "rethink-e3aa1",
    storageBucket: "rethink-e3aa1.appspot.com",
    messagingSenderId: "459536126206"
  }
};
