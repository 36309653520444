import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[rgLoading]',
  inputs: ['rgLoading']
})
export class LoadingDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
    ) { }

  @Input() set rgLoading (isLoading: boolean) {
    if (isLoading) {
      // If condition is true add template to DOM
      this.viewContainer.clear();
      
    } else {
     // Else remove template from DOM
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
