import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { FirebaseApp } from 'angularfire2';
import * as firebase from 'firebase/app';
import 'firebase/storage';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

import { UtilityService } from '../app-core/utility.service';
import { UserService, User } from '../app-core/user.service';
import { SharingService } from '../network-module/sharing/sharing.service';
import { isOwner } from '../app-core/db-helper.service';

export interface ThoughtField {
    label: string;
    type: string;
    value: any;
}

export interface ThoughtData {
    uid: string;
    creatorUid: string;
    creatorName: string;
    ownerUid: string;
    ownerName: string;
    createdDate: string;
    updatedDate: string;
    title: string;
    fields: ThoughtField[];
    tags: string[];
}

export interface Thought extends ThoughtData {
    sharedWith: {[index: string]: boolean};
    oldSharedWith?: {[index: string]: boolean};
    isPublic: boolean;
}

@Injectable()
export class ThoughtService {

    private _cachedThoughts;
    private _thoughtsString: string = '/thoughts/';
    private _userThoughtsRef: AngularFirestoreCollection<Thought>;
    private _userImagesRef: firebase.storage.Reference;
    private _user: User;

    constructor(
        private _afs: AngularFirestore,
        private _userService: UserService,
        private _utilityService: UtilityService,
        private _sharingService: SharingService
    ) {
        this._init();
    }

    private _init() {
        this._user = this._userService.get();

        if (this._user && this._user.uid) {
            this._userThoughtsRef = this._afs.collection(this._thoughtsString, ref => isOwner(ref, this._user.uid));

            this._userImagesRef = firebase.storage().ref().child('/users/' + this._user.uid + '/');
        }
    }

    private preSaveTasks(thoughtData: Thought) {
        // ensure that date fields are translated to their json string
        thoughtData.fields.forEach((field) => {
            if (field.type === 'date' && field.value && field.value.toJSON) {
                field.value = field.value.toJSON();
            }
        });

        thoughtData.updatedDate = new Date().toJSON();
        
        return thoughtData;
    }

    public getAll() {

        if ( !this._user ) {
            this._init();
        }

        if (this._userThoughtsRef) {
            return this._afs.collection<Thought>(this._thoughtsString, ref => isOwner(ref, this._user.uid).orderBy('updatedDate'))
            .valueChanges()
            .pipe(map( (arr) => { 
                // TODO filter and throw out duplicates without the uid properties
                arr = arr.filter(thought => thought.uid)
                    .reverse();

                return arr;
            } ));
        }
        // TODO: throw an error if no user (or retry?)
    }

    public getRecent(numberOfThoughts: number): Observable<Thought[]> {

        if ( !this._user ) {
            this._init();
        }

        numberOfThoughts = numberOfThoughts || 10;

        if (this._userThoughtsRef) {
            return <Observable<Thought[]>>this._afs.collection(this._thoughtsString, ref => isOwner(ref, this._user.uid).orderBy('updatedDate', 'desc')
                .limit(numberOfThoughts) )
                .valueChanges();
        }
        // TODO: throw an error if no user (or retry?)
    }

    public getOne (thoughtId: string): Observable<Thought> {

        if ( !this._user ) {
            this._init();
        }

        // TODO: improve security by NOT returning an object that can set data? Need to alter the save/delete method params/logic if done.
        return this._afs.doc<Thought>(this._thoughtsString + thoughtId).valueChanges();
    }

    public create (thoughtData: Thought): any {

        if ( !this._user ) {
            this._init();
        }

        if (this._userThoughtsRef) {
            let newUid = this._afs.createId();

            // Most of the required data is auto generated. User must supply a title, at least
            thoughtData.uid = newUid;
            thoughtData.createdDate = thoughtData.updatedDate = new Date().toJSON();
            thoughtData.ownerUid = thoughtData.creatorUid = this._user.uid;
            thoughtData.ownerName = thoughtData.creatorName = this._user.firstName + ' ' + this._user.lastName;
            thoughtData.isPublic = false;
            

            // don't need this for new thoughts
            delete thoughtData.oldSharedWith;

            thoughtData = this.preSaveTasks(thoughtData);

            return this._userThoughtsRef.doc(newUid).set(thoughtData);

            // DBFIX - fix sharing mechanism. Old one used to simply copy
            // return this._sharingService.composeCreationPromiseWithShares(
            //     thoughtData,
            //     () => this._userThoughtsRef.doc(newUid).set(thoughtData),
            //     (userIds, thoughtId) => {
            //         return userIds.map(userId => {
            //             return this._afs.doc(this._thoughtsString + thoughtId).set(thoughtData);
            //         });
            //     }
            // );
        }
        // TODO: return error if no user or no title
    }

    public update (thoughtData: Thought) {
        const thoughtId = thoughtData.uid;

        let removedShares: string[],
            removePromises,
            updatePromise: PromiseLike<any>;

        if (!thoughtData) {
            // TODO: if I can figure out how to get thought uid from thought data, maybe try that
            return;
        }

        if (thoughtData.oldSharedWith) {
            removedShares = this._utilityService.getNewAndRemovedKeys(thoughtData.sharedWith, thoughtData.oldSharedWith).removed;
            delete thoughtData.oldSharedWith;
        }

        thoughtData = this.preSaveTasks(thoughtData);

        return this._afs.doc(`/thoughts/${thoughtId}`).update(thoughtData);
        
        // TODO: DBFIX fix sharing service
        // updatePromise = this._sharingService.composePromiseWithShares(thoughtData, (id) => {
        //     return this._afs.doc('/thoughts/' + id + '/' + thoughtId).update(thoughtData);
        // });

        // // TODO: wait on all the promises, not just the update ones
        // if (removedShares && removedShares.length > 0) {
        //     removePromises = Promise.all(removedShares.map(shareId => {
        //         return this._afs.doc('/thoughts/' + shareId + '/' + thoughtId).delete();
        //     }));
        // }

        // return updatePromise;
    }

    public uploadUserImage (file: File): firebase.storage.UploadTask {
        if ( !this._user ) {
            this._init();
        }

        const imageRef = this._userImagesRef.child(file.name);

        return imageRef.put(file);
    }

    public deleteUserImage (fullImageUrl: string): Promise<any>  {
        const decodedURL = fullImageUrl.replace(/%2F/g, '/'),
            relativeUrl = decodedURL.substring(decodedURL.search(/\/users/), decodedURL.search(/\?/)),
            imageRef = firebase.storage().ref().child(relativeUrl);

        return imageRef.delete();
    }

    public scrubImageFromField (field: ThoughtField): void {
        if (!field || !field.type || !field.value) { return; }

        if (field.type === 'image') {
            this.deleteUserImage(field.value);
        }
    }

    public scrubImagesFromThoughtFields (fieldsToScrub: Array<ThoughtField>, fieldsToProtect: Array<ThoughtField>): void {
        let protectedPaths: Array<string> = [];
        if (!fieldsToScrub || !fieldsToScrub.forEach || fieldsToScrub.length === 0) { return; }

        if (fieldsToProtect && fieldsToProtect.length && fieldsToProtect.length > 0) {
            fieldsToProtect.forEach((field) => {
                if (field.type && field.type === 'image' && field.value) {
                    protectedPaths.push(field.value);
                }
            });
        }

        fieldsToScrub.forEach((field) => {
            if (field.type && field.type === 'image' && field.value && !protectedPaths.some((path) => path === field.value)) {
                this.deleteUserImage(field.value);
            }
        });
    }

    public delete (thoughtData: Thought) {

        // assumes images from deleted thoughts aren't used in other thoughts
        // TODO: check that there are no references to the field (maybe call a server process to do it behind the scenes)
        this.scrubImagesFromThoughtFields(thoughtData.fields, null);

        // if shares have been edited, use the originals
        if (thoughtData.oldSharedWith) {
            thoughtData.sharedWith = thoughtData.oldSharedWith;
        }

        this._afs.doc(this._thoughtsString + thoughtData.uid).delete();

        // TODO: DBFIX sharing service ?
        
    }
}
