<h2 class="header-major thought-bg">Search your thoughts</h2>
<div class="header-actions no-float-xs">
  <button
    (click)="toggleFilters()"
    class="btn btn-secondary"
    [ngClass]="{'toggle-active': showFilters}">
    <span class="fa fa-filter" aria-hidden="true"></span>
  </button>
  <button
    (click)="toggleEditForm()"
    class="bulk-edit-toggle btn btn-secondary"
    [ngClass]="{'toggle-active': showEditForm}"
    [attr.aria-expanded]="showEditForm"
    aria-controls="bulkEditForm">
      <span class="fa fa-pencil pencil-top" aria-hidden="true"></span>
      <span class="fa fa-pencil pencil-bottom" aria-hidden="true"></span>
  </button>
</div>

<app-thought-search [showEditForm]="showEditForm" [showFilters]="showFilters"></app-thought-search>