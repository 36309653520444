
<app-thought-filter
    [showFilters]="showFilters"
    [advancedFilters]="advancedFilters"
    [contextClass]="contextClass"
    (simpleSearchUpdated)="runSimpleSearch($event)"
    (advancedSearchUpdated)="runAdvancedSearch($event)"
    ></app-thought-filter>

<!-- TODO: add pagination -->
<app-thought-list
    [thoughtList]="thoughtResults"
    [showEditForm]="showEditForm"></app-thought-list>