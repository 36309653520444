import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CollectionFormComponent } from './collection-form/collection-form.component';
import { CollectionViewBasicComponent } from './collection-view-basic/collection-view-basic.component';

import { CanActivateAuthGuard } from '../app-core/can-activate.service';

const routes: Routes = [
    { path: 'collections/create',
    component: CollectionFormComponent,
    canActivate: [CanActivateAuthGuard],
    canActivateChild: [CanActivateAuthGuard]
},
{ path: 'collections/:collectionId',
    component: CollectionViewBasicComponent,
    canActivate: [CanActivateAuthGuard],
    canActivateChild: [CanActivateAuthGuard]
},
{ path: 'collections/:collectionId/edit',
    component: CollectionFormComponent,
    canActivate: [CanActivateAuthGuard],
    canActivateChild: [CanActivateAuthGuard]
},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CollectionRoutingModule {}

export const routableComponents = [
    CollectionFormComponent,
	CollectionViewBasicComponent,
];