import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ThoughtSearchPageComponent } from './thought-search-page/thought-search-page.component';

import { CanActivateAuthGuard } from '../app-core/can-activate.service';

const routes: Routes = [
    { path: 'thoughts',
        component: ThoughtSearchPageComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThoughtSearchRoutingModule {}

export const routableComponents = [
    ThoughtSearchPageComponent
];