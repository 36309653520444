import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ThoughtSearchRoutingModule, routableComponents} from './thought-search-routing.module';

import { ThoughtModule } from '../thought-module/thought.module';
import { ThoughtSearchService } from './thought-search.service';

import { ThoughtFilterComponent } from './thought-filter/thought-filter.component';
import { ThoughtSearchComponent } from './thought-search-component/thought-search.component';

let sharedComponents = [
 ThoughtFilterComponent,
 ThoughtSearchComponent
];

@NgModule({
  imports:      [
        CommonModule,
        FormsModule,
        NgbModule,
        ThoughtModule,
        ThoughtSearchRoutingModule
  ],
  declarations: [ routableComponents, sharedComponents ],
  exports:      [ sharedComponents ],
  providers:    [ ThoughtSearchService ]
})
export class ThoughtSearchModule { }