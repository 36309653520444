import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import { ActivatedRoute } from '@angular/router';

import { ThoughtTemplateService, ThoughtTemplate } from '../thought-template.service';

@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent implements OnInit {

  private _templateObserver: Observable<ThoughtTemplate>;

  public templateId: string;
  public template: ThoughtTemplate;

  constructor (
    private thisRoute: ActivatedRoute,
    private _thoughtTemplateService: ThoughtTemplateService,
    private _titleService: Title) {}

  ngOnInit() {
    const routeObserver = this.thisRoute.params.subscribe(params => {
       this.templateId = params['templateId'];

       // TODO: sanitize long text
       // TODO: figure out how to make the editor not so space happy

        // Get thought observable from Service if there is one
        if (this.templateId) {
            this._templateObserver = this._thoughtTemplateService.getOne(this.templateId);
            this._templateObserver.subscribe( (templateData => {
                this.template = templateData;
                this._titleService.setTitle('ReCog - ' + this.template.templateName);
            }).bind(this));
       }
    });
  }

}
