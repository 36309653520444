import { Component, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';

import { View } from '../view.service';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnChanges {

  @Input() view: View;
  @Input() viewData: any;
  @Input() linksToThought = true;

  public timelineData: any[];
  public timelineCols: any[];

  public timelineOptions: any  = {
    tooltip: {isHtml: true}
  };

  public errorMsg: string;


  constructor(private _router: Router) { }

  ngOnChanges(changes: any) {
    if (!changes.viewData || !changes.viewData.currentValue) {
      return;
    }

    if (this.viewData && this.viewData.length > 0) {
      this.errorMsg = null;

      this.timelineData = [];

      this.viewData.forEach(dataItem => {
        // filter out items with no dates
        if ( (!dataItem['Start Date Field(s)'] || !dataItem['Start Date Field(s)'][0])
          && (!dataItem['End Date Field(s)'] || !dataItem['End Date Field(s)'][0]) ) {
          return;
        }

        if (!dataItem['Start Date Field(s)']) {
          dataItem['Start Date Field(s)'] = [{
            label: dataItem['End Date Field(s)'][0].label,
            type: dataItem['End Date Field(s)'][0].type,
            value: new Date(dataItem['End Date Field(s)'][0].value)
          }];
          dataItem['End Date Field(s)'][0].value.setSeconds(dataItem['End Date Field(s)'][0].value.getSeconds());
          
        }
        else if (!dataItem['End Date Field(s)'] || dataItem['Start Date Field(s)'][0].value >= dataItem['End Date Field(s)'][0].value) {
          dataItem['End Date Field(s)'] = [{
            label: dataItem['Start Date Field(s)'][0].label,
            type: dataItem['Start Date Field(s)'][0].type,
            value: new Date(dataItem['Start Date Field(s)'][0].value)
          }];
          dataItem['End Date Field(s)'][0].value.setSeconds(dataItem['End Date Field(s)'][0].value.getSeconds() + 1);          
        }

        dataItem['Start Date Field(s)'][0].value = new Date(dataItem['Start Date Field(s)'][0].value);
        dataItem['End Date Field(s)'][0].value = new Date(dataItem['End Date Field(s)'][0].value);

        this.timelineData.push([
          dataItem['Label'][0].value,
          new Date(dataItem['Start Date Field(s)'][0].value),
          new Date(dataItem['End Date Field(s)'][0].value),
        ]);
      });

      if (this.timelineData.length > 0) {
        this.timelineData.sort( (a, b) => {
          // sort by start date
          if (a[1] > b[1]) {
            return 1;
          }
          else {
            return -1;
          }
        });

        this.timelineData.unshift(['', new Date(), new Date()]);
        this.timelineOptions.height = this.timelineData.length * 43 + 43;
      }
      else {
        this.errorMsg = 'No date info available to chart';
      }
    }
    else {
      this.errorMsg = 'No date info available to chart';
    }

    // TODO figure out why this data works and my demo data does not.
    // this.timelineData = [
    //   [
    //     "",
    //     new Date("2017-12-04T01:27:14.083Z"),
    //     new Date("2017-12-04T01:27:14.083Z")
    //   ],
    //   [
    //     "P1S1: Luxurious Thought Bot",
    //     new Date("2017-05-01T04:37:06.000Z"),
    //     new Date("2017-05-06T04:37:19.000Z")
    //   ],
    //   [
    //     "P1S2: Scribal Occulence",
    //     new Date("2017-05-08T04:37:06.000Z"),
    //     new Date("2017-05-13T04:37:19.000Z")
    //   ],
    //   [
    //     "P1S3: Snapshots in Time",
    //     new Date("2017-05-15T04:37:06.000Z"),
    //     new Date("2017-05-20T04:37:19.000Z")
    //   ],
    //   [
    //     "P1S4: Corpulent Mod vs the Quick Epithet",
    //     new Date("2017-05-22T04:37:06.000Z"),
    //     new Date("2017-05-27T04:37:19.000Z")
    //   ],
    //   [
    //     "P1S5: Ejection Seat",
    //     new Date("2017-05-29T04:37:06.000Z"),
    //     new Date("2017-06-03T04:37:19.000Z")
    //   ],
    //   [
    //     "P1S6: Smooth Lockbox",
    //     new Date("2017-06-05T04:37:06.000Z"),
    //     new Date("2017-06-10T04:37:19.000Z")
    //   ]
    // ];
  }

  // TODO: move this somewhere common (view service?) to DRY
  public selectionCallback = (selectionIndex) => {

    let item = this.viewData[selectionIndex];

    if (!this.linksToThought) {
      return;
    }

    if (item) {
      this._router.navigateByUrl('/thoughts/' + item.thoughtId);
    }
  }

}
