import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FocusMeDirective } from './widgets/focus-me/focus-me.directive';
import { LoadingDirective } from './widgets/loading/loading.directive';
import { LoadingComponent } from './widgets/loading/loading.component';
import { WordListInputComponent } from './widgets/word-list-input/word-list-input.component';
import { TagsComponent } from './widgets/tags/tags.component';


let sharedComponents = [
  FocusMeDirective,
  LoadingDirective,
  LoadingComponent,
  WordListInputComponent,
  TagsComponent
];
@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    FormsModule
  ],
  declarations: [ sharedComponents ],
  exports:      [ sharedComponents ]
})
export class SharedModule { }

