import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { View } from '../view.service';
import { WindowRef } from '../../../app-core/window-ref.service';

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrls: ['./custom-page.component.scss']
})
export class CustomPageComponent implements OnInit, OnChanges {

  @Input() view: View;
  @Input() viewData;
  @Input() linksToThought = true;

  private SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  public isTouch: boolean;

  constructor(private _winRef: WindowRef) { }

  ngOnInit() {
    this.isTouch = this._isTouchDevice();
  }

  ngOnChanges(changes) {

    if (changes.viewData && this.viewData) {

      this.viewData.forEach( (dataItem, index) => {
        // set visibility for swiping
        if (index === 0) {
          dataItem.visible = true;
        }
        else {
          dataItem.visible = false;
        }

        // set title for page, if first including field is to be treated like a title
        if (this.view && this.view.settings[0].value === true && dataItem['Included Fields']) {
          dataItem.listItemTitle = dataItem['Included Fields'].shift();
        }
      });
    }
  }

  private _isTouchDevice(): boolean {
    return "ontouchstart" in this._winRef.nativeWindow || !!this._winRef.navigator.msMaxTouchPoints;
    // return false;
  }

  public swipe(currentIndex: number, action: string = this.SWIPE_ACTION.RIGHT) {
        
        if (currentIndex > this.viewData.length || currentIndex < 0) return;

        let nextIndex = 0;
        // next
        if (action === this.SWIPE_ACTION.RIGHT) {
            const isLast = currentIndex === this.viewData.length - 1;
            nextIndex = isLast ? 0 : currentIndex + 1;
        }

        // previous
        if (action === this.SWIPE_ACTION.LEFT) {
            const isFirst = currentIndex === 0;
            nextIndex = isFirst ? this.viewData.length - 1 : currentIndex - 1;
        }

        // toggle avatar visibility
        this.viewData.forEach((x, i) => x.visible = (i === nextIndex));
    }

}
