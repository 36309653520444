<h1 *ngIf="!network.uid" class="header-major">New Network</h1>
<h1 *ngIf="network.uid" class="header-major">Edit Network</h1>
<div class="content-box">
  <div class="form-group">
      <label>Network Name</label>
      <input [(ngModel)]="network.name" type="text" class="form-control">
  </div>
  <div class="form-group" *ngIf="!network.admin">
    <label for="">Invite an Admin</label>
    <input [(ngModel)]="network.adminInvitee" type="email" class="form-control">
  </div>
  <div class="form-group" *ngIf="network.admin">
    <label for="">Network Admin</label>
    {{ network.admin.name }}
    <button (click)="removeAdmin()" class="btn btn-danger ml-2">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </button>
  </div>
</div>

<section *ngIf="members.length > 0" class="content-box">
  <h3 class="header-minor">Network Members</h3>
  <ul class="list-unstyled">
    <li *ngFor="let member of members" class="mb-2">
      {{ network.members[member]}}
      <button (click)="removeMember(member)" class="btn btn-danger ml-2">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </button>
    </li>
  </ul>
</section>

<div class="content-box">
  <h3 class="header-minor">Invite New Members</h3>
  <ul class="list-unstyled">
    <li *ngFor="let invitee of invitees" class="form-inline mb-2">
      {{ invitee }}
      <button (click)="removeInvitee(invitee)" class="btn btn-danger ml-2">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </button>
    </li>
    <li class="form-inline">
        <label class="mr-2">Email</label>
        <input type="email" [(ngModel)]="newInvitee" class="form-control mr-2">
        <button (click)="addNewInvitee()" class="btn btn-primary">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
    </li>
  </ul>
</div>

<!-- TODO: create a bar on the bottom, like thoughts.  -->
<!-- TODO: create a cancel button  -->
<div class="form-actions">
  <div class="container">
    <div class="positive-actions">
      <button (click)="save()" class="btn btn-primary">Save Network</button>
    </div>
    <div class="negative-actions">
      <button (click)="cancelEditNetwork()" class="btn btn-warning btn-small">Cancel</button>
    </div>
  </div>
</div>

