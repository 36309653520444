<p *ngIf="isTouch" class="content-box">Swipe the below  to see more thoughts in the collection.</p>
<div class="view-page swipe-box" 
    *ngFor="let item of viewData; let idx=index"
    (swipeleft)="swipe(idx, $event.type)" (swiperight)="swipe(idx, $event.type)" 
    [class.visible]="item.visible" [class.hidden]="!item.visible">
    <div *ngIf="!isTouch" class="float-right">
      <button (click)="swipe(idx, 'swipeleft')" class="page-nav prev btn btn-primary ml-2">&lt;</button>
      <button (click)="swipe(idx, 'swiperight')"  class="page-nav next btn btn-primary">&gt;</button>
    </div>
    
    <h5 *ngIf="item.listItemTitle" class="header-minor thought-bg">
      <a *ngIf="linksToThought" [routerLink]="linksToThought ? ['/thoughts/', item.thoughtId] : null">{{item.listItemTitle.value}}</a>
      <span *ngIf="!linksToThought">{{item.listItemTitle.value}}</span>
    </h5>
    
    <app-display-fields
      [fields]="item['Included Fields']"
      [showLabels]="view.settings[1]?.value">
    </app-display-fields>
    <a
        *ngIf="!item.listItemTitle && linksToThought"
        [routerLink]="linksToThought ? ['/thoughts/', item.thoughtId] : null"
        class="float-right">
          Details
    </a>
</div>
