<div class="list-unstyled field-list mb-5" [ngClass]="{'info-list-edit': isEditing}" [dragula]="'info-bag'" [dragulaModel]="fields">
  
  <div class="field-row clearfix" *ngFor="let field of fields; let i = index" >

    <div class="info-wrapper clearfix mb-2" [ngClass]="{'long-text-info': field.type === 'long text', 'image-wrapper': field.type === 'image', 'info-wrapper-editing': isEditing}">

      <div *ngIf="isEditing" class="form-group move-arrows mr-2 mb-2">
        <div (click)="moveField('up', i)" class="move-arrow fa fa-arrow-up pt-2 pr-2 pl-2 pb-1" aria-hidden="true"></div>
        <div (click)="moveField('down', i)" class="move-arrow fa fa-arrow-down pt-1 pr-2 pl-2 pb-2" aria-hidden="true"></div>
      </div>

      <app-display-field-label
        *ngIf="showLabels" 
        [field]="field"
        [isEditing]="isEditing"
        [readyForFocus]="readyForFocus"
        (fieldUpdated)="fieldsUpdated.emit(fields)"></app-display-field-label>

        <app-display-field-type
        *ngIf="showType"
        [field]="field"
        class="field-type form-group"></app-display-field-type> 
        
      <app-display-field-value
        *ngIf="!hideValue"
        [field]="field"
        [isEditing]="isEditing"
        [index]="i"
        [ready]="ready"
        (fieldUpdated)="fieldsUpdated.emit(fields)"
        [ngClass]="{'image-value': field.type === 'image', 'check-value': field.type === 'checkbox'}"
        class="field-value"></app-display-field-value>

      <button 
        *ngIf="isEditing"
        (click)="deleteField(field, i)"
        class="btn btn-danger field-delete ml-4 mb-2">
          <i class="fa fa-trash" aria-hidden="true"></i>
      </button>
    </div>

  </div>

</div>