import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ThoughtTemplate } from '../thought-template.service';

@Component({
  selector: 'app-template-create',
  templateUrl: './template-create.component.html',
  styleUrls: ['./template-create.component.scss']
})
export class TemplateCreateComponent implements OnInit {

  public template: ThoughtTemplate = <ThoughtTemplate>{};

  constructor(private _router: Router) { }

  ngOnInit() {

  }

  public templateSaved (event) {
    // TODO: route this to the correct page
    this._router.navigateByUrl('/templates/' + event.uid);
  }

  public templateCancelled (event) {
    this._router.navigateByUrl('/templates');
  }

  public templateDeleted (event) {
    this._router.navigateByUrl('/templates');
  }

}
