<!-- TODO: make a thought-data-view that is reusable by thought basic view and template view -->
<div *ngIf="!template" class="container">
    <h2 class="header-major">This Template Does Not Exist</h2>
</div>
<div *ngIf="template" class="container">
    <h2 class="header-major">
        {{template.templateName}}
    </h2>
    <div class="header-actions">
        <a routerLink="edit" class="btn btn-secondary"><i class="fa fa-pencil" aria-hidden="true"></i></a>
    </div>
    <div class="content-box">
        <h3 class="header-minor thought-bg">Default Thought Title</h3>
        <p>{{template?.title}}</p>
    </div>

    <div class="thought-view-list">
        <app-display-fields [fields]="template?.fields" [showType]="true"></app-display-fields>
    </div>
    <h3>Tags</h3>
    <ul class="list-inline">
        <li *ngFor="let tag of template?.tags" class="badge badge-default m-2">
            {{tag}}
        </li>
    </ul>
</div>