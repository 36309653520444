// Angular Core Modules
import { BrowserModule, Title, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Vendor Libraries
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestoreModule, AngularFirestore } from 'angularfire2/firestore';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';

// ReCog Feature Modules
import { LoginModule } from './login-module/login.module';
// import { NetworkModule } from './network-module/network.module';
import { ThoughtModule } from './thought-module/thought.module';
import { ThoughtSearchModule } from './thought-search-module/thought-search.module';
import { CollectionModule } from './collection-module/collection.module';
// import { IntegrationModule } from './integration-module/integration.module';


// App Basics
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';

import {AppRoutingModule, routableComponents} from './app-routing.module';
import { CanActivateAuthGuard } from './app-core/can-activate.service';

import { UtilityService } from './app-core/utility.service';
import { UserService } from './app-core/user.service';
import { WindowRef } from './app-core/window-ref.service';
import { HistoryService } from './app-core/history.service';
import { NgbCustomDateFormatter } from './app-core/ngb-custom-date-formatter';

import { HeaderComponent } from './app-core/header/header.component';

// Other services
import { ThoughtService } from './thought-module/thought.service';
import { ThoughtSearchService } from './thought-search-module/thought-search.service';
import { NetworkService } from './network-module/network.service';
import { SharingService } from './network-module/sharing/sharing.service';
import { CollectionService } from './collection-module/collection.service';
import { IntegrationService } from './integration-module/integration.service';
import { TestComponent } from './app-core/test/test.component';


export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      'swipe': {velocity: 0.4, threshold: 20} // override default settings
  };
}

// TODO: put this somewhere safe
export const firebaseConfig = environment.dbConfig;

export function configLoad (config: UserService) {
  return () => config.load();
};

export function dateParserFactory () {
  return new NgbCustomDateFormatter('longDate');
};

@NgModule({
  declarations: [
    AppComponent,
    routableComponents,
    HeaderComponent,
    TestComponent
  ],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    NgbModule,
    Angulartics2Module.forRoot(),
    LoginModule,
    ThoughtModule,
    ThoughtSearchModule,
    CollectionModule,
    // NetworkModule,
    // IntegrationModule,
    AppRoutingModule
  ],
  providers: [
    AngularFireAuth,
    NetworkService,
    SharingService,
    Title,
    CanActivateAuthGuard,
    UtilityService,
    UserService,
    WindowRef,
    HistoryService,
    ThoughtService,
    ThoughtSearchService,
    CollectionService,
    IntegrationService,
    { provide: APP_INITIALIZER, useFactory: configLoad, deps: [UserService], multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    {provide: NgbDateParserFormatter, useFactory: dateParserFactory}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
