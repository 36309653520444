import { Component, OnInit, OnChanges, EventEmitter, Input } from '@angular/core';

import { View } from '../view.service';

@Component({
  selector: 'app-custom-list',
  templateUrl: './custom-list.component.html',
  styleUrls: ['./custom-list.component.scss']
})
export class CustomListComponent implements OnInit, OnChanges {

  @Input() view: View;
  @Input() viewData;
  @Input() linksToThought = true;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {

    if (changes.viewData && this.viewData && this.view && this.view.settings[0].value === true) {
      this.viewData = this.viewData.map(dataItem => {
        if (dataItem['Included Fields']) {
          dataItem.listItemTitle = dataItem['Included Fields'].shift();
        }
        return dataItem;
      });
    }
  }

}
