<h2 class="header-major collection-bg">
  <input type="text" [(ngModel)]="collection.title" class="form-control" placeholder="Collection Title">
</h2>
<div class="header-actions no-float-xs">
    <!-- TODO: Enable networks -->
    <!-- <app-sharing
        [shareInfo]="collection.sharedWith"
        [owner]="collection.owner?.uid">
    </app-sharing> -->
</div>

<div class="content-box">
  <label class="content-label">Collection Description (Optional)</label>
  <textarea [(ngModel)]="collection.desc" cols="30" rows="10" class="form-control">
  </textarea>
</div>
<!--<div class="form-group">
  <label>Collection Type</label>
  <select [(ngModel)]="collection.collectionType" class="form-control">
    <option *ngFor="let colType of collectionTypes" value="{{colType.value}}">{{colType.displayName}}</option>
  </select>
</div>-->
<div class="form-group" *ngIf="collection.collectionType === 'filter'">
  <!-- TODO: make thoughts expandable (and don't get thoughts until it's expanded) -->
  <app-thought-search 
    [advancedFilters]="collection.filter"
    contextClass="collection"
    showFilters="true"
    (filterUpdated)="updateCollectionFilter($event)"></app-thought-search>
</div>
<!-- TODO: actually implement sorting... -->
<!-- TODO: allow user to create multiple sort instructions + set their order -->

<!--<div class="form-group">
  <h3>Collection Order</h3>
  
  <ul class="list-unstyled">
    <li *ngFor="let sortInst of collection.sortInstructions" class="form-inline">
      <label class="mr-2">Sort Thoughts By</label>
      <select [(ngModel)]="sortInst.sortBy" class="form-control mr-2">
        <option *ngFor="let dataType of sortableDataTypes" value="{{dataType.value}}">{{dataType.displayName}}</option>
      </select>
      <input type="text" [(ngModel)]="sortInst.fieldLabel" *ngIf="sortInst.sortBy === sortableDataTypes.fieldValue" class="form-control" placeholder="Field name to sort by">
      <div form-check form-check-inline>
        <label>Is Ascending <input type="checkbox" [(ngModel)]="sortInst.isAscending" class="ml-2 mt-2"></label>
      </div>
    </li>
  </ul>
</div>-->
  <!-- TODO: Add view button + ngFor each view-->

<header class="views-header clearfix">
  <h3 class="header-minor view-bg">
    Add Views to this Collection
  </h3>
</header>
<ul class="list-unstyled">
  <li class="content-box" *ngFor="let view of collection.views; let i = index">
    
    <header class="view-header clearfix">
      <h4 class="header-minor header-input">
          <input type="text" [(ngModel)]="view.name" class="form-control" placeholder="Name this view">
      </h4>
      <div class="header-sub-actions view-actions">
        <span *ngIf="i > 0 || collection.views.length > 1">
          <button (click)="removeView(i)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
        </span>
      </div>
      <select [(ngModel)]="view.type" (change)="resetViewSettings(view)" class="view-type-selector form-control">
        <option *ngFor="let view of views" value="{{view}}">{{view}}</option>
      </select>
      
    </header>
  

    <h5>View Variables</h5>
    <p>For each variable, include one or more info labels to add data to the view.</p>
    <!-- TODO: Refactor this into a separate widget for brevity's sake' -->
    <ul class="list-unstyled variable-list">
      <li *ngFor="let variable of view.info?.variables" class="variable-list-item">
        <!-- TODO: Improve the wording/instructions for these-->
        <h6 class="variable-label">{{variable.name}} ({{variable.type}})</h6>
        <p *ngIf="variable.instructions">{{variable.instructions}}</p>
        <!-- !!!! TODO: fix, currently the default is overriding saved mappings -->
        <app-word-list-input
          [items]="variable.fieldMappings"
          contextClass="view"
          itemName="label"
          (updated)="updateIncludedFields($event, variable)"></app-word-list-input>
      </li>
    </ul>
    <div class="view-settings-section" *ngIf="view.info.settings && view.info.settings.length > 0" >
      <h5>General View Settings</h5>
      <ul class="list-unstyled">
        <li *ngFor="let setting of view.info?.settings">
          <label *ngIf="setting.type === 'boolean'" class="setting-label form-check-label">
            <input type="checkbox" [(ngModel)]="setting.value" (change)="viewSettingToggle(setting, view.info)" class="form-check-input">
            {{setting.name}}
          </label>
          <!-- TODO: add more setting types as needed -->
          
        </li>
      </ul>
    </div>

  
  </li>
</ul>

<div class="form-actions">
  <div class="container">
    <div class="positive-actions">
        <div class="add-views" ngbDropdown placement="top-left" container="body">
            <button class="btn btn-primary btn-view" id="addViewDropButton" ngbDropdownToggle>
                <i class="fa fa-plus" aria-hidden="true"></i> View
            </button>
            <div ngbDropdownMenu aria-labelledby="addViewDropButton" class="dropdown-menu">
                <button *ngFor="let view of views" (click)="addView(view)" ngbDropdownItem>
                    {{view}}
                </button>
            </div>
        </div>
      <button class="btn btn-primary btn-collection" (click)="save()">Save Collection</button>
    </div>
    <div class="negative-actions">
      <button class="btn btn-danger mr-3" (click)="delete()" *ngIf="collectionId"><i class="fa fa-trash" aria-hidden="true"></i></button>
      <button class="btn btn-warning" (click)="cancel()"><i class="fa fa-ban" aria-hidden="true"></i></button>
    </div>
  </div>
</div>

