import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { NetworkService, Network } from '../network.service';
import { UserService, User } from '../../app-core/user.service';

import { takeWhile } from 'rxjs/operators'

@Component({
  selector: 'app-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss']
})
export class SharingComponent implements OnInit, OnDestroy, OnChanges {

  @Input() shareInfo: {[index: string]: boolean};
  @Input() owner: string;
  @Input() showShareForm = false;
  @Input() alwaysShowForm = false;
  @Input() startIndeterminate: boolean;

  // not necessary unless the widget does not get a reference to an existing object it can mutate
  @Output() updated = new EventEmitter<any>();

  private _isAlive = true;

  public userNetworks: Network[];
  public networkShares: any = {};
  public networkHash: {[index: string]: Network} = {};
  public memberHash: {[index: string]: string[]} = {};
  public user: User;
  public hasAShare: boolean;


  constructor(
    private _networkService: NetworkService,
    private _userService: UserService) {}

    // TODO: create a hash of members for each network

  ngOnInit() {
    this.user = this._userService.get();
    this.showShareForm = false;

    this._networkService.getUserNetworksAndMembers()
      .pipe(takeWhile(() => this._isAlive))
      .subscribe(networks => {

        this.userNetworks = networks;
        // get a quick hash of the networks so we don't have to update
        networks.forEach((network) => {
          this.networkHash[network.uid] = network;
          this.memberHash[network.uid] = Object.keys(network.members);
        });

        if (this.startIndeterminate) {
          this.userNetworks.forEach(network => {
            this.networkShares[network.uid] = undefined;
          });
        }
        else {
          this.processUpdates();
        }
      });
  }

  ngOnDestroy() {
    this._isAlive = false;
  }

  ngOnChanges(changes) {
    this.shareInfo = this.shareInfo || {};
    this.detectShares();

    if (changes.shareInfo
      && this.startIndeterminate
      && this.userNetworks) {
      this.userNetworks.forEach(network => {
          this.networkShares[network.uid] = undefined;
        });
    }
    else if (changes.shareInfo && this.userNetworks) {
      this.processUpdates();
    }
  }

  // any networks with an unshared user are unshared, otherwise they are shared
  private processUpdates (sendUpdate: boolean = false) {
    this.userNetworks.forEach(network => {
      let unsharedUser,
        networkMembers;

      networkMembers = Object.keys(network.members);

      // owner doesn't count as unshared user
      unsharedUser = networkMembers.some(id => id !== this.user.uid && !this.shareInfo[id]);

      this.networkShares[network.uid] = !unsharedUser;
    });

    // if you startIndeterminate, you'll have to remove false / undefined values yourself later
    if (!this.startIndeterminate) {
      // ensure that only TRUE values are saved to the shareInfo index
      Object.keys(this.shareInfo).forEach(sharerID => {
        if (this.shareInfo[sharerID] === false) {
          delete this.shareInfo[sharerID];
        }
      });
    }

    this.detectShares();

    if (sendUpdate) {
      this.updated.emit(this.shareInfo);
    }
  }

  private detectShares () {
    let ids = Object.keys(this.shareInfo);

    this.hasAShare = ids.some( (id) => this.shareInfo[id]);
  }

  public updateNetworkShares(networkId: string) {
    let shareStatus: boolean,
      network: Network = this.networkHash[networkId],
      networkMembers: string[] = [];

    if (network && network.members) {
      networkMembers = Object.keys(network.members);
    }

    this.networkShares[networkId] = shareStatus = !this.networkShares[networkId];

    networkMembers.forEach(id => {

      // never need to share with yourself
      if (id !== this.user.uid) {
        this.shareInfo[id] = shareStatus;
      }
    });

    // check all the networks in case users in this network belong to another
    this.processUpdates(true);
  }

}
