<ul class="list-unstyled custom-list-view-border mt-4">
  <li *ngFor="let item of viewData" class="custom-list-item">
    <h5 *ngIf="item.listItemTitle" class="header-minor thought-bg">
      <a *ngIf="linksToThought" [routerLink]="['/thoughts/', item.thoughtId]" class="title-link">{{item.listItemTitle?.value}}</a>
      <span *ngIf="!linksToThought" class="title-link">{{item.listItemTitle?.value}}</span>
    </h5>
    <app-display-fields *ngIf="item['Included Fields'] && item['Included Fields'].length > 0"
      [fields]="item['Included Fields']"
      [showLabels]="view.settings[1]?.value">
    </app-display-fields>
    <a
      *ngIf="!item.listItemTitle && linksToThought"
      [routerLink]="linksToThought ? ['/thoughts/', item.thoughtId] : null"
      class="float-right">
          Details
    </a>
  </li>
</ul>
