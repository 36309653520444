import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NetworkFormComponent } from './network-form/network-form.component';
import { NetworkViewComponent } from './network-view/network-view.component';

import { CanActivateAuthGuard } from '../app-core/can-activate.service';

const routes: Routes = [
    { path: 'networks/create',
    // TODO: special guard to check for loggedin ADMINs
        component: NetworkFormComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
    { path: 'networks/:networkId',
    // TODO: special guard for admins/network admins/network members
        component: NetworkViewComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
    { path: 'networks/:networkId/edit',
    // TODO: special guard to check for loggedin ADMINs/network admins
        component: NetworkFormComponent,
        canActivate: [CanActivateAuthGuard],
        canActivateChild: [CanActivateAuthGuard]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NetworkRoutingModule {}

export const routableComponents = [
    NetworkFormComponent,
    NetworkViewComponent
];