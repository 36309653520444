import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ThoughtService, ThoughtField } from '../../thought.service';

// TODO: create simplified version for inclusion in the simplified module

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() field: ThoughtField;
  @Input() isEditing: boolean;
  @Output() fieldUpdated = new EventEmitter<ThoughtField>();


  constructor(private _thoughtService: ThoughtService) { }

  ngOnInit() {
  }

  public upload (event, object) {
        const file = event.srcElement.files[0];

        this._thoughtService.uploadUserImage(file).then((snapshot) => {
            object.value = snapshot.downloadURL;
            this.fieldUpdated.emit(this.field);
        });
    }

}
