<table class="table table-no-separators table-sm">
  <thead *ngIf="!simple">
    <th>Collection Title</th>
    <th>Last Updated</th>
  </thead>
  <tr *ngFor="let collection of collectionList">
    <td>
      <a [routerLink]="['/collections/', collection.uid]">{{collection.title}}</a>
    </td>
    <td *ngIf="!simple">
      {{collection.updatedDate | date:'short'}}
    </td>
  </tr>
</table>