import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { ThoughtService, Thought } from '../thought.service';
import { UserService, User } from '../../app-core/user.service';
import { SharingService } from '../../network-module/sharing/sharing.service';

@Component({
  selector: 'app-thought-edit',
  templateUrl: './thought-edit.component.html',
  styleUrls: ['./thought-edit.component.scss']
})
export class ThoughtEditComponent implements OnInit {

  public thoughtId: string;
  public thought: Thought;
  public user: User;
  public shared: boolean;

  private _thoughtObserver: Observable<Thought>;

  constructor(
    private _thisRoute: ActivatedRoute,
    private _router: Router,
    private _thoughtService: ThoughtService,
    private _userService: UserService,
    private _titleService: Title,
    private _sharingService: SharingService
  ) { }

  ngOnInit() {
    this.user = this._userService.get();
    const routeObserver = this._thisRoute.params.subscribe(params => {
            this.thoughtId = params['thoughtId'];

            // create a default thought so we don't have to use elvis operator for thought in every template reference
            this.thought = <Thought> {
                title: null,
                tags: [],
                fields: []
            };

            // Get thought observable from Service if there is one
            if (this.thoughtId) {
                this._thoughtObserver = this._thoughtService.getOne(this.thoughtId);
                this._thoughtObserver.subscribe( (thoughtData => {
                    this.thought = thoughtData;
                    this.isShared();

                    this._titleService.setTitle('ReCog - Editing Thought: ' + this.thought.title);
                }).bind(this));
            }
        });
  }

  public thoughtSaved(savedThought: Thought): void {
    this._thoughtService.update(savedThought);
  }
  public thoughtCancelled(cancelledThought: Thought): void {

    this._router.navigateByUrl('/thoughts/' + this.thoughtId);
  }
  public thoughtDeleted(deletedThought: Thought): void {
    this._thoughtService.delete(deletedThought);

    // TODO: send back to previous context (dashboard, search, collection, whatevs)
    this._router.navigateByUrl('/dashboard');
  }

  // TODO: Delete? Looks like I'm not using isShared in the thought anyway, and I'm trying to reuse shares for collections. Looks outdated.
  public isShared() {
    this.shared = this._sharingService.isShared(this.thought);
  }

}
