import { Component, EventEmitter, Input, Output, OnChanges, ViewEncapsulation } from '@angular/core';
import { ThoughtFilterItem, ThoughtFilter } from '../thought-search.service';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-thought-filter',
  templateUrl: './thought-filter.component.html',
  styleUrls: ['./thought-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThoughtFilterComponent implements OnChanges {

  @Input() simpleSearchValue: string;
  @Input() advancedFilters: ThoughtFilter;
  @Input() showFilters = false;
  @Input() contextClass = 'thought';

  @Output() simpleSearchUpdated = new EventEmitter<string>();
  @Output() advancedSearchUpdated = new EventEmitter<ThoughtFilter>();
  @Output() toggleAdvancedSearch = new EventEmitter<boolean>();

  public filterInputType = 'text';
  public filterDate: NgbDateStruct = null;
  public newFilter: any = {
    filterType: 'Tag',
    filterValue: ''
  };
  
  // TODO: move this to thought service, get it on init (to keep in sync with other components)
  public fieldTypes: string[] = [
    'short text',
    'long text',
    'number',
    'date',
    'image',
    'checkbox'
  ];

  private _searchTimeout: any;

  ngOnChanges() {
    this.simpleSearchValue = this.simpleSearchValue || '';
    this.advancedFilters = this.advancedFilters || {};
    this.advancedFilters.title = this.advancedFilters.title || <ThoughtFilterItem[]> [];
    this.advancedFilters.info = this.advancedFilters.info || <ThoughtFilterItem[][]> [];
    this.advancedFilters.tags = this.advancedFilters.tags || <ThoughtFilterItem[]> [];
    this.advancedFilters.dates = this.advancedFilters.dates || <ThoughtFilterItem[]> [];
  }

  // wait for user to stop typing before searching
  public searchTrigger (): void {
    if (this._searchTimeout) {
      clearTimeout(this._searchTimeout);
    }

    this._searchTimeout = setTimeout(
      () => {
        // TODO: call parent method
        this.simpleSearchUpdated.emit(this.simpleSearchValue);
      },
      500
    );
  }

  public searchToggle(): void {
    this.showFilters = !this.showFilters;
    this.toggleAdvancedSearch.emit(this.showFilters);
  }

  public selectFilterInput(): void {
    let type = this.newFilter.filterType;

    if (type === 'Title' || type === 'Info Label' || type === 'Info Value' || type === 'Tag') {
      this.filterInputType = 'text';
    }
    else if (type === 'Info Type') {
      this.filterInputType = 'select';
    }
    else {
      this.filterInputType = 'date';
    }
  }
  public onSelectDate(date: NgbDateStruct){
    if (date != null) {
        this.newFilter.filterDate = new Date(date.year, date.month - 1, date.day);   // needed for first time around due to ngModel not binding during ngOnInit call. Seems like a bug in ng2.
    }
  }

  public addNewFilter(): void {

    if (this.newFilter.filterType === 'Created After'
      || this.newFilter.filterType === 'Created Before'
      || this.newFilter.filterType === 'Updated After'
      || this.newFilter.filterType === 'Updated Before') {
        this.advancedFilters.dates.push(this.newFilter);
      }
    else if (this.newFilter.filterType === 'Title') {
      this.advancedFilters.title.push(this.newFilter);
    }
    else if (this.newFilter.filterType === 'Tag') {
      this.advancedFilters.tags.push(this.newFilter);
    }
    else {

      this.advancedFilters.info.push([this.newFilter]);
    }

    this.advancedSearchUpdated.emit(this.advancedFilters);

    this.newFilter = {
      filterType: 'Title',
      filterValue: ''
    };
    this.filterDate = null;
  }

  public deleteFilterItem (filterGroup: string, filterItem: ThoughtFilterItem): void {

    this.advancedFilters[filterGroup] = this.advancedFilters[filterGroup].filter((filter: any) => {
      return filter !== filterItem;
    });

    // TODO: call parent method
    this.advancedSearchUpdated.emit(this.advancedFilters);
  }

}
